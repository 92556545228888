export const validateNone = () => {
    return { isValid: true, msg: null };
}

export const validateRequiredFile = (file) => {
    if (!file) {
        return { isValid: false, msg: "Required" };
    }
    return { isValid: true, msg: null };
}

export const validateRequiredText = (str) => {
    if (!str) return { isValid: false, msg: "Required" };
    if (str.length) {
        // if (!str.match(/^[a-zA-Z0-9_]+$/g)) return { isValid: false, msg: "Only alpha-numeric characters are accepted" };
        if (str.length > 255) return { isValid: false, msg: "Field too long" };
    }
    return { isValid: true, msg: null };
}

export const validateRequiredLongText = (str) => {
    if (str.length) {
        // if (!str.match(/^[a-zA-Z0-9_]+$/g)) return { isValid: false, msg: "Only alpha-numeric characters are accepted" };
        if (str.length > 65534) return { isValid: false, msg: "Field too long" };
    } else {
        return { isValid: false, msg: "Required" };
    }
    return { isValid: true, msg: null };
}

export const validateOptionalText = (str) => {
    if (str.length) {
        if (str.length > 255) return { isValid: false, msg: "Field too long" };
    }
    return { isValid: true, msg: null };
}

export const validateRequiredEmail = (email) => {
    if (!email) return { isValid: false, msg: "Required" };
    if (email.length) {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // console.log(regex.test(email));
        if (!regex.test(email)) return { isValid: false, msg: "Incorrect email format" };
        if (email.length > 255) return { isValid: false, msg: "Field too long" };
    }
    return { isValid: true, msg: null };
}

export const validateOptionalEmail = (email) => {
    if (email.length) {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        console.log(regex.test(email));
        if (!regex.test(email)) return { isValid: false, msg: "Incorrect email format" };
        if (email.length > 255) return { isValid: false, msg: "Field too long" };
    }
    return { isValid: true, msg: null };
}

export const validateRequiredPhone = phone => {
    if (phone.length) {
        const regex = /^[0-9\b]+$/;
        if (!regex.test(phone)) return { isValid: false, msg: "Invalid phone format" };
        if (phone.length !== 10) return { isValid: false, msg: "Please enter valid 10 digit phone number" };
    } else {
        return { isValid: false, msg: "Required" };
    }
    return { isValid: true, msg: null };
}

export const validateOptionalPhone = phone => {
    if (phone.length) {
        const regex = /^[0-9\b]+$/;
        if (!regex.test(phone)) return { isValid: false, msg: "Invalid phone format" };
        if (phone.length !== 10) return { isValid: false, msg: "Please enter valid 10 digit phone number" };
    }
    return { isValid: true, msg: null };
}

export const validateRequiredDate = (date) => {
    const inputDate = new Date(date);
    if (!isNaN(inputDate.getTime())) {
        if (isNaN(inputDate.getTime())) return { isValid: false, msg: "Invalid date" };
    } else {
        return { isValid: false, msg: "Required" };
    }
    return { isValid: true, msg: null };
}

export const validateRequiredTime = (date) => {
    if (date.length) {
        if (isNaN(date.getTime())) return { isValid: false, msg: "Invalid date" };
    } else {
        return { isValid: false, msg: "Required" };
    }
    return { isValid: true, msg: null };
}

export const validateRequiredNumber = (number, min = 0, max = 99999) => {
    if (!number) return { isValid: false, msg: "Required" };
    if (number < min) return { isValid: false, msg: "Minimum not met" };
    if (number > max) return { isValid: false, msg: "Maximum exceeded" };
    return { isValid: true, msg: null };
}
