import React from 'react';

const UserAvatar = ({ firstName, lastName }) => {
    // Extract the first letters of the first and last names
    const firstLetter = firstName.charAt(0).toUpperCase();
    const lastLetter = lastName.charAt(0).toUpperCase();

    // Generate a unique color based on the initials
    const color = generateUniqueColor(`${firstLetter}${lastLetter}`);

    // Function to generate a unique color based on a string
    // function generateUniqueColor(str) {
    //     let hash = 0;
    //     for (let i = 0; i < str.length; i++) {
    //         hash = str.charCodeAt(i) + ((hash << 5) - hash);
    //     }
    //     const hexColor = (hash & 0x00FFFFFF).toString(16).toUpperCase();
    //     return `#${'00000'.substring(0, 6 - hexColor.length)}${hexColor}`;
    // }

    // Function to generate a pastel color based on a string
    function generateUniqueColor(str) {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }

        // Create pastel-like RGB values
        const r = (hash & 0x7F) + 128;
        const g = ((hash >> 8) & 0x7F) + 128;
        const b = ((hash >> 16) & 0x7F) + 128;

        return `rgb(${r}, ${g}, ${b})`;
    }

    return (
        <div className="w-10 h-10 rounded-full mt-1.5" style={{ backgroundColor: color }}>
            <span className="uppercase text-2xl font-bold flex justify-center p-1 text-white">
                {firstLetter}
                {lastLetter}
            </span>
        </div>
    );
}

export default UserAvatar;
