import React from 'react'

const Error = () => {
    return (
        <div className='min-h-screen bg-[#F4F4F4]'>
            <div className='text-5x'>you are not on a page</div>
        </div>
    )
}

export default Error