import React, { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import styled from 'styled-components';
import dayjs from 'dayjs';

export default function MUITimePicker({ name, onChange, onBlur, form, editMode }) {
    const dateVar = name + "Date";
    const timeVar = name + "Time";

    // State to manage combined datetime value
    // form[dateVar].value && form[timeVar].value  needs to be a string in the format 'MM/DD/YYYY hh:mm A'
    const [dateTimeValue, setDateTimeValue] = useState({
        date: form[dateVar].value ? form[dateVar].value : new Date(),
        time: form[timeVar].value ? form[timeVar].value : new Date(),
    });


    // Update combined datetime value and trigger onChange
    const handleDateTimeChange = (newDate, newTime) => {
        console.log(newDate);
        console.log(newTime);

        const newDateText = newDate ? dayjs(newDate).format('MM/DD/YYYY') : null;
        const newTimeText = newTime ? dayjs(newTime).format('hh:mm A') : null;
        const newDateTimeText = newDateText + " " + newTimeText

        setDateTimeValue({
            date: newDateTimeText,
            time: newDateTimeText
        });


        onChange(name + "Date", newDateTimeText);
        onChange(name + "Time", newDateTimeText);
    };

    // Function to compare dates
    const isDateDisabled = () => {
        if (!dateTimeValue.date) {
            return false; // if date is not set, it's not disabled
        }
        const parsedDateTime = dayjs(dateTimeValue.date, 'MM/DD/YYYY').toDate();
        return editMode && (new Date() >= parsedDateTime);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className='flex flex-row w-full'>
                {/* Date Picker */}
                <DatePickerField
                    defaultValue={new Date()}
                    minDate={new Date()}
                    placeholder="mm/dd/yyyy"
                    value={dayjs(dateTimeValue.date).toDate()}
                    onChange={(newDate) => handleDateTimeChange(newDate, dateTimeValue.time)}
                    onBlur={onBlur}
                    renderInput={(params) => <TextField {...params} />}
                    // disabled={editMode && (new Date() >= dateTimeValue.date)}
                    disabled={isDateDisabled()}
                />
                <span className="text-xs text-red-700">{form[dateVar].error}</span>

                {/* Time Picker */}
                <TimePickerField
                    value={dayjs(dateTimeValue.time).toDate()}
                    onChange={(newTime) => handleDateTimeChange(dateTimeValue.date, newTime)}
                    onBlur={onBlur}
                    renderInput={(params) => <TextField fullWidth {...params} margin="normal" />}
                    // disabled={editMode && (new Date() >= dateTimeValue.date)}
                    disabled={isDateDisabled()}
                />
                <span className="text-xs text-red-700">{form[timeVar].error}</span>

            </div>
        </LocalizationProvider>
    );
}

// Styled components remain the same as you provided
const DatePickerField = styled(DesktopDatePicker)`
    padding: 4px 8px;
    width: 100%;
    flex-direction: row;
    -webkit-flex-direction: row;
    max-width: 210px;
    margin-right: 1rem !important;

    .MuiInputBase-input{
        padding: 7.5px 12px;
        max-width: 150px;
    }
`;

const TimePickerField = styled(DesktopTimePicker)`
    height: 44px;
    border-radius: 4px;
    padding: 4px 48px;
    width: 100%;
    flex-direction: row;
    -webkit-flex-direction: row;
    margin-top: 0px !important;
    max-width: 150px;

    .MuiInputBase-input{
        padding: 7.5px 12px;
        max-width: 150px;
    }
`;