import React from 'react'
import CategoriesSample from '../../util/CategoriesSample'




const Brand = ({ OffersList, onChange }) => {

    const getBrandsList = () => {
        var nameArray = OffersList.map((offer) => { return offer.organizer.name; });
        var a = [... new Set(nameArray)]; //there has to be a better way to do this
        return a;
    }

    const handleFilterChange = (event) => {
        // const brandName = event.target.value;
        onChange(event);
    };

    return (
        <div className='px-[15px] pt-6'>
            <div className='flex'>
                <div className='uppercase font-semibold leading-6 tracking-[1.78px] text-[#003863] pb-2 pr-[10px]'>Organizer</div>
                <span className='text-[#757575] font-semibold uppercase leading-6 tracking-[0.78px] text-[12px]'>({getBrandsList().length})</span>
            </div>

            <ul className='max-h-72 overflow-y-auto overflow-x-hidden'>
                {getBrandsList().map((brand, index) => (
                    <li className='py-[7px] flex' key={index}>
                        <input
                            value={brand}
                            type="checkbox"
                            className='w-4 h-4 cursor-pointer'
                            onChange={handleFilterChange}
                        />
                        <label className='pr-[8px] pl-[10px] text-[#757575]  font-light text-[14px] leading-3'>
                            {brand}
                        </label>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default Brand