import React, { useEffect, useState } from 'react'
import Hero from '../components/Hero';
import Section from '../components/Section';

import Loader from '../components/Loader';
import { requestOffersByCategory } from '../store/actions/index';
import { useDispatch, useSelector } from 'react-redux';
import Wrapper from '../components/Wrapper';


const Home = () => {

    const dispatch = useDispatch();

    const CategoryList = useSelector((state) => state.offersByCategory.categoryList);
    const OfferLoading = useSelector((state) => state.offersByCategory.loading);

    useEffect(() => {
        dispatch(requestOffersByCategory());
    }, []);

    return (

        (OfferLoading ? <Loader /> :
            <div className='pb-10 '>
                <Hero />
                <div className='flex w-full justify-center'>
                    <Section categoryList={CategoryList} />
                </div>
            </div>
        )

    )
}

export default Home