import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LogoutButton = () => {
    const { logout } = useAuth0();

    return (
        <button
            onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
            className='flex w-full py-2 bg-lime-500 border-none font-semibold justify-center my-0 text-white text-xs leading-tight rounded shadow-md hover:bg-lime-600 transition duration-150 ease-in-out'>
            Log Out
        </button>
    );
};

export default LogoutButton;