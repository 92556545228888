export function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export function StatusPill(value) {
    let status = value ? value.toLowerCase() : "unknown";

    if (status.startsWith("needs_response")) {
        status = "Needs Response";
    }
    return (
        <span
            className={classNames(
                "px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm",
                status.startsWith("completed") ? "bg-[#d9fbd0] text-[#3E7128] border border-[#bee8b4]" : null,
                status.startsWith("refunded") ? "bg-[#e3e6ed] text-[#141824] border border-[#cbd0dd] bg-opacity-50" : null,
                status.startsWith("Needs Response") ? "bg-yellow-100 text-yellow-800 bg-opacity-50" : null
            )}
        >
            {status}
        </span>
    );
}