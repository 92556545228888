import axios from "axios";

const headers = {
    headers: {
        "Content-Type": "application/json",
    }
}

// const events_service_base_url = 'https://ybmqxz9gng.execute-api.us-east-1.amazonaws.com'
// const orders_service_base_url = 'https://k360s8t9ch.execute-api.us-east-1.amazonaws.com'
// const organization_service_base_url = 'https://8cpw9c1u4f.execute-api.us-east-1.amazonaws.com'
// const payments_service_base_url = 'https://whwzgyz9id.execute-api.us-east-1.amazonaws.com'

const events_service_base_url = 'https://staging-api.lymetickets.com'
const orders_service_base_url = 'https://staging-api.lymetickets.com'
const organization_service_base_url = 'https://staging-api.lymetickets.com'
const payments_service_base_url = 'https://staging-api.lymetickets.com'



export const getOfferDetails = async (offerId) => {
    try {

        const res = await axios.get(`${events_service_base_url}/events/${offerId}`);
        return res.data;
    } catch (err) {
        console.log("Error caught: ", err.message);
    }
}


export const getAllOffers = async (variables) => {
    try {


        const res = await axios.get(`${events_service_base_url}/events`);
        return res.data;
    } catch (err) {
        console.log("Error caught: ", err.message);
    }
}

export const getOffersByCategory = async () => {
    try {

        const res = await axios.get(`${events_service_base_url}/events/categories`);
        return res.data;
    } catch (err) {
        console.log("Error caught: ", err.message);
    }
}


export const submitEvent = async (payload, jwt) => {
    try {
        const res = await axios.post(`${events_service_base_url}/events`, payload, {
            headers: {
                "Authorization": `Bearer ${jwt}`
            }
        });
        return res.data;
    } catch (err) {
        return err.response.data
    }
}


export const editEvent = async (payload, orgId, eventId, jwt) => {
    try {
        const res = await axios.post(`${organization_service_base_url}/organizations/${orgId}/events/${eventId}`, payload, {
            headers: {
                "Authorization": `Bearer ${jwt}`
            }
        });
        return res.data;
    } catch (err) {
        return err.response.data
    }
}


export const getUserOrgs = async (jwt) => {
    try {

        const res = await axios.get(`${events_service_base_url}/organizations`, {
            headers: {
                "Authorization": `Bearer ${jwt}`
            }
        });
        return res.data;
    } catch (err) {
        console.log("Error caught: ", err.message);
    }
}


export const purchaseTickets = async (payload, jwt, tokenType) => {
    const checkout = '/orders'
    const guestCheckout = '/guest-checkout'
    let url = ""

    if (tokenType === 'authenticated') {
        url = payments_service_base_url + checkout
    } else {
        url = payments_service_base_url + guestCheckout
    }

    try {
        const res = await axios.post(url, payload, {
            headers: {
                "Authorization": `Bearer ${jwt}`
            }
        });
        return res.data;
    } catch (err) {
        console.log("Error caught: ", err.message);
        return err.response.data
    }
}


export const getMyOrders = async (jwt) => {
    try {
        const res = await axios.get(`${orders_service_base_url}/orders`, {
            headers: {
                "Authorization": `Bearer ${jwt}`
            }
        });
        return res.data;
    } catch (err) {
        console.log("Error caught: ", err.message);
    }
}


export const getOrderDetailsViaOrderId = async (payload, jwt) => {
    try {
        const res = await axios.get(`${orders_service_base_url}/orders/${payload}`, {
            headers: {
                "Authorization": `Bearer ${jwt}`
            }
        });
        return res.data;
    } catch (err) {
        console.log("Error caught: ", err.message);
    }
}


export const getOrganizerDetails = async (orgId, jwt) => {
    try {
        const res = await axios.get(`${events_service_base_url}/organizations/${orgId}`, {
            headers: {
                "Authorization": `Bearer ${jwt}`
            }
        });
        return res.data;
    } catch (err) {
        console.log("Error caught: ", err.message);
    }
}


export const getOrganizerPaymentSetup = async (payload, jwt) => {
    try {
        const res = await axios.post(`${payments_service_base_url}/payment-setup`, payload, {
            headers: {
                "Authorization": `Bearer ${jwt}`
            }
        });
        return res.data;
    } catch (err) {
        console.log("Error caught: ", err.message);
    }
}


export const getOrganizerPaymentDetails = async (payload, jwt) => {
    try {
        const res = await axios.post(`${payments_service_base_url}/organization-payment-details`, payload, {
            headers: {
                "Authorization": `Bearer ${jwt}`
            }
        });
        return res.data;
    } catch (err) {
        console.log("Error caught: ", err.message);
    }
}


export const getTicketValidation = async (orgId, payload, jwt) => {
    try {
        const res = await axios.post(`${organization_service_base_url}/organizations/${orgId}/validate-ticket`, payload, {
            headers: {
                "Authorization": `Bearer ${jwt}`
            }
        });
        return { status: res.status, data: res.data };
    } catch (err) {
        console.log("Error caught: ", err.message);
        return err.response
    }
}


export const getOrganizerDashboardMetrics = async (orgId, eventId, jwt) => {
    try {
        const url = `${organization_service_base_url}/organizations/${orgId}/events/${eventId}/metrics`;
        const res = await axios.get(url, {
            headers: {
                "Authorization": `Bearer ${jwt}`
            }
        });
        return { status: res.status, data: res.data };
    } catch (err) {
        console.log("Error caught: ", err.message);
        return err.response;
    }
}


export const getOrganizerEvents = async (orgId, jwt) => {
    try {
        const url = `${organization_service_base_url}/organizations/${orgId}/events`;
        const res = await axios.get(url, {
            headers: {
                "Authorization": `Bearer ${jwt}`
            }
        });
        return { status: res.status, data: res.data };
    } catch (err) {
        console.log("Error caught: ", err.message);
        return err.response;
    }
}


export const getOrganizerOrders = async (orgId, jwt) => {
    try {
        const url = `${organization_service_base_url}/organizations/${orgId}/orders`;
        const res = await axios.get(url, {
            headers: {
                "Authorization": `Bearer ${jwt}`
            }
        });
        return { status: res.status, data: res.data };
    } catch (err) {
        console.log("Error caught: ", err.message);
        return err.response;
    }
}

export const requestRefundByOrganizer = async (data, jwt) => {
    const payload = {
        "order": {
            "order_id": data.order_id,
            "org_id": data.org_id
        }
    }

    try {
        const res = await axios.post(`${payments_service_base_url}/refund`, payload, {
            headers: {
                "Authorization": `Bearer ${jwt}`
            }
        });
        return { status: res.status, data: res.data };
    } catch (err) {
        console.log("Error caught: ", err.message);
        return err.response.data
    }
}

export const createOrganization = async (payload, jwt) => {
    try {
        const res = await axios.post(`${organization_service_base_url}/organizations`, payload, {
            headers: {
                "Authorization": `Bearer ${jwt}`
            }
        });
        return res.data;
    } catch (err) {
        return err.response.data
    }
}



export const getOrganizerAdminUsers = async (orgId, jwt) => {
    try {
        const res = await axios.get(`${organization_service_base_url}/organizations/${orgId}/users`, {
            headers: {
                "Authorization": `Bearer ${jwt}`
            }
        });
        return res.data;
    } catch (err) {
        return err.response.data
    }
}

export const getOrganizerAdminRoles = async (orgId, jwt) => {
    try {
        const res = await axios.get(`${organization_service_base_url}/organizations/${orgId}/roles`, {
            headers: {
                "Authorization": `Bearer ${jwt}`
            }
        });
        return res.data;
    } catch (err) {
        return err.response.data
    }
}


export const sendOrganizerAdminInvitation = async (orgId, jwt, payload) => {
    try {
        const res = await axios.post(`${organization_service_base_url}/organizations/${orgId}/invite`, payload, {
            headers: {
                "Authorization": `Bearer ${jwt}`
            }
        });
        return res.data;
    } catch (err) {
        return err.response.data
    }
}

export const requestResendTicketsByOrganizer = async (data, jwt) => {

    try {
        const res = await axios.post(`${organization_service_base_url}/organizations/${data.org_id}/orders/${data.order_id}/resend`, null, {
            headers: {
                "Authorization": `Bearer ${jwt}`
            }
        });
        return { status: res.status, data: res.data };
    } catch (err) {
        console.log("Error caught: ", err.message);
        return err.response.data
    }
}


export const requestDuplicateEventByOrganizer = async (data, jwt) => {

    try {
        const res = await axios.post(`${organization_service_base_url}/organizations/${data.org_id}/events/${data.event_id}/duplicate`, null, {
            headers: {
                "Authorization": `Bearer ${jwt}`
            }
        });
        return { status: res.status, data: res.data };
    } catch (err) {
        console.log("Error caught: ", err.message);
        return err.response.data
    }
}