import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Auth0Provider } from "@auth0/auth0-react";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>

  // custom DOMAINS need to be implemented to avoid the issue with safari and other browers that block 3rd party cookies by default

  <Auth0Provider
    domain="dev-ds6akl1mnfuvpgub.us.auth0.com"
    clientId="LQ8Inv6bsm27XcUi9NZsuOFDsJbIjHOF"
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: "https://gatekeepers.com/",
      // scope: "openid profile email"
    }}
  >
    <App />
  </Auth0Provider>
);
