import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { MdCalendarMonth } from 'react-icons/md'
import { TbSpeakerphone, TbSettings, TbCoins, TbLayoutDashboard, TbClipboardList } from 'react-icons/tb';
import { BsQrCodeScan } from 'react-icons/bs';
import { requestOrganizerDetails, requestOrganizerEvents, resetDashboardMetrics } from '../store/actions/index';
import Loader from './Loader';
import { useAuth0 } from '@auth0/auth0-react';
import { Link, useMatch } from 'react-router-dom';
import OrganizerSwitchDropdown from './OrganizerSwitchDropdown'
import defaultOrg from '../images/default_org.png'



const OrganizerSideNav = () => {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const { user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();

    const orgs = useSelector((state) => state.userOrgs.orgs.data);

    const organizationDetails = useSelector((state) => state.organizerDetails.orgDetails);
    const organizationDetailsLoading = useSelector((state) => state.organizerDetails.loading);

    const organizationEvents = useSelector((state) => state.organizerEvents.orgEvents);
    const organizationEventsLoading = useSelector((state) => state.organizerEvents.loading);

    const selectedOrg = useSelector((state) => state.selectedOrganization.selectedOrg);
    const selectedOrgLoading = useSelector((state) => state.selectedOrganization.loading);

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [currentMenuItemText, setCurrentMenuItemText] = useState("Dashboard");

    const dashboardMenuRef = useRef(null);

    function MenuItem({ to, icon, children }) {
        const isActive = useMatch(to);

        return (
            <li>
                <Link
                    to={to}
                    onClick={() => handleSidebarMenuItemClicked(children)}
                    className={`flex items-center text-sm p-2 text-gray-700 rounded-lg font-semibold -tracking-tight hover:bg-gray-100 group ${isActive ? 'bg-gray-100 text-gray-500 ' : ''}`}
                >
                    {icon && React.createElement(icon, { size: 20, className: `text-gray-700 -ml-1 ${isActive ? 'text-gray-700' : ''}` })}
                    <span className="ml-3">{children}</span>
                </Link>
            </li>
        );
    }

    useEffect(() => {
        const getOrganizerDetails = async () => {
            const accessToken = await getAccessTokenSilently();
            dispatch(resetDashboardMetrics())
            dispatch(requestOrganizerDetails(selectedOrg, accessToken));
            dispatch(requestOrganizerEvents(selectedOrg, accessToken))
            setLoading(false);
        };

        getOrganizerDetails();
    }, [selectedOrg])

    useEffect(() => {
        function handleClickOutside(event) {

            if (dashboardMenuRef.current && !dashboardMenuRef.current.contains(event.target)) {
                // Clicked outside of the sidebar, so hide it
                setIsSidebarOpen(!isSidebarOpen);
            }
        }

        if (isSidebarOpen) {
            // Add a mousedown event listener when the sidebar is open
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            // Remove the event listener when the sidebar is closed
            document.removeEventListener('mousedown', handleClickOutside);
        }

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isSidebarOpen]);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const closeSidebar = () => {
        setIsSidebarOpen(false);
    };

    const updateBreadcrumbs = (text) => {
        setCurrentMenuItemText(text);
    };

    const handleSidebarMenuItemClicked = (text) => {
        console.log(text);
        updateBreadcrumbs(text);
        closeSidebar();
    };




    return (

        <>

            {!organizationDetailsLoading ?
                (
                    <>

                        {/* Breadcrumb */}
                        <div className="sticky top-0 inset-x-0 z-20 bg-white border-y px-4 sm:px-6 md:px-8 lg:hidden">
                            <div className="flex items-center py-2">
                                <button
                                    type="button"
                                    onClick={toggleSidebar}
                                    className="py-2 px-3 mr-3 flex justify-center items-center gap-x-1.5 text-xs rounded-lg border border-gray-200 text-gray-500 hover:text-gray-600"
                                    aria-controls="application-sidebar"
                                    aria-label="Sidebar"
                                >
                                    <svg
                                        className="flex-shrink-0 size-4"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="22"
                                        viewBox="-2 1 20 20"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path d="M17 16M3 12H13M3 6H13M3 18H13" />
                                    </svg>
                                    <span className="sr-only">Sidebar</span>
                                </button>
                                {/* Breadcrumb */}
                                <ol className="ms-3 flex items-center whitespace-nowrap">
                                    <li className="flex items-center text-sm text-gray-800">
                                        {/* // Picture  */}
                                        <img className={`mx-auto h-10 w-10 rounded-full ${organizationDetails.img_url != null ? '' : 'grayscale'}`} src={organizationDetails.img_url} alt="" />
                                        <svg
                                            className="flex-shrink-0 mx-3 overflow-visible size-2.5 text-gray-400"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M5 1L10.6869 7.16086C10.8637 7.35239 10.8637 7.64761 10.6869 7.83914L5 14"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                            />
                                        </svg>
                                    </li>
                                    <li className="text-sm font-semibold text-gray-800 truncate" aria-current="page">
                                        {currentMenuItemText}
                                    </li>
                                </ol>
                                {/* Sidebar */}

                                {/* End Sidebar */}
                            </div>
                        </div>
                        {/* End Breadcrumb */}

                        {/* overlay */}
                        {isSidebarOpen ? <div className='fixed inset-0 bg-slate-900/25 backdrop-blur transition-opacity opacity-100 z-40'></div> : ''}

                        <div
                            ref={dashboardMenuRef}
                            id="application-sidebar"
                            className={`hs-overlay [--auto-close:lg] hs-overlay-open:translate-x-0 -translate-x-full transition-all duration-300 transform w-64 z-40 fixed inset-y-0 start-0 bg-white border-e border-gray-200 h-screen pt-24 lg:block lg:translate-x-0 lg:end-auto lg:bottom-0 ${isSidebarOpen ? 'translate-x-0' : ''
                                }`}
                        >
                            <div class="h-full px-3 py-4 overflow-y-auto ">

                                {/* // Picture  */}
                                <img className={`mt-4 mx-auto h-20 w-20 rounded-full ${organizationDetails.img_url != null ? '' : 'grayscale'}`} src={organizationDetails.img_url != null ? organizationDetails.img_url : defaultOrg} alt="organization logo" />

                                <ul className="mb-0 list-unstyled">
                                    <li className='mt-8'>
                                        <h5 class="mb-2 text-sm font-semibold tracking-wide text-gray-900 uppercase lg:text-xs">General</h5>
                                        <ul class="py-1 list-unstyled fw-normal small">
                                            <li>
                                                <MenuItem
                                                    to={`/organizer/${selectedOrg}/dashboard`}
                                                    icon={TbLayoutDashboard}
                                                // className="flex items-center text-sm p-2 text-gray-900 rounded-lg hover:text-lime-600 "
                                                >
                                                    Dashboard
                                                </MenuItem>
                                            </li>
                                            <li>
                                                <MenuItem
                                                    to={`/organizer/${selectedOrg}/events`}
                                                    icon={MdCalendarMonth}
                                                // className="flex items-center text-sm p-2 text-gray-900 rounded-lg hover:text-lime-600 "
                                                >
                                                    Manage Events
                                                </MenuItem>
                                            </li>
                                            <li>
                                                <MenuItem
                                                    to={`/organizer/${selectedOrg}/orders`}
                                                    icon={TbClipboardList}
                                                // className="flex items-center text-sm pl-1 pr-3 py-2 text-gray-900 rounded-lg hover:text-lime-600 "
                                                >
                                                    Orders
                                                </MenuItem>
                                            </li>
                                            <li>
                                                <MenuItem
                                                    to={`/organizer/${selectedOrg}/payments`}
                                                    icon={TbCoins}
                                                // className="flex items-center text-sm p-2 text-gray-900 rounded-lg hover:text-lime-600 "
                                                >
                                                    Payments and Tax
                                                </MenuItem>
                                            </li>
                                            {/* <li>
                                                <MenuItem
                                                    to={`/organizer/${selectedOrg}/scan`}
                                                    icon={BsQrCodeScan}
                                                // className="flex items-center text-sm p-2 text-gray-900 rounded-lg hover:text-lime-600 "
                                                >
                                                    Scan
                                                </MenuItem>
                                            </li> */}
                                        </ul>
                                    </li>


                                    <li className='mt-8'>
                                        <h5 class="mb-2 mx-4 text-sm font-semibold tracking-wide text-gray-900 uppercase lg:text-xs">Settings</h5>
                                        <ul className="py-1 list-unstyled fw-normal small">
                                            <li>
                                                <MenuItem
                                                    to={`/organizer/${selectedOrg}/team-management`}
                                                    icon={TbSettings}
                                                // className="flex items-center text-sm p-2 text-gray-900 rounded-lg hover:text-lime-600 "
                                                >
                                                    Team Management
                                                </MenuItem>
                                            </li>

                                        </ul>
                                    </li>

                                </ul>


                                {
                                    organizationDetails.onboarding_completed === false ?


                                        <div id="dropdown-cta" className="p-4 mt-8 rounded-lg bg-blue-50" role="alert">
                                            <div class="flex items-center mb-3">
                                                <span class="bg-orange-100 text-orange-800 text-sm font-semibold mr-2 px-2.5 py-0.5 rounded">Action Required</span>

                                            </div>
                                            <p class="mb-3 text-sm text-blue-800 ">
                                                Complete you payment details before your events can be listed
                                            </p>
                                            <Link
                                                className="text-sm text-blue-800 underline font-medium hover:text-blue-900"
                                                to={`/organizer/${selectedOrg}/payments`}>Finish Payment Setup
                                            </Link>
                                        </div>
                                        :
                                        <></>
                                }


                                <div className='block bottom-0 fixed w-[90%] '>
                                    <OrganizerSwitchDropdown items={orgs} />
                                </div>

                            </div>
                        </div>
                    </>
                )

                :

                (
                    <div class="flex h-screen">
                        <div class="m-auto">
                            <Loader />
                        </div>
                    </div>
                )
            }

        </>



    )
}

export default OrganizerSideNav