import { React, useState, useEffect } from 'react'
import StepperControl from '../StepperControl'
import { validateRequiredText, validateRequiredFile, validateRequiredEmail, validateNone } from '../utils/validators';
import { Link } from 'react-router-dom';


const AnonymousReservation = ({ ticketState, setTicketState, currentStep, steps, increaseStep, setIsNextDisabled }) => {

    const [isChecked, setIsChecked] = useState(false);


    function handleCheckboxChange(event) {
        setIsChecked(event.target.checked);
    }

    const handleChange = (name, value) => {
        setTicketState({
            ...ticketState, ticketHolderInfo: {
                ...ticketState.ticketHolderInfo, [name]:
                    { ...ticketState.ticketHolderInfo[name], value: value, error: null }
            }
        })
    };

    const onUpdateField = e => {
        const { name, value } = e.target;

        handleChange(name, value);
    };

    const handleBlur = e => {
        const name = e.target.name;
        const value = e.target.value;

        if (!ticketState.ticketHolderInfo[name].value) {
            return setTicketState({
                ...ticketState, ticketHolderInfo: {
                    ...ticketState.ticketHolderInfo, [name]:
                        { ...ticketState.ticketHolderInfo[name], error: "Required!" }
                }
            })
        }
    };

    const validateField = (field, value) => {
        switch (field) {
            case "firstName":
                return validateRequiredFile(value);
            case "lastName":
                return validateRequiredText(value);
            case "email":
                return validateRequiredEmail(value);
            case "confirmEmail":
                return validateRequiredEmail(value);
            default:
                return validateNone();
        }
    }


    const handleSubmit = (e) => {
        if (e == "prev") {
            increaseStep("prev");
        } else {
            let hasError = false;
            const updatedForm = { ...ticketState.ticketHolderInfo };
            console.log(updatedForm);
            for (const [name, field] of Object.entries(updatedForm)) {
                const error = validateField(name, field.value);
                if (error.msg !== null) {
                    hasError = true;
                    ticketState.ticketHolderInfo[name] = { ...field, error: error.msg };
                }
            }
            if (hasError) {
                setTicketState({ ...ticketState, updatedForm })
            } else {
                increaseStep("next");
            }
        }
    };

    useEffect(() => {
        const updatedForm = { ...ticketState.ticketHolderInfo };
        const tos = isChecked;
        const hasMissingValues = Object.values(updatedForm).some(field => field.value == null || field.value === "");

        setIsNextDisabled(hasMissingValues || Object.keys(updatedForm).length !== 4 || !tos);
    }, [ticketState, isChecked]);


    return (
        <div className="mt-1">
            <h2 className="text-2xl font-semibold mb-2 ml-6">Ticket Information</h2>
            {/* <div className='text-xs'>Log in for faster checkout experience</div> */}

            {/* */}
            <div className="overflow-hidden">
                <div className="bg-white px-4 py-5 sm:p-6">
                    <div className="grid grid-cols-6 gap-6">

                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="firstName" className="block text-sm font-medium leading-6 text-gray-900">
                                First Name
                            </label>
                            <input
                                type="text"
                                name="firstName"
                                id="firstName"
                                autoComplete="given-name"
                                className={`px-4 h-[47px] mt-2 block w-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${ticketState.ticketHolderInfo.firstName.error !== null ? 'border border-red-700' : ''}`}
                                // className="px-4 py-2 rounded border border-gray-300 focus:outline-none focus:border-blue-500"
                                onChange={onUpdateField}
                                onBlur={(e) => handleBlur(e)}
                                value={ticketState.ticketHolderInfo.firstName.value}
                            />
                            <span className="text-xs text-red-700">{ticketState.ticketHolderInfo.firstName.error}</span>
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="lastName" className="block text-sm font-medium leading-6 text-gray-900">
                                Last Name
                            </label>
                            <input
                                type="text"
                                name="lastName"
                                id="lastName"
                                autoComplete="family-name"
                                className={`px-4 h-[47px] mt-2 block w-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${ticketState.ticketHolderInfo.lastName.error !== null ? 'border border-red-700' : ''}`}
                                onChange={onUpdateField}
                                onBlur={(e) => handleBlur(e)}
                                value={ticketState.ticketHolderInfo.lastName.value}
                            />
                            <span className="text-xs text-red-700">{ticketState.ticketHolderInfo.lastName.error}</span>
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                Email
                            </label>
                            <input
                                type="text"
                                name="email"
                                id="email"
                                autoComplete="email"
                                className={`px-4 h-[47px] mt-2 block w-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${ticketState.ticketHolderInfo.email.error !== null ? 'border border-red-700' : ''}`}
                                onChange={onUpdateField}
                                onBlur={(e) => handleBlur(e)}
                                value={ticketState.ticketHolderInfo.email.value}
                            />
                            <span className="text-xs text-red-700">{ticketState.ticketHolderInfo.email.error}</span>
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="confirmEmail" className="block text-sm font-medium leading-6 text-gray-900">
                                Confirm Email
                            </label>
                            <input
                                type="text"
                                name="confirmEmail"
                                id="confirmEmail"
                                className={`px-4 h-[47px] mt-2 block w-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${ticketState.ticketHolderInfo.confirmEmail.error !== null ? 'border border-red-700' : ''}`}
                                onChange={onUpdateField}
                                onBlur={(e) => handleBlur(e)}
                                value={ticketState.ticketHolderInfo.confirmEmail.value}
                            />
                            <span className="text-xs text-red-700">{ticketState.ticketHolderInfo.confirmEmail.error}</span>
                        </div>

                        <div class="col-span-6 mb-[0.125rem] block min-h-[1.5rem]">
                            <label className="inline-flex items-center">
                                <input
                                    type="checkbox"
                                    className="form-checkbox h-5 w-5 text-blue-600"
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                />
                                <span className="ml-2 text-gray-700">I accept the <Link to="/tos" target="_blank" className="text-[#008bbf] hover:opacity-80"> Terms of Service</Link></span>
                            </label>
                        </div>

                    </div>
                </div>

            </div>

            {/* <StepperControl
                handleClick={handleSubmit}
                currentStep={currentStep}
                steps={steps}
                nextText='Checkout'
            /> */}

        </div>
    )
}

export default AnonymousReservation