import React from 'react'
import bg_cta from '../images/cta_organizers.svg';
import speakers from '../images/lt_speaker.svg';


const OrganizersCallToAction = () => {
    return (
        <div className=' max-w-7xl m-auto mt-20 -mb-36'>
            <div>
                <div className="mb-12">
                    <div>
                        <li className=" bg-gray-800 overflow-hidden group rounded-md relative">
                            <img src={bg_cta} alt="" className="absolute inset-0 w-full h-full  filter transition-all duration-700 grayscale transform group-hover:scale-110 group-hover:grayscale-0 object-cover object-center" />
                            <div className="relative isolate overflow-hidden">
                                <div className="mx-auto max-w-7xl lg:flex lg:py-8 lg:px-8 px-6">
                                    <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl lg:flex-shrink-0 lg:pt-8 ">
                                        <h1 className="mt-10 text-4xl font-bold tracking-tight text-white sm:text-6xl">Are you an organizer?</h1>
                                        <p className="mt-6 text-lg leading-8 text-gray-300">Maximize your event's success with Lymetickets.com - we help you sell more tickets, promote with ease and save time.</p>
                                        <div className="mt-10 mx-6 flex items-center gap-x-6">
                                            <a target="_blank" href="/organiser/sign_up?ref=B2C+Frontpage+Banner" className="bg-[#0453a3] rounded-md bg-brand-600 px-3.5 py-1.5 text-base font-semibold leading-7 text-white shadow-sm hover:bg-brand-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600">Get started</a>
                                            <a target="_blank" href="/l/product?ref=B2C+Frontpage+Banner" className=" text-base font-semibold leading-7 text-gray-300">Learn more</a>
                                        </div>
                                    </div>
                                    <div className="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mt-0 lg:mr-0 lg:max-w-none lg:flex-none xl:ml-48 pb-6">
                                        <div className="w-full flex-none sm:max-w-5xl ">
                                            <div>
                                                <img src={speakers} alt="" className='w-96 mx-auto' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrganizersCallToAction