import React, { useState, useEffect } from 'react'
// import Table, { AvatarCell, SelectColumnFilter, StatusPill } from '../components/Table'
import Table, { StatusPill, OrderNumberCell, DateCell } from '../components/Table'
import { useDispatch, useSelector } from 'react-redux';
import { requestMyOrders } from '../store/actions/index';
import { useAuth0 } from '@auth0/auth0-react';
import Loader from '../components/Loader';
import useAccessToken from '../hooks/useAccessToken';


const Order = () => {

    const dispatch = useDispatch();

    const myOrders = useSelector((state) => state.myOrders.ordersList);
    const myOrdersLoading = useSelector((state) => state.myOrders.loading);
    const { user, getAccessTokenSilently, getIdTokenClaims, isLoading, isAuthenticated } = useAuth0();

    // useAccessToken()

    useEffect(() => {
        const loadOffers = async () => {
            const accessToken = await getAccessTokenSilently();

            dispatch(requestMyOrders(accessToken));
        }

        loadOffers();
    }, []);

    const columns = React.useMemo(() => [
        {
            Header: "Order Number",
            accessor: 'order_id',
            Cell: OrderNumberCell,
        },
        {
            Header: "Name",
            accessor: 'title',
        },
        {
            Header: "Date",
            accessor: 'order_date',
            Cell: DateCell
        },
        {
            Header: "Status",
            accessor: 'status',
            Cell: StatusPill,
        }
    ], [])

    return (
        <div>
            {
                !myOrdersLoading ? (

                    <div className="flex min-h-screen h-screen bg-white text-gray-900 mt-10 overflow-hidden">
                        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 pt-4 overflow-auto">

                            {/* <div className='lg:col-span-2'>
                                <h4 className='text-[#003863] text-xl font-semibold'>Pending</h4>
                            </div>

                            <div className="py-6">
                                <Table columns={columns} data={data.slice(0, 3)} />
                            </div> */}

                            <div className='lg:col-span-2 mt-4'>
                                <h4 className='text-gray-900 text-xl font-semibold'>Orders <span className='text-gray-500 text-xl'>{myOrders.length}</span></h4>
                            </div>

                            <div className="py-6">
                                <Table columns={columns} data={myOrders} />
                            </div>
                        </div>
                    </div>)
                    : < Loader />}
        </div>
    )
}

export default Order