import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const useAccessToken = () => {
    const [idToken, setIdToken] = useState(null);
    const [accessToken, setAccessToken] = useState(null);
    const { user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();

    useEffect(() => {

        const getAccessToken = async () => {

            try {

                const accessToken = await getAccessTokenSilently();
                const idToken = await getIdTokenClaims();

                console.log('Access Token:', accessToken)
                console.log('ID Token:', idToken.__raw)

                setIdToken(idToken);
                setAccessToken(accessToken);
            } catch (error) {
                console.log(error.message);
            }
        };

        getAccessToken();
    }, [getAccessTokenSilently, user?.sub]);

    return accessToken;
};

export default useAccessToken;