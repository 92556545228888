import React from 'react'
import Brand from './filters/Brand'
import OfferType from './filters/OfferType'
import Categories from './filters/Categories'

const Filters = ({ OffersList, onChange }) => {

    return (
        <div className='border-[1px] border-[#e6e8ed]'>
            <div className='flex border-b-solid border-b-[1px] border-b-[#e6e8ed]  px-[24px] py-[14px] justify-between'>
                <div className='font-bold tracking-normal'>Filters</div>
                <div className='text-[#0062AB] text-xs self-center uppercase cursor-pointer border-b-[#0062AB] border-b-[1px] hover:text-[#00226b] hover:border-b-[#00226b]'>clear</div>
            </div>
            <div className='py-4'>
                <Brand
                    OffersList={OffersList}
                    onChange={onChange}
                />
                < Categories
                    OffersList={OffersList}
                    onChange={onChange}
                />

                {/* <OfferType /> */}
            </div>
        </div>
    )
}

export default Filters