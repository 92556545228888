import React, { useEffect, useState, useRef } from 'react';
import "react-barcode-scanner/polyfill";
import { useDispatch, useSelector } from 'react-redux';
import { requestTicketValidation } from '../store/actions/index';
import { useAuth0 } from '@auth0/auth0-react';
import { TbCircleCheck, TbAlertCircle } from "react-icons/tb";
import Loader from './Loader';

// Styles
import "./QrStyles.css";

// Qr Scanner
import QrScanner from "qr-scanner";
import QrFrame from "../images/qr-frame.svg";

const QRScanner = ({ onQRCodeDetected }) => {
    const [resetTimeout, setResetTimeout] = useState(null); // State to hold the timeout ID

    // QR States
    const scanner = useRef();
    const videoEl = useRef(null);
    const qrBoxEl = useRef(null);
    const [qrOn, setQrOn] = useState(true);

    // Result
    const [scannedResult, setScannedResult] = useState("");

    // Success
    const onScanSuccess = (result) => {
        onQRCodeDetected(result?.data);
    };

    // Fail
    const onScanFail = (err) => {
        // console.log(err);
    };

    useEffect(() => {
        if (videoEl.current && !scanner.current) {
            // Instantiate the QR Scanner
            scanner.current = new QrScanner(videoEl.current, onScanSuccess, {
                onDecodeError: onScanFail,
                preferredCamera: "environment",
                highlightScanRegion: true,
                highlightCodeOutline: true,
                overlay: qrBoxEl.current || undefined,
            });

            // Start QR Scanner
            scanner.current
                .start()
                .then(() => setQrOn(true))
                .catch((err) => {
                    if (err) setQrOn(false);
                });
        }

        // Clean up on unmount
        return () => {
            if (!videoEl.current) {
                scanner.current?.stop();
            }
        };
    }, []);

    // If camera is not allowed in browser permissions, show an alert
    useEffect(() => {
        if (!qrOn)
            alert(
                "Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload."
            );
    }, [qrOn]);

    // point the camera at the QR code and wait for the prompt
    return (
        <>
            <div className="qr-reader">
                <video ref={videoEl}></video>
                <div ref={qrBoxEl} className="qr-box">
                    <img
                        src={QrFrame}
                        alt="Qr Frame"
                        width={256}
                        height={256}
                        className="qr-frame"
                    />
                </div>


                {scannedResult && (
                    <p
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            zIndex: 99999,
                            color: "white",
                        }}
                    >
                        Scanned Result: {scannedResult}
                    </p>
                )}
            </div>
        </>
    );
};

export default QRScanner;
