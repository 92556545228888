import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import OrganizerSideNav from '../components/OrganizerSideNav'
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { requestOrganizerPaymentSetup, requestOrganizerPaymentDetails } from '../store/actions/index';
import Loader from '../components/Loader';
import Table2 from '../components/Table2';
import { MdInfoOutline } from "react-icons/md";
import {
    ConnectPayments,
    ConnectBalances,
    ConnectPayoutsList,
    ConnectComponentsProvider,
} from "@stripe/react-connect-js";
import { loadConnectAndInitialize } from "@stripe/connect-js";
import CardMetric from '../components/dashboard/CardMetric'




const Payments = () => {

    const { user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const transactionTableHeaders = ["Date", "Bank/Card", "Status", "Amount"]
    const tableType = "latestPayoutTransactions"

    const organizationDetails = useSelector((state) => state.organizerDetails.orgDetails);
    const orgPaymentSetupDetails = useSelector((state) => state.organizerPaymentSetup.orgPaymentSetupDetails);
    const orgPaymentSetupDetailsLoading = useSelector((state) => state.organizerPaymentSetup.loading);
    const orgPaymentDetails = useSelector((state) => state.organizerPaymentDetails.orgPaymentDetails);
    const orgPaymentDetailsLoading = useSelector((state) => state.organizerPaymentDetails.loading);

    // const [stripeConnectInstance] = useState(() => {

    //     const fetchClientSecret = async () => {
    //         const accessToken = await getAccessTokenSilently();
    //         // Fetch the AccountSession client secret
    //         const response = await fetch('https://whwzgyz9id.execute-api.us-east-1.amazonaws.com/payout-settings', {
    //             method: "POST",
    //             body: JSON.stringify({ "org_id": organizationDetails.org_id }),
    //             headers: {
    //                 'Authorization': `Bearer ${accessToken}`, // set the Authorization header properly
    //                 'Content-Type': 'application/json' // specify content type
    //             }
    //         });
    //         if (!response.ok) {
    //             // Handle errors on the client side here
    //             const { error } = await response.json();
    //             console.log('An error occurred: ', error);
    //             return undefined;
    //         } else {
    //             const { data: { client_secret: clientSecret } } = await response.json();
    //             return clientSecret;
    //         }
    //     };

    //     return loadConnectAndInitialize({
    //         // This is your test publishable API key.
    //         publishableKey: "pk_test_51NDxbkJatF2W2kOGcNYU5DVMbvYLCL1j4CmpwYTcYwVRhVxyg43nKb24U2NANWesvDIQQWS1tEqSEL8ZbUmTK5fT006Qc3qRpk",
    //         fetchClientSecret: fetchClientSecret,
    //         appearance: {
    //             overlays: 'dialog',
    //             variables: {
    //                 // colorPrimary: '#625afa',
    //                 // colorPrimary: '#82C342',
    //                 colorBackground: '#FFFFFF'
    //             },
    //         },
    //     })
    // });


    const handlePaymentSetup = async () => {
        if (organizationDetails && organizationDetails.org_id) {
            const accessToken = await getAccessTokenSilently();
            const payload = {
                // Moved to backend because stripe configuration
                // "currency": "USD",
                "org_id": organizationDetails.org_id
                // "country_code": "US"
            };
            dispatch(requestOrganizerPaymentSetup(payload, accessToken));
        }
    };

    useEffect(() => {
        const loadOrgPaymentDetails = async () => {
            if (loading && organizationDetails && organizationDetails.org_id) {
                const accessToken = await getAccessTokenSilently();
                const json = {
                    "org_id": organizationDetails.org_id,
                };
                dispatch(requestOrganizerPaymentDetails(json, accessToken));
                setLoading(false);
            }
        };

        loadOrgPaymentDetails();
    }, [loading, organizationDetails, dispatch, getAccessTokenSilently]);


    useEffect(() => {
        if (orgPaymentSetupDetails?.url) {
            const url = orgPaymentSetupDetails.url
            const newTab = window.open(url, "_blank");
            if (newTab) {
                // If the new tab was opened successfully
                // you can perform additional operations here
                // For example, you can focus the new tab:
                newTab.focus();
            } else {
                // If the new tab was blocked by the browser's popup blocker
                // you can provide an alternative action for the user.
                alert("New tab was blocked by the browser's popup blocker.");
            }
        }
        console.log(orgPaymentSetupDetails);
    }, [orgPaymentSetupDetailsLoading, orgPaymentSetupDetails])


    function formatCurrency(amountInCents) {
        const amountInDollars = amountInCents / 100;
        return amountInDollars.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    }

    if (!organizationDetails) {
        return <Loader />;
    }


    return (
        <>
            <div class="p-4 lg:ml-64 min-h-screen">
                {/* <div className='bg-[#003863] text-white text-sm p-6 -m-4 leading-4'>
                    Disclaimer:Early payouts will not be allowed once your event is within seven (7) days of completion. We apologize for any inconvenience.
                </div> */}

                <div className="flex flex-col items-start justify-between pb-6 space-y-4 border-b lg:items-center lg:space-y-0 lg:flex-row m-4 mt-8">
                    <h1 className="text-2xl font-semibold whitespace-nowrap">Payments</h1>
                </div>

                {/* <!-- Start Content --> */}
                {!orgPaymentDetailsLoading && orgPaymentDetails && !loading ?

                    <>

                        {organizationDetails.onboarding_completed === false ?
                            <div className="mb-4 rounded-lg bg-white px-6 py-5 text-base text-neutral-600 flex shadow-xl justify-between">
                                <div className='flex'>
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                    </svg>
                                    Finish setting up your payment details, so you can open for business.
                                </div>
                                <div class="flex items-center justify-center gap-x-6">
                                    <div className="rounded-md bg-lime-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-lime-500 hover:cursor-pointer"
                                        onClick={handlePaymentSetup}
                                    >
                                        {
                                            orgPaymentSetupDetailsLoading ?
                                                <svg aria-hidden="true" role="status" class="inline w-4 h-4 mr-3 text-gray-200 animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                                                </svg>
                                                : <></>
                                        }
                                        Finish setup
                                    </div>
                                    {/* <a href="#" class="text-sm font-semibold leading-6 text-gray-900">Finish setup <span aria-hidden="true">→</span></a> */}
                                </div>
                            </div>

                            : <></>
                        }

                        <div class="grid grid-cols-1 gap-5 mt-6 m-4 sm:grid-cols-2 lg:grid-cols-4">
                            <CardMetric
                                title="Pending Account Balance"
                                value={formatCurrency(orgPaymentDetails.balance?.pending?.[0]?.amount || 0)}
                                currency={orgPaymentDetails.balance?.pending?.[0]?.currency?.toUpperCase() || "USD"}
                            />
                            <CardMetric
                                title="Current Available Balance"
                                value={formatCurrency(orgPaymentDetails.balance?.available?.[0]?.amount || 0)}
                                currency={orgPaymentDetails.balance?.available?.[0]?.currency.toUpperCase() || "USD"}
                            />
                            <CardMetric
                                title="Open Disputes"
                                value={orgPaymentDetails.balance?.pending?.[0]?.amounttt ? orgPaymentDetails.balance?.pending?.[0]?.amounttt : '-'}
                            />
                            {
                                organizationDetails.onboarding_completed === true ?

                                    <div class="w-full flex flex-col mx-auto p-4 transition-shadow border bg-white rounded-lg shadow-sm justify-around">
                                        <button type="button" class="inline-flex w-full h-12 align-middle border-none self-center items-center justify-center rounded-md bg-lime-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-lime-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600" >
                                            Payout
                                        </button>

                                        <a
                                            href={orgPaymentDetails.settings_link?.url}
                                            target='_blank'
                                            className="inline-flex w-full h-12 align-middle border-none self-center items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 hover:cursor-pointer"
                                        >
                                            Express Dashboard
                                        </a>


                                    </div> : <></>
                            }
                        </div>


                        <div className="flex flex-col items-start justify-between pb-6 space-y-4 border-b lg:items-center lg:space-y-0 lg:flex-row m-4 mt-8">
                            <h1 className="text-xl font-semibold whitespace-nowrap">Payouts</h1>
                        </div>

                        {/* {
                            organizationDetails.onboarding_completed === true ?
                                <div className="container">
                                    <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
                                        <ConnectPayoutsList />
                                    </ConnectComponentsProvider>
                                </div> :
                                <>
                                    <div class="flex h-[10rem]">
                                        <div class="m-auto">
                                            <div className='flex flex-col items-center text-slate-500'>
                                                <span className='font-semibold text-2xl mb-4'>No payouts found</span>
                                                <span>You dont have any payouts, once payouts occur, you will see them here</span>
                                                <span>
                                                    <div className='mt-4'>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </>
                        } */}
                    </>

                    : <Loader />
                }
            </div>
        </>
    )
}

export default Payments