import { call, put, takeLatest, all } from "redux-saga/effects";
import * as actionTypes from '../actions/actionTypes';
import * as actions from '../actions';

import {
    getOfferDetails,
    getAllOffers,
    getOffersByCategory,
    submitEvent,
    editEvent,
    getUserOrgs,
    purchaseTickets,
    getMyOrders,
    getOrderDetailsViaOrderId,
    getOrganizerDetails,
    getOrganizerPaymentSetup,
    getOrganizerPaymentDetails,
    getTicketValidation,
    getOrganizerDashboardMetrics,
    getOrganizerEvents,
    getOrganizerOrders,
    requestRefundByOrganizer,
    createOrganization,
    getOrganizerAdminUsers,
    getOrganizerAdminRoles,
    sendOrganizerAdminInvitation,
    requestResendTicketsByOrganizer,
    requestDuplicateEventByOrganizer
} from './api';




export function* getOfferDetailsSaga(action) {
    try {
        const res = yield call(getOfferDetails, action.payload)
        yield put(actions.receiveOfferDetails(res.data));
    } catch (err) {
        yield put(actions.errorOfferDetails(err))
    }
}

function* watchGetOfferDetailsSaga() {
    yield takeLatest(actionTypes.REQUEST_OFFER_DETAILS, getOfferDetailsSaga);
}





export function* getAllOffersSaga(action) {
    try {
        const res = yield call(getAllOffers, action.payload)
        yield put(actions.receiveAllOffers(res.data));
    } catch (err) {
        yield put(actions.errorAllOffers(err))
    }
}

function* watchGetAllOffersSaga() {
    yield takeLatest(actionTypes.REQUEST_ALL_OFFERS, getAllOffersSaga);
}







export function* getOffersByCategorySaga(action) {
    try {
        const res = yield call(getOffersByCategory, action.payload)
        yield put(actions.receiveOffersByCategory(res.data));
    } catch (err) {
        yield put(actions.errorOffersByCategory(err))
    }
}

function* watchGetOffersByCategorySaga() {
    yield takeLatest(actionTypes.REQUEST_OFFERS_BY_CATEGORY, getOffersByCategorySaga);
}



export function* submitEventSaga(action) {
    try {
        const res = yield call(submitEvent, action.payload, action.jwt)
        if (res.data == null || res.data == undefined) {
            yield put(actions.errorSubmissionOfEvent(res))
        } else {
            yield put(actions.receiveSubmissionOfEvent(res.data));
        }

    } catch (err) {
        yield put(actions.errorSubmissionOfEvent(err))
    }
}

function* watchsubmitEventSaga() {
    yield takeLatest(actionTypes.REQUEST_SUBMISSION_OF_EVENT, submitEventSaga);
}


export function* alterEventSaga(action) {
    try {
        const res = yield call(editEvent, action.payload, action.orgId, action.eventId, action.jwt)
        if (res.data == null || res.data == undefined) {
            yield put(actions.errorAlterationOfEvent(res))
        } else {
            yield put(actions.receiveAlterationOfEvent(res.data));
        }

    } catch (err) {
        yield put(actions.errorSubmissionOfEvent(err))
    }
}

function* watchalterEventSaga() {
    yield takeLatest(actionTypes.REQUEST_ALTERATION_OF_EVENT, alterEventSaga);
}

export function* getUserOrgSaga(action) {
    try {
        const res = yield call(getUserOrgs, action.jwt)
        yield put(actions.receiveUserOrganizaions(res));
    } catch (err) {
        yield put(actions.errorUserOrganizaions(err))
    }
}

function* watchgetUserOrgs() {
    yield takeLatest(actionTypes.REQUEST_USER_ORG, getUserOrgSaga);
}



export function* purchaseTicketsSaga(action) {
    try {
        const res = yield call(purchaseTickets, action.payload, action.jwt, action.tokenType)
        if (res.data == null || res.data == undefined) {
            yield put(actions.errorPurchaseTickets(res))
        } else {
            yield put(actions.receivePurchaseTickets(res.data));
        }

    } catch (err) {
        yield put(actions.errorPurchaseTickets(err))
    }
}

function* watchPurchaseTicketsSaga() {
    yield takeLatest(actionTypes.REQUEST_PURCHASE_OF_TICKETS, purchaseTicketsSaga);
}


export function* getMyOrdersSaga(action) {
    try {
        const res = yield call(getMyOrders, action.jwt)
        yield put(actions.receiveMyOrders(res.data));
    } catch (err) {
        yield put(actions.errorMyOrders(err))
    }
}

function* watchGetMyOrdersSaga() {
    yield takeLatest(actionTypes.REQUEST_MY_ORDERS, getMyOrdersSaga);
}


export function* OrderDetailsSaga(action) {
    try {
        const res = yield call(getOrderDetailsViaOrderId, action.payload, action.jwt)
        yield put(actions.receiveOrderDetails(res.data));
    } catch (err) {
        yield put(actions.errorOrderDetails(err))
    }
}

function* watchOrderDetailsSaga() {
    yield takeLatest(actionTypes.REQUEST_ORDER_DETAILS, OrderDetailsSaga);
}


export function* getOrganizerDetailsSaga(action) {
    try {
        const res = yield call(getOrganizerDetails, action.payload, action.jwt)
        yield put(actions.receiveOrganizerDetails(res.data));
    } catch (err) {
        yield put(actions.errorOfferDetails(err))
    }
}

function* watchGetOrganizerDetailsSaga() {
    yield takeLatest(actionTypes.REQUEST_ORGANIZER_DETAILS, getOrganizerDetailsSaga);
}


export function* getOrganizerPaymentSetupSaga(action) {
    try {
        const res = yield call(getOrganizerPaymentSetup, action.payload, action.jwt)
        yield put(actions.receiveOrganizerPaymentSetup(res.data));
    } catch (err) {
        yield put(actions.errorOrganizerPaymentSetup(err))
    }
}

function* watchGetOrganizerPaymentSetupSaga() {
    yield takeLatest(actionTypes.REQUEST_ORGANIZER_PAYMENT_SETUP, getOrganizerPaymentSetupSaga);
}


export function* setSelectedOrganizationSaga(action) {
    try {
        yield put(actions.receiveSelectedOrganization(action.payload));
    } catch (err) {
        console.log("sumb guh wrong");
    }
}

function* watchSetSelectedOrganizationSaga() {
    yield takeLatest(actionTypes.SET_SELECTED_ORGANIZER, setSelectedOrganizationSaga);
}


export function* getOrganizerPaymentDetailsSaga(action) {
    try {
        const res = yield call(getOrganizerPaymentDetails, action.payload, action.jwt)
        yield put(actions.receiveOrganizerPaymentDetails(res.data));
    } catch (err) {
        yield put(actions.errorOrganizerPaymentDetails(err))
    }
}

function* watchGetOrganizerPaymentDetailsSaga() {
    yield takeLatest(actionTypes.REQUEST_ORGANIZER_PAYMENT_DETAILS, getOrganizerPaymentDetailsSaga);
}

export function* getTicketValidationSaga(action) {
    try {
        const res = yield call(getTicketValidation, action.orgId, action.payload, action.jwt)
        if (res.status != 200) {
            yield put(actions.errorTicketValidation(res.data))
        } else {
            yield put(actions.receiveTicketValidation(res));
        }
    } catch (err) {
        yield put(actions.errorTicketValidation(err))
    }
}

function* watchGetTicketValidationSaga() {
    yield takeLatest(actionTypes.REQUEST_TICKET_VALIDATION, getTicketValidationSaga);
}


export function* getDashboardMetricsSaga(action) {
    try {
        const res = yield call(getOrganizerDashboardMetrics, action.orgId, action.eventId, action.jwt)
        yield put(actions.receiveDashboardMetrics(res.data));
    } catch (err) {
        yield put(actions.errorDashboardMetrics(err))
    }
}

function* watchGetDashboardMetricsSaga() {
    yield takeLatest(actionTypes.REQUEST_ORGANIZER_DASHBOARD_METRICS, getDashboardMetricsSaga);
}


export function* getOrganizerEventsSaga(action) {
    try {
        const res = yield call(getOrganizerEvents, action.orgId, action.jwt)
        yield put(actions.receiveOrganizerEvents(res.data));
    } catch (err) {
        yield put(actions.errorOrganizerEvents(err))
    }
}

function* watchGetOrganizerEventsSaga() {
    yield takeLatest(actionTypes.REQUEST_ORGANIZER_EVENTS, getOrganizerEventsSaga);
}


export function* getOrganizerOrdersSaga(action) {
    try {
        const res = yield call(getOrganizerOrders, action.orgId, action.jwt)
        yield put(actions.receiveOrganizerOrders(res.data));
    } catch (err) {
        yield put(actions.errorOrganizerOrders(err))
    }
}

function* watchGetOrganizerOrdersSaga() {
    yield takeLatest(actionTypes.REQUEST_ORGANIZER_ORDERS, getOrganizerOrdersSaga);
}


export function* getRefundFromOrganizerSaga(action) {
    try {
        const res = yield call(requestRefundByOrganizer, action.payload, action.jwt)
        yield put(actions.receiveOrganizerRefundOrder(res.data));
    } catch (err) {
        yield put(actions.errorOrganizerRefundOrder(err))
    }
}

function* watchGetRefundFromOrganizerSaga() {
    yield takeLatest(actionTypes.REQUEST_REFUND_BY_ORGANIZER, getRefundFromOrganizerSaga);
}


export function* createOrganizationSaga(action) {
    try {
        const res = yield call(createOrganization, action.payload, action.jwt)
        if (res.data == null || res.data == undefined) {
            yield put(actions.errorCreateOrganization(res))
        } else {
            yield put(actions.receiveCreateOrganization(res.data));
        }

    } catch (err) {
        yield put(actions.errorCreateOrganization(err))
    }
}

function* watchCreateOrganizationSaga() {
    yield takeLatest(actionTypes.REQUEST_CREATE_ORGANIZATION, createOrganizationSaga);
}






export function* getOrganizerAdminUsersSaga(action) {
    try {
        const res = yield call(getOrganizerAdminUsers, action.orgId, action.jwt)
        yield put(actions.receiveOrganizationAdminUsers(res.data));
    } catch (err) {
        yield put(actions.errorOrganizationAdminUsers(err))
    }
}

function* watchGetOrganizerAdminUsersSaga() {
    yield takeLatest(actionTypes.REQUEST_ORGANIZER_ADMIN_USERS, getOrganizerAdminUsersSaga);
}


export function* getOrganizerAdminRolesSaga(action) {
    try {
        const res = yield call(getOrganizerAdminRoles, action.orgId, action.jwt)
        yield put(actions.receiveOrganizationAdminRoles(res.data));
    } catch (err) {
        yield put(actions.errorOrganizationAdminRoles(err))
    }
}

function* watchGetOrganizerAdminRolesSaga() {
    yield takeLatest(actionTypes.REQUEST_ORGANIZER_ADMIN_ROLES, getOrganizerAdminRolesSaga);
}



export function* getOrganizerAdminInvitationSaga(action) {
    try {
        const res = yield call(sendOrganizerAdminInvitation, action.orgId, action.jwt, action.payload)
        yield put(actions.receiveOrganizationAdminInvitation(res.data));
    } catch (err) {
        yield put(actions.errorOrganizationAdminInvitation(err))
    }
}

function* watchGetOrganizerAdminInvitationSaga() {
    yield takeLatest(actionTypes.REQUEST_ORGANIZER_ADMIN_INVITATION, getOrganizerAdminInvitationSaga);
}


export function* ResendTicketsFromOrganizerSaga(action) {
    try {
        const res = yield call(requestResendTicketsByOrganizer, action.payload, action.jwt)
        yield put(actions.receiveOrganizerResendTickets(res.data));
    } catch (err) {
        yield put(actions.errorOrganizerResendTickets(err))
    }
}

function* watchResendTicketsFromOrganizerSaga() {
    yield takeLatest(actionTypes.REQUEST_RESEND_TICKETS_BY_ORGANIZER, ResendTicketsFromOrganizerSaga);
}


export function* DuplicateEventSaga(action) {
    try {
        const res = yield call(requestDuplicateEventByOrganizer, action.payload, action.jwt)
        yield put(actions.receiveOrganizerDuplicateEvent(res.data));
    } catch (err) {
        yield put(actions.errorOrganizerDuplicateEvent(err))
    }
}

function* watchDuplicateEventSaga() {
    yield takeLatest(actionTypes.REQUEST_ORGANIZER_DUPLICATE_EVENT, DuplicateEventSaga);
}

export default function* rootSaga() {
    yield all([
        watchGetOfferDetailsSaga(),
        watchGetAllOffersSaga(),
        watchGetOffersByCategorySaga(),
        watchsubmitEventSaga(),
        watchalterEventSaga(),
        watchgetUserOrgs(),
        watchPurchaseTicketsSaga(),
        watchGetMyOrdersSaga(),
        watchOrderDetailsSaga(),
        watchGetOrganizerDetailsSaga(),
        watchGetOrganizerPaymentSetupSaga(),
        watchSetSelectedOrganizationSaga(),
        watchGetOrganizerPaymentDetailsSaga(),
        watchGetTicketValidationSaga(),
        watchGetDashboardMetricsSaga(),
        watchGetOrganizerEventsSaga(),
        watchGetOrganizerOrdersSaga(),
        watchGetRefundFromOrganizerSaga(),
        watchCreateOrganizationSaga(),
        watchGetOrganizerAdminUsersSaga(),
        watchGetOrganizerAdminRolesSaga(),
        watchGetOrganizerAdminInvitationSaga(),
        watchResendTicketsFromOrganizerSaga(),
        watchDuplicateEventSaga()
    ]);
};