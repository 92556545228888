import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { requestOrganizerRefundOrder, resetOrganizerRefundOrder, requestOrganizerResendTickets, resetOrganizerResendTickets } from '../store/actions/index';
import { StatusPill } from './common/StatusPillUtils';
import buttonLoader from '../images/loaders/buttonLoader.svg';
import { convertUTCToHumanTime } from '../components/common/dateUtils';
import Table3 from '../components/Table3';




const RefundModal = ({ orderDetails, onClose }) => {

    const { user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();

    const dispatch = useDispatch();


    const organizationResendTickets = useSelector((state) => state.organizerResendTickets.orgResendTickets);
    const organizationResendTicketsLoading = useSelector((state) => state.organizerResendTickets.loading);

    const organizationOrderRefund = useSelector((state) => state.organizerRefund.orgOrderRefund);
    const organizationOrderRefundLoading = useSelector((state) => state.organizerRefund.loading);
    const organizationDetails = useSelector((state) => state.organizerDetails.orgDetails);


    const [isEditing, setIsEditing] = useState(false);
    const [tempDetails, setTempDetails] = useState({ ...orderDetails });

    const orderDetailsTableHeaders = ["Ticket Type", "Qty", "Price", "Subtotal"]
    const tableType = "orderDetails"


    useEffect(() => {
        if (!organizationResendTicketsLoading && Object.keys(organizationResendTickets).length > 0) {
            closeModal()
        }
    }, [organizationResendTickets, organizationResendTicketsLoading]);


    const submitOrderRefund = async () => {
        const accessToken = await getAccessTokenSilently();
        const payload = {
            "order_id": orderDetails.order_id,
            "org_id": organizationDetails.org_id
        }
        dispatch(requestOrganizerRefundOrder(payload, accessToken));
    }


    const submitResendTickets = async () => {
        const accessToken = await getAccessTokenSilently();
        const payload = {
            "order_id": orderDetails.order_id,
            "org_id": organizationDetails.org_id
        }
        console.log(payload, accessToken);
        dispatch(requestOrganizerResendTickets(payload, accessToken));
    }

    const closeModal = () => {
        dispatch(resetOrganizerRefundOrder())
        dispatch(resetOrganizerResendTickets())
        onClose()
    }


    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleCancelClick = () => {
        setTempDetails({ ...orderDetails });
        setIsEditing(false);
    };

    const handleSaveClick = () => {
        // Here you would normally save the changes to the server
        // After successful save, update the orderDetails state in the parent component if needed
        setIsEditing(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setTempDetails({ ...tempDetails, [name]: value });
    };

    function paymentNetworkImg(network) {
        if (!network) {
            return <></>;  // Return an empty fragment if network is blank or undefined
        }

        switch (network.toLowerCase()) {
            case 'visa':
                return <img src='/images/paymentMethods/visa_20.svg' alt="visa card" />
            case 'mastercard':
                return <img src='/images/paymentMethods/mastercard_20.svg' alt="mastercard card" />;
            case 'amex':
                return <img src='/images/paymentMethods/amex_20.svg' alt="amex card" />;
            case 'discover':
                return <img src='/images/paymentMethods/discover_20.svg' alt="discover card" />;
            default:
                return <></>;  // Return an empty fragment if network doesn't match any known type
        }
    }


    // TODO: need to handle exception when refund is unsuccessful

    return (
        <div className="fixed inset-0 z-50 backdrop-blur-sm bg-gray-500 bg-opacity-75  overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen px-2 sm:px-4">

                {/* <div className="fixed inset-0 transition-opacity">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div> */}



                {/* Modal content */}
                <div className="flex-col align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full">
                    <div className='flex flex-auto overflow-hidden'>
                        <button onClick={closeModal} type="button" className="absolute left-[85%] sm:left-[90%] top-[3%] h-fit text-gray-400 bg-slate-100 hover:bg-gray-200 hover:text-gray-900 border-none rounded-full text-sm p-1.5 z-30" data-modal-hide="extralarge-modal">
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            <span className="sr-only">Close modal</span>
                        </button>


                        {/* Left pane */}
                        <div className='flex-col w-full'>
                            {/* Modal header */}
                            <div class="flex items-center justify-start p-5 border-b rounded-t">
                                <div className='flex flex-col sm:flex-row '>
                                    <h3 class="text-xl font-medium text-gray-900 mr-1 lg:mr-4">
                                        Order #{String(orderDetails.order_id).padStart(6, '0')}
                                    </h3>
                                    <span>{StatusPill(orderDetails.order_status)}</span>
                                </div>

                            </div>

                            {
                                Object.keys(organizationOrderRefund).length == 0 ?

                                    <>
                                        <div className='flex flex-col'>
                                            <div className='order-last sm:order-first'>
                                                <div>
                                                    {isEditing ?
                                                        <>
                                                        </> : <>
                                                            <div className='m-4 mb-1'>
                                                                <button
                                                                    disabled={orderDetails.order_status.toLowerCase() === 'refunded' ? true : false}
                                                                    type="button"
                                                                    className="text-white bg-[#529535] hover:opacity-90 font-medium border-none rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 w-full sm:w-fit disabled:opacity-30 disabled:bg-slate-700 disabled:border-slate-300"
                                                                    onClick={() => submitResendTickets()}
                                                                >
                                                                    {
                                                                        organizationResendTicketsLoading ?
                                                                            <svg aria-hidden="true" role="status" class="inline w-4 h-4 mr-3 text-gray-200 animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                                                                            </svg>
                                                                            : <></>
                                                                    }

                                                                    Resend Tickets
                                                                </button>
                                                                <button
                                                                    disabled={orderDetails.order_status.toLowerCase() === 'refunded' ? true : false}
                                                                    className="bg-white border border-gray-300 py-2 px-4 text-sm font-medium rounded-lg text-gray-900 hover:bg-gray-100 mb-2 mr-2 w-full sm:w-fit disabled:opacity-30 disabled:border-slate-300"
                                                                    onClick={handleEditClick}>
                                                                    Edit Details
                                                                </button>
                                                                <button
                                                                    disabled={orderDetails.order_status.toLowerCase() === 'refunded' ? true : false}
                                                                    type="button"
                                                                    className="bg-white border border-gray-300 py-2 px-4 text-sm font-medium rounded-lg text-gray-900 hover:bg-gray-100 w-full sm:w-fit disabled:opacity-30 disabled:border-slate-300"
                                                                    onClick={() => submitOrderRefund()}
                                                                >
                                                                    {
                                                                        organizationOrderRefundLoading ?
                                                                            <svg aria-hidden="true" role="status" class="inline w-4 h-4 mr-3 text-gray-200 animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                                                                            </svg>
                                                                            : <></>
                                                                    }

                                                                    Cancel Order
                                                                </button>
                                                            </div>
                                                        </>}
                                                </div>
                                            </div>

                                            {/* Main Content */}
                                            <div className='inline-flex w-full mb-4'>
                                                <div className='w-full px-4 overflow-y-auto pt-1 text-gray-800'>
                                                    <div>
                                                        <div className='my-4 flex justify-between'>
                                                            <span className='font-bold w-1/2'>First Name:</span>
                                                            {isEditing ? (
                                                                <input
                                                                    type="text"
                                                                    name="first_name"
                                                                    value={tempDetails.first_name}
                                                                    className='px-4 block w-full border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                                                                    onChange={handleChange}
                                                                />
                                                            ) : (
                                                                <span>{orderDetails.first_name}</span>
                                                            )}
                                                        </div>
                                                        <div className='my-4 flex justify-between'>
                                                            <span className='font-bold w-1/2'>Last Name:</span>
                                                            {isEditing ? (
                                                                <input
                                                                    type="text"
                                                                    name="last_name"
                                                                    value={tempDetails.last_name}
                                                                    className='px-4 block w-full border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                                                                    onChange={handleChange}
                                                                />
                                                            ) : (
                                                                <span>{orderDetails.last_name}</span>
                                                            )}
                                                        </div>
                                                        <div className='my-4 flex justify-between'>
                                                            <span className='font-bold w-1/2'>Email:</span>
                                                            {isEditing ? (
                                                                <input
                                                                    type="email"
                                                                    name="email"
                                                                    value={tempDetails.email}
                                                                    className='px-4 block w-full border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                                                                    onChange={handleChange}
                                                                />
                                                            ) : (
                                                                <span>{orderDetails.email}</span>
                                                            )}
                                                        </div>
                                                        <div className='my-4 flex justify-between'>
                                                            <span className='font-bold'>Payment Method:</span>
                                                            <div className='flex flex-row'>
                                                                {orderDetails.price > 0 ? (
                                                                    <>
                                                                        <span className='mr-4'>
                                                                            {paymentNetworkImg(orderDetails.payment_method)}
                                                                        </span>
                                                                        <span>
                                                                            XXXXXXXXXXXX{orderDetails.last_4_payment_card}
                                                                        </span>
                                                                    </>
                                                                ) : <>
                                                                    <span>
                                                                        N/A
                                                                    </span>
                                                                </>}
                                                            </div>
                                                        </div>
                                                        <div className='my-4 flex justify-between'>
                                                            <span className='font-bold'>Price:</span>
                                                            {orderDetails.price > 0 ? (
                                                                <>
                                                                    <span>{orderDetails.price} {orderDetails.currency}</span>
                                                                </>
                                                            ) : <>
                                                                <span>
                                                                    Free
                                                                </span>
                                                            </>}
                                                        </div>
                                                        <div className='my-4 flex justify-between'>
                                                            <span className='font-bold'>Purchase Date</span>
                                                            <span>{convertUTCToHumanTime(orderDetails.ordered_date)}</span>
                                                        </div>
                                                    </div>


                                                    {/* Order Details Table */}
                                                    {/* <div className='mt-6'>
                                                        <table className='min-w-full bg-white'>
                                                            <thead>
                                                                <tr>
                                                                    <th className='py-2'>Ticket Type</th>
                                                                    <th className='py-2'>Qty</th>
                                                                    <th className='py-2'>Price</th>
                                                                    <th className='py-2'>Subtotal</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td className='py-2 px-4'>General Admission</td>
                                                                    <td className='py-2 px-4'>2</td>
                                                                    <td className='py-2 px-4'>${orderDetails.general_admission_price}</td>
                                                                    <td className='py-2 px-4'>${2 * orderDetails.general_admission_price}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='py-2 px-4'>VIP</td>
                                                                    <td className='py-2 px-4'>2</td>
                                                                    <td className='py-2 px-4'>${orderDetails.vip_price}</td>
                                                                    <td className='py-2 px-4'>${2 * orderDetails.vip_price}</td>
                                                                </tr>
                                                            </tbody>
                                                            <tfoot>
                                                                <tr>
                                                                    <td colSpan='3' className='py-2 px-4 text-right font-bold'>Total</td>
                                                                    <td className='py-2 px-4'>${(2 * orderDetails.general_admission_price) + (2 * orderDetails.vip_price)}</td>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div> */}

                                                    <Table3
                                                        tableType={tableType}
                                                        headers={orderDetailsTableHeaders}
                                                        rows={orderDetails['ticket_summary'] ? orderDetails['ticket_summary'] : []}
                                                    />





                                                    {/* <div className='bottom-5 right-0 fixed'>
                                                    <div className='bg-sky-50 flex w-full mb-5 p-6 text-justify text-sm'>
                                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                                    </div>

                                                    <div className='flex items-end bottom justify-end pr-5'>
                                                        <button type="button" class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border-white hover:border-solid hover:border-1 hover:border-gray-200 hover:text-blue-700 ">Cancel</button>
                                                        <button
                                                            type="button"
                                                            class="text-white bg-[#529535] hover:opacity-90 font-medium border-none rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
                                                            onClick={() => submitOrderRefund()}
                                                        >
                                                            {
                                                                organizationOrderRefundLoading ?
                                                                    <svg aria-hidden="true" role="status" class="inline w-4 h-4 mr-3 text-gray-200 animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                                                                    </svg>
                                                                    : <></>
                                                            }

                                                            Refund
                                                        </button>
                                                    </div>
                                                </div> */}

                                                    {
                                                        isEditing ?
                                                            <>
                                                                <div className='flex flex-col sm:flex-row items-end bottom justify-end mt-10'>
                                                                    <button
                                                                        // className="w-full sm:w-fit py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border-white hover:border-solid hover:border-1 hover:border-gray-200 hover:text-blue-700 "
                                                                        className="w-full sm:w-fit bg-white border border-gray-300 py-2 px-4 text-sm font-medium rounded-lg text-gray-900 hover:bg-gray-100 mb-2 sm:mr-2"

                                                                        onClick={handleCancelClick}
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                    <button
                                                                        className="w-full sm:w-fit text-white bg-[#529535] hover:opacity-90 font-medium border-none rounded-lg text-sm px-5 py-2.5 sm:mr-2 mb-2"
                                                                        onClick={() => handleSaveClick()}
                                                                    >
                                                                        {
                                                                            organizationOrderRefundLoading ?
                                                                                <img src={buttonLoader} />
                                                                                : <></>
                                                                        }

                                                                        Save
                                                                    </button>
                                                                </div>
                                                            </>
                                                            :
                                                            <></>
                                                    }

                                                </div>
                                            </div>

                                        </div>

                                    </>

                                    :

                                    organizationOrderRefund.status == 'success' ?
                                        <>
                                            <div className='vh-100 mt-16 mx-8'>
                                                <div className='flex flex-col w-[480px] left-0 top-0 m-auto mb-16'>
                                                    <div className='mx-auto mb-8 rounded-full transition duration-500 ease-in-out h-16 w-16 flex items-center justify-center py-3 bg-[#529535] text-white font-bold border border-[#529535]'>
                                                        <span className='bg-[#529535] text-white font-bold border border-[#529535] text-3xl'>
                                                            &#10003;
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <p className='font-bold text-[#003f6b] text-center'>
                                                            Order has been refunded.
                                                        </p>
                                                        <p className='font-semibold text-[#474b55] text-center'>
                                                            A confirmation email will be sent out shortly <br />
                                                            Please allow 5 - 10 business days for refund to reflect in your account
                                                            {/* <Link to={`/orders/${orderId}`}><span className='text-[#0062AB] text-sm'>#{orderId}</span></Link> */}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </>

                                        :

                                        <></>

                            }


                        </div>
                    </div>
                </div>



            </div>
        </div>
    )
}

export default RefundModal