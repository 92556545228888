import React, { useEffect, useState, useRef } from 'react'


const Stepper = ({ steps, currentStep }) => {

    const [newStep, setNewStep] = useState([]);
    const stepRef = useRef();

    const updateStep = (stepNumber, steps) => {

        const newSteps = [...steps]
        let count = 0;
        // console.log(newSteps.length);

        while (count < newSteps.length) {
            if ((count == stepNumber - 1) && (stepNumber - 1 == newSteps.length - 1)) {
                newSteps[count] = {
                    ...newSteps[count],
                    selected: true,
                    completed: true
                }
                count++;
            } else if (count == stepNumber - 1) {
                newSteps[count] = {
                    ...newSteps[count],
                    selected: true,
                    completed: false
                }
                count++;
            } else if (count < stepNumber - 1) {
                newSteps[count] = {
                    ...newSteps[count],
                    selected: false,
                    completed: true
                }
                count++;
            } else {
                newSteps[count] = {
                    ...newSteps[count],
                    selected: false,
                    completed: false
                }
                count++;
            }
        }
        // console.log(newSteps);
        return newSteps;
    };

    useEffect(() => {
        const stepsState = steps.map((step, index) =>
            Object.assign(
                {},
                {
                    description: step,
                    completed: false,
                    selected: index === 0 ? true : false,
                }
            )
        )

        stepRef.current = stepsState;
        const current = updateStep(currentStep, stepRef.current)
        setNewStep(current);

    }, [steps, currentStep]);

    const displaySteps = newStep.map((step, index) => {
        return <div key={index} className={index !== newStep.length - 1 ? 'w-full flex items-center' : 'flex'}>
            <div className='relative flex flex-col items-center text-teal-600 '>
                <div className={`rounded-full transition duration-500 ease-in-out border-2 border-gray-300 h-12 w-12 flex items-center justify-center py-3 
                 ${step.completed ? 'bg-[#529535] text-white font-bold border border-[#529535]' : ""} ${step.selected && !step.completed ? 'bg-[#008BBF] text-white border border-white' : ''} `}>
                    {step.completed ? (
                        <span className='bg-[#529535] text-white font-bold border border-[#529535] text-xl'>
                            &#10003;
                        </span>
                    ) : (index + 1)}
                </div>
                <div className={`absolute top-0 text-center mt-16 w-28 text-xs font-medium uppercase ${step.selected ? 'text-gray-900' : 'text-gray-400'}`}>
                    {step.description}
                </div>
            </div>
            <div className={`flex-auto border-t-2 transition duration-500 ease-in-out ${step.completed ? 'border-[#529535]' : 'border-gray-300'}`}></div>
        </div>
    });


    return (
        <div className='mx-4 p-4 flex justify-between items-center'>
            {displaySteps}
        </div>
    )
}

export default Stepper