export const REQUEST_OFFER_DETAILS = "REQUEST_OFFER_DETAILS";
export const RECEIVE_OFFER_DETAILS = "RECEIVE_OFFER_DETAILS";

export const REQUEST_ALL_OFFERS = "REQUEST_ALL_OFFERS";
export const RECEIVE_ALL_OFFERS = "RECEIVE_ALL_OFFERS";

export const REQUEST_OFFERS_BY_CATEGORY = "REQUEST_OFFERS_BY_CATEGORY";
export const RECEIVE_OFFERS_BY_CATEGORY = "RECEIVE_OFFERS_BY_CATEGORY";

export const REQUEST_SUBMISSION_OF_EVENT = "REQUEST_SUBMISSION_OF_EVENT";
export const RECEIVE_SUBMISSION_OF_EVENT = "RECEIVE_SUBMISSION_OF_EVENT";

export const REQUEST_USER_ORG = "REQUEST_USER_ORG";
export const RECEIVE_USER_ORG = "RECEIVE_USER_ORG";

export const REQUEST_PURCHASE_OF_TICKETS = "REQUEST_PURCHASE_OF_TICKETS";
export const RECEIVE_PURCHASE_OF_TICKETS = "RECEIVE_PURCHASE_OF_TICKETS";
export const RESET_PURCHASE_OF_TICKETS = "RESET_PURCHASE_OF_TICKETS";

export const REQUEST_MY_ORDERS = "REQUEST_MY_ORDERS";
export const RECEIVE_MY_ORDERS = "RECEIVE_MY_ORDERS";

export const REQUEST_ORDER_DETAILS = "REQUEST_ORDER_DETAILS";
export const RECEIVE_ORDER_DETAILS = "RECEIVE_ORDER_DETAILS";


// ============================================================

export const REQUEST_CREATE_ORGANIZATION = "REQUEST_CREATE_ORGANIZATION";
export const RECEIVE_CREATE_ORGANIZATION = "RECEIVE_CREATE_ORGANIZATION";
export const RESET_CREATE_ORGANIZATION = "RESET_CREATE_ORGANIZATION";


export const SET_SELECTED_ORGANIZER = "SET_SELECTED_ORGANIZER";
export const RECEIVE_SELECTED_ORGANIZER = "RECEIVE_SELECTED_ORGANIZER";

export const REQUEST_ORGANIZER_DETAILS = "REQUEST_ORGANIZER_DETAILS";
export const RECEIVE_ORGANIZER_DETAILS = "RECEIVE_ORGANIZER_DETAILS";

export const REQUEST_ORGANIZER_PAYMENT_SETUP = "REQUEST_ORGANIZER_PAYMENT_SETUP";
export const RECEIVE_ORGANIZER_PAYMENT_SETUP = "RECEIVE_ORGANIZER_PAYMENT_SETUP";

export const REQUEST_ORGANIZER_PAYMENT_DETAILS = "REQUEST_ORGANIZER_PAYMENT_DETAILS";
export const RECEIVE_ORGANIZER_PAYMENT_DETAILS = "RECEIVE_ORGANIZER_PAYMENT_DETAILS";

export const REQUEST_TICKET_VALIDATION = "REQUEST_TICKET_VALIDATION";
export const RECEIVE_TICKET_VALIDATION = "RECEIVE_TICKET_VALIDATION";

export const REQUEST_ORGANIZER_DASHBOARD_METRICS = "REQUEST_ORGANIZER_DASHBOARD_METRICS";
export const RECEIVE_ORGANIZER_DASHBOARD_METRICS = "RECEIVE_ORGANIZER_DASHBOARD_METRICS";
export const RESET_ORGANIZER_DASHBOARD_METRICS = "RESET_ORGANIZER_DASHBOARD_METRICS";

export const REQUEST_ORGANIZER_EVENTS = "REQUEST_ORGANIZER_EVENTS";
export const RECEIVE_ORGANIZER_EVENTS = "RECEIVE_ORGANIZER_EVENTS";

export const REQUEST_ALTERATION_OF_EVENT = "REQUEST_ALTERATION_OF_EVENT";
export const RECEIVE_ALTERATION_OF_EVENT = "RECEIVE_ALTERATION_OF_EVENT";
export const RESET_ALTERATION_OF_EVENT = "RESET_ALTERATION_OF_EVENT";

export const REQUEST_ORGANIZER_ORDERS = "REQUEST_ORGANIZER_ORDERS";
export const RECEIVE_ORGANIZER_ORDERS = "RECEIVE_ORGANIZER_ORDERS";

export const REQUEST_REFUND_BY_ORGANIZER = "REQUEST_REFUND_BY_ORGANIZER";
export const RECEIVE_REFUND_BY_ORGANIZER = "RECEIVE_REFUND_BY_ORGANIZER";
export const RESET_REFUND_BY_ORGANIZER = "RESET_REFUND_BY_ORGANIZER";

export const REQUEST_RESEND_TICKETS_BY_ORGANIZER = "REQUEST_RESEND_TICKETS_BY_ORGANIZER";
export const RECEIVE_RESEND_TICKETS_BY_ORGANIZER = "RECEIVE_RESEND_TICKETS_BY_ORGANIZER";
export const RESET_RESEND_TICKETS_BY_ORGANIZER = "RESET_RESEND_TICKETS_BY_ORGANIZER";

export const REQUEST_ORGANIZER_ADMIN_USERS = "REQUEST_ORGANIZER_ADMIN_USERS";
export const RECEIVE_ORGANIZER_ADMIN_USERS = "RECEIVE_ORGANIZER_ADMIN_USERS";


export const REQUEST_ORGANIZER_ADMIN_ROLES = "REQUEST_ORGANIZER_ADMIN_ROLES";
export const RECEIVE_ORGANIZER_ADMIN_ROLES = "RECEIVE_ORGANIZER_ADMIN_ROLES";

export const REQUEST_ORGANIZER_ADMIN_INVITATION = "REQUEST_ORGANIZER_ADMIN_INVITATION";
export const RECEIVE_ORGANIZER_ADMIN_INVITATION = "RECEIVE_ORGANIZER_ADMIN_INVITATION";

export const REQUEST_ORGANIZER_DUPLICATE_EVENT = "REQUEST_ORGANIZER_DUPLICATE_EVENT";
export const RECEIVE_ORGANIZER_DUPLICATE_EVENT = "RECEIVE_ORGANIZER_DUPLICATE_EVENT";
export const RESET_ORGANIZER_DUPLICATE_EVENT = "RESET_ORGANIZER_DUPLICATE_EVENT";