import React from 'react'
import backround from '../images/background_img1.svg';

const Hero = () => {
    return (
        <div className='mx-auto'>
            <div className='max-h-[600px] relative'>
                {/* overlay */}
                <div className='absolute w-full h-full text-gray-200 max-h-[600px] bg-black/40 flex flex-col justify-center'>
                    <h1 className='px-4 text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold'>We <span className=''>know</span></h1>
                    <h1 className='px-4 text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold'><span className=' text-yellow-500'>Entertainment</span></h1>
                </div>
                <img className='w-full md:max-h-[600px] md:h-[600px] h-[300px] object-cover' src='https://pbs.twimg.com/profile_banners/294511597/1675700242/1500x500' alt='persons in movie theater' />
                {/* <img className='w-full md:max-h-[600px] md:h-[600px] h-[300px] object-cover' src={backround} alt="" /> */}
            </div>
        </div>
    )
}

export default Hero