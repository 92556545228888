import React from 'react'
import { Card, Title, Text, AreaChart } from "@tremor/react";
import { getCurrencyFormat } from '../common/currencyUtils';



const RevenueAreaChart = ({ dataSet, currency }) => {


    const dataFormatter = (number) => {
        // return getCurrencyFormat(number, currency)
        return "$" + Intl.NumberFormat(currency).format(number).toString();
    };


    return (
        <div>
            <Card>
                <Title>Ticket revenue over time ({currency})</Title>
                <Text>Total revenue trend over the last 7 days</Text>
                <AreaChart
                    className="h-80 mt-4 -ml-10 w-[105%]"
                    data={dataSet}
                    index="Order date"
                    categories={["Accumulative sales"]}
                    colors={["sky", "cyan"]}
                    yAxisWidth={100}
                    // colors={["yellow", "green"]}
                    // rotateLabelX={{ angle: 20, xAxisHeight: 10, verticalShift: 70 }}
                    valueFormatter={dataFormatter}
                // tickGap={0}
                />
            </Card>
        </div>
    )
}

export default RevenueAreaChart