import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { MdOutlineAddCircleOutline } from "react-icons/md";
import defaultOrg from '../images/default_org.png'

const OrganizerSwitchDropdown = ({ items }) => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const selectedOrg = useSelector((state) => state.selectedOrganization.selectedOrg);
    const filteredObject = items.find(item => item.org_id == selectedOrg);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleClick = (orgId) => {
        toggleDropdown();
        navigate("/organizer/" + orgId + "/dashboard");
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        Object.keys(selectedOrg).length !== 0 ?
            <div className="flex flex-col-reverse text-left" ref={dropdownRef}>
                <div className="group relative border-t border-gray-200" data-headlessui-state="">
                    <button onClick={toggleDropdown} className="flex w-full items-center gap-3 rounded-md px-3 py-3 text-sm duration-200 border-none" type="button" aria-haspopup="true" aria-expanded="false" data-state="closed" id="headlessui-menu-button-:r9:" data-headlessui-state="">
                        <div className="flex items-center justify-center rounded">
                            <div className="relative flex">
                                {/* <img alt="User" loading="lazy" width="36" height="36" src={filteredObject?.img_url} /> */}
                                <img alt="User" loading="lazy" width="24" height="24" className={`rounded-full ${filteredObject?.img_url != null ? '' : 'grayscale'}`} src={filteredObject?.img_url != null ? filteredObject?.img_url : defaultOrg} />
                            </div>
                        </div>
                        <div className="grow overflow-hidden text-ellipsis whitespace-nowrap text-left text-slate-700">
                            <div className="font-bold">{filteredObject?.name}</div>
                            <div className="text-xs text-gray-500"></div>
                        </div>
                        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="h-4 w-4 flex-shrink-0 text-gray-500" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="12" cy="12" r="1"></circle>
                            <circle cx="19" cy="12" r="1"></circle>
                            <circle cx="5" cy="12" r="1"></circle>
                        </svg>
                    </button>
                </div>

                {isOpen && (
                    <div className="border border-gray-200 rounded-lg overflow-y-scroll w-full max-h-96">
                        <div className="flex px-1 min-h-[20px] py-1 items-center gap-3 text-black cursor-pointer text-sm bg-white rounded-md">
                            <span className="flex w-full flex-row flex-wrap-reverse justify-between">
                                <ul className="py-1 text-gray-700 w-full text-sm">
                                    {items.map((item, index) => (
                                        <li key={index}>
                                            <div
                                                onClick={() => handleClick(item.org_id)}
                                                className="inline-flex px-2 py-2 items-center justify-start w-full hover:text-lime-600"
                                            >
                                                <div className="flex items-center justify-center rounded">
                                                    <div className="relative flex h-6">
                                                        <img alt="User" loading="lazy" width="24" height="24" className={`rounded-full ${item.img_url != null ? '' : 'grayscale'}`} src={item.img_url != null ? item.img_url : defaultOrg} />
                                                    </div>
                                                </div>
                                                <div className='pl-4 font-medium'>
                                                    {item.name}
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                    <div className="border-t border-gray-200">
                                        <li>
                                            <Link
                                                to={`/create-organization`}
                                                className='flex px-4 py-2 text-sm text-gray-700 font-medium hover:text-lime-600'>
                                                <MdOutlineAddCircleOutline size={20} className='-ml-2 mr-4' />
                                                Create Organization
                                            </Link>
                                        </li>
                                    </div>
                                </ul>
                            </span>
                        </div>
                    </div>
                )}
            </div>
            : <></>
    );
};

export default OrganizerSwitchDropdown;
