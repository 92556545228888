import * as actionTypes from "../actions/actionTypes";

export const initialState = {
    orgEvents: [],
    loading: false,
    error: ""
}

export default function organizerEvents(state = initialState, action) {
    switch (action.type) {
        case actionTypes.REQUEST_ORGANIZER_EVENTS: {
            return {
                ...state,
                orgEvents: [],
                loading: true,
                error: ""
            };
        }
        case actionTypes.RECEIVE_ORGANIZER_EVENTS: {
            // console.log(action.payload);
            // console.log(action.payload.data);

            switch (action.payload.status) {
                case 'ERROR': {
                    // console.log(action.payload);
                    return {
                        ...state,
                        orgEvents: [],
                        loading: false,
                        error: action.payload.errorData
                    };
                }
                default: {
                    return {
                        ...state,
                        orgEvents: action.payload.data.data,
                        loading: false,
                        error: ""
                    }
                }
            }
        }
        default:
            return state;
    }
}
