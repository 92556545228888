import React, { useState, useEffect, useRef } from 'react';
import { TbHotelService, TbCar, TbMountain, TbTicket, TbBuilding, TbSettings } from 'react-icons/tb';
import { MdOutlineFlight, MdLocalDining } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

import logo from '../images/Lymeticket_2024_logo.svg';
import Login from './authentication/login';
import Logout from './authentication/logout';
import useAccessToken from '../hooks/useAccessToken';
import Banner from './Banner';

const Navbar = () => {
    const { user, isAuthenticated, isLoading } = useAuth0();
    const [nav, setNav] = useState(false);
    const [openNavDropdown, setOpenNavDropdown] = useState(false);
    const menuRef = useRef(null);
    const desktopMenuRef = useRef(null);

    useAccessToken();

    useEffect(() => {
        function handleClickOutside(event) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setNav(false);
            }

            if (desktopMenuRef.current && !desktopMenuRef.current.contains(event.target)) {
                setOpenNavDropdown(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const NavbarItem = ({ title, reactIcon, classProps }) => {
        return (
            <li className={`mx-4 cursor-pointer text-xs pt-6 flex items-center font-lato font-semibold tracking-wider leading-5 uppercase text-[#353535] opacity-90 ${classProps}`}>
                {getIconTag(reactIcon)}
                {title}
            </li>
        );
    }

    const getIconTag = (icon) => {
        switch (icon) {
            case "MdOutlineFlight":
                return <MdOutlineFlight size={20} className='mr-1' />
            case "TbHotelService":
                return <TbHotelService size={20} className='mr-1' />
            case "TbCar":
                return <TbCar size={20} className='mr-1' />
            case "MdLocalDining":
                return <MdLocalDining size={20} className='mr-1' />
            case "TbMountain":
                return <TbMountain size={20} className='mr-1' />
            case "TbTicket":
                return <TbTicket size={20} className='mr-1' />
            default:
                return;
        }
    }

    return (
        <>
            <div className='xl:w-full xl:px-10 lg:w-full w-full mx-auto flex justify-between items-center p-4 flex-wrap border-b shadow-md z-50 top-0 left-auto right-0 sticky bg-white'>
                <div className="flex-initial justify-center items-center order-1">
                    <div>
                        <Link to="/">
                            <img src={logo} alt="logo" className="w-36 cursor-pointer" to="/" />
                        </Link>
                    </div>
                </div>

                <div className='flex items-center py-2 order-2'>
                    {isAuthenticated ?
                        <>
                            <div className="flex flex-row items-center justify-end gap-2">
                                <button type="button" className="w-[2.375rem] h-[2.375rem] inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none">
                                    <svg className="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M6 8a6 6 0 0 1 12 0c0 7 3 9 3 9H3s3-2 3-9" /><path d="M10.3 21a1.94 1.94 0 0 0 3.4 0" /></svg>
                                </button>

                                <div className="relative inline-flex" ref={desktopMenuRef}>
                                    <button
                                        onClick={() => { setOpenNavDropdown(!openNavDropdown) }}
                                        id="hs-dropdown-with-header"
                                        type="button"
                                        className="w-[2.375rem] h-[2.375rem] inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none">
                                        <img src={user.picture} alt={user.name} className='flex rounded-full text-center mx-auto m-4 w-10 max-w-[35px]' />
                                    </button>

                                    <div className={`${openNavDropdown ? 'block' : 'hidden'} hs-dropdown-menu transition-[opacity,margin] absolute top-10 right-1 duration min-w-60 bg-white shadow-md rounded-lg p-2`}>
                                        <div className="py-3 px-5 -m-2 bg-gray-100 rounded-t-lg">
                                            <p className="text-sm text-gray-500 ">Signed in as</p>
                                            <p className="text-sm font-medium text-gray-800 ">{user.email}</p>
                                        </div>
                                        <div className="mt-2 py-2 first:pt-0 last:pb-0">
                                            <Link to="/orders" onClick={() => { setOpenNavDropdown(!openNavDropdown) }} className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-gray-700 text-sm hover:text-lime-600" role="menuitem" tabIndex="-1" id="menu-item-0">
                                                <svg className="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M6 2 3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4Z" /><path d="M3 6h18" /><path d="M16 10a4 4 0 0 1-8 0" /></svg>
                                                Orders
                                            </Link>
                                            <Link to="/organizer" onClick={() => { setOpenNavDropdown(!openNavDropdown) }} className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-gray-700 text-sm hover:text-lime-600" role="menuitem" tabIndex="-1" id="menu-item-0">
                                                <TbBuilding size={20} className='' />
                                                Organizations
                                            </Link>
                                            <Link to="settings" onClick={() => { setOpenNavDropdown(!openNavDropdown) }} className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-gray-700 text-sm hover:text-lime-600" role="menuitem" tabIndex="-1" id="menu-item-0">
                                                <TbSettings size={20} className='' />
                                                Settings
                                            </Link>
                                        </div>
                                        <div className="py-1 mx-1" role="none">
                                            <Logout />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        : (isLoading ? <></> :
                            <Login />
                        )
                    }
                </div>
            </div>
        </>
    )
}

export default Navbar;
