import * as actionTypes from "../actions/actionTypes";

export const initialState = {
    orderDetails: {},
    loading: false,
    error: ""
}


export default function purchaseTickets(state = initialState, action) {
    switch (action.type) {
        case actionTypes.REQUEST_PURCHASE_OF_TICKETS: {
            return {
                ...state,
                orderDetails: {},
                loading: true,
                error: ""
            };
        }
        case actionTypes.RESET_PURCHASE_OF_TICKETS: {
            return {
                ...state,
                orderDetails: {},
                loading: false,
                error: ""
            };
        }
        case actionTypes.RECEIVE_PURCHASE_OF_TICKETS: {
            switch (action.payload.status) {
                case 'ERROR': {
                    return {
                        ...state,
                        orderDetails: {},
                        loading: false,
                        error: action.payload.errorData.errorMessage
                    };
                }
                default: {
                    return {
                        ...state,
                        orderDetails: action.payload.data,
                        loading: false,
                        error: ""
                    }
                }
            }
        }
        default:
            return state;
    }
}
