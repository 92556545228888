import React, { useEffect, useState } from 'react';
import StepperControl from '../StepperControl';
import TimePicker from '../MUITimePicker';
import { validateRequiredText, validateOptionalText, validateNone, validateRequiredNumber, validateRequiredDate } from '../utils/validators';
import emptyState from '../../images/empty_state.png'
import { convertDateToHuman } from '../../components/common/dateUtils';
import { getCurrencyFormat } from '../../components/common/currencyUtils';


const TicketInfo = ({ formState, setFormState, currentStep, steps, increaseStep, editMode }) => {
    const [submit, setSubmit] = useState(false);
    const [ticketsInfo, setTicketsInfo] = useState(formState.ticketInfo);
    const [tickets, setTickets] = useState(formState.ticketInfo.map((ticket) => ({
        ticketId: { value: ticket.ticketId, error: null },
        title: { value: ticket.title, error: null },
        description: { value: '', error: null },
        quantity: { value: ticket.quantity, error: null },
        min: { value: ticket.min, error: null },
        max: { value: ticket.max, error: null },
        unitPrice: { value: ticket.unitPrice, error: null },
        currency: { value: ticket.currency, error: null },
        startDate: { value: new Date(ticket.startTime), error: null },
        endDate: { value: new Date(ticket.endTime), error: null },
        startTime: { value: new Date(ticket.startTime), error: null },
        endTime: { value: new Date(ticket.endTime), error: null },
        hasPrice: { value: ticket.hasPrice, error: null },
    })));
    const [showTicketForm, setShowTicketForm] = useState(false);
    const [isNewTicket, setIsNewTicket] = useState(false);
    const [openIndex, setOpenIndex] = useState(null);


    useEffect(() => {
        if (submit) {
            increaseStep("next");
        }
    }, [submit]);


    useEffect(() => {
        setFormState({ ...formState, ticketInfo: [...ticketsInfo] })
    }, [ticketsInfo]);

    const onUpdateField = e => {
        const name = e.target.name;
        const value = e.target.value;

        setTickets({ ...tickets, [openIndex]: { ...tickets[openIndex], [name]: { value: value, error: null } } });
    };

    const onUpdateDateTime = (name, time) => {
        if (name && (name === "startDate") || name && (name === "endDate")) {
            console.log("updated date is", time);
            setTickets({ ...tickets, [openIndex]: { ...tickets[openIndex], [name]: { value: new Date(time), error: null } } });
            // setTickets({ ...tickets, [name]: { value: new Date(time), error: null } });
        } else {
            console.log("updated time is", time);
            setTickets({ ...tickets, [openIndex]: { ...tickets[openIndex], [name]: { value: new Date(time), error: null } } });
            // setTickets({ ...tickets, [name]: { value: new Date(time), error: null } });
        }

    };

    // const handleBlur = e => {
    //     // const name = e.target.name;
    //     // const value = e.target.value;
    //     // if (!formState.venueInfo[name].value) return setFormState({ ...formState, venueInfo: { ...formState.venueInfo, [name]: { ...formState.venueInfo[name], value: value, error: "Required!" } } })
    // };

    const handleBlur = (e) => {
        const value = parseFloat(e.target.value);
        const name = e.target.name;

        if (!isNaN(value)) {
            const formattedValue = value.toFixed(2);

            setTickets({ ...tickets, [openIndex]: { ...tickets[openIndex], [name]: { value: formattedValue, error: null } } });
        }
    };

    const validateField = (field, value, index) => {
        const updatedTickets = { ...tickets[index] };
        switch (field) {
            case "title":
                return validateRequiredText(value);
            case "description":
                return validateOptionalText(value);
            case "quantity":
                return validateRequiredNumber(value, 0, 10000);
            case "min":
                return validateRequiredNumber(value, 1, 10);
            case "max":
                return validateRequiredNumber(value, 1, 10);
            case "startDate":
                return validateRequiredDate(value);
            case "startTime":
                return validateRequiredDate(value);
            case "endDate":
                return validateRequiredDate(value);
            case "endTime":
                return validateRequiredDate(value);
            case "unitPrice":
                // console.log(updatedTickets.hasPrice);
                if (updatedTickets.hasPrice.value === true) {
                    return validateRequiredNumber(value, 1, 99999);
                }
            case "currency":
                if (updatedTickets.hasPrice.value === true) {
                    return validateRequiredText(value);
                }

            default:
                return validateNone();
        }
    }

    const handleSubmit = (e) => {
        if (e == "prev") {
            increaseStep("prev");
        } else {
            setFormState({ ...formState, ticketInfo: ticketsInfo });
            setSubmit(true);
        }
    };

    const handleAddTicket = () => {
        if (ticketsInfo.length < 5) {
            const newIndex = ticketsInfo.length
            setTickets({
                ...tickets, [newIndex]: {
                    title: { value: '', error: null },
                    description: { value: '', error: null },
                    quantity: { value: '', error: null },
                    min: { value: '', error: null },
                    max: { value: '', error: null },
                    unitPrice: { value: '0.00', error: null },
                    currency: { value: '', error: null },
                    startDate: { value: new Date(), error: null },
                    endDate: { value: new Date(), error: null },
                    startTime: { value: new Date(), error: null },
                    endTime: { value: new Date(), error: null },
                    hasPrice: { value: false, error: null }
                }
            });
            setIsNewTicket(!isNewTicket)
            toggleEditTicketModal(newIndex)
        }
    };

    const handleSaveTicket = (index) => {
        let hasError = false;
        const updatedTickets = { ...tickets[index] };
        // console.log();
        for (const key in updatedTickets) {
            const value = updatedTickets[key].value;
            const error = validateField(key, value, index);
            if (error.msg !== null) {
                hasError = true;
                updatedTickets[key] = { value: value, error: error.msg };
            }
        }
        if (hasError) {
            setTickets({ ...tickets, [index]: { ...updatedTickets } });
            console.log(updatedTickets);
        } else {
            const updatedTickets = { ...tickets[index] };
            const flatObject = {};
            console.log(updatedTickets);
            for (const key in updatedTickets) {
                const value = updatedTickets[key].value;

                if (value instanceof Date) {
                    flatObject[key] = value.toISOString();
                } else {
                    flatObject[key] = value;
                }
            }

            console.log(flatObject);

            setTicketsInfo((prevTicketsInfo) => {
                const updatedTicketsInfo = [...prevTicketsInfo];
                updatedTicketsInfo[index] = flatObject;
                return updatedTicketsInfo;
            });

            // console.log(ticketsInfo);

            // setFormState({ ...formState, ticketInfo: [...ticketsInfo] })
            // setFormState((prevFormState) => ({
            //     ...prevFormState,
            //     ticketInfo: [...ticketsInfo],
            // }));

            toggleEditTicketModal(index)
            setIsNewTicket(false)
        }
    };

    function handleCheckboxChange(event) {

        const name = event.target.name;
        const value = event.target.checked;

        setTickets({ ...tickets, [openIndex]: { ...tickets[openIndex], [name]: { value: value, error: null } } });
    }

    const toggleEditTicketModal = (index) => {
        setShowTicketForm(!showTicketForm)
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const closeModal = (index) => {
        setIsNewTicket(false)
        toggleEditTicketModal(index)
    };

    const handleUnitPriceChange = (e) => {
        const name = e.target.name;
        const inputValue = e.target.value;

        // const value = parseFloat(inputValue);

        // if (!isNaN(value)) {
        //     const formattedValue = value.toFixed(2);

        //     setTickets({ ...tickets, [openIndex]: { ...tickets[openIndex], [name]: { value: formattedValue, error: null } } });

        // }
        setTickets({ ...tickets, [openIndex]: { ...tickets[openIndex], [name]: { value: inputValue, error: null } } });

    };

    function ticketForm(index) {
        return (
            <div className="mt-5 md:col-span-2 md:mt-0">
                {/* <form action="#" method="POST"> */}
                <div className="overflow-hidden ">
                    <div className="bg-white px-4 py-5 sm:p-6">
                        <div className="grid grid-cols-6 gap-6">


                            <div className="col-span-6 sm:col-span-4 lg:col-span-6">
                                <label htmlFor="venue" className="block text-sm font-medium leading-6 text-gray-900">
                                    Ticket Name
                                </label>
                                <input
                                    type="text"
                                    name="title"
                                    id="title"
                                    disabled={editMode && !isNewTicket}
                                    className={`px-2 mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${tickets[index].title.error !== null ? 'border border-red-700' : ''}`}
                                    onChange={onUpdateField}
                                    onBlur={(e) => handleBlur(e)}
                                    value={tickets[index].title.value}
                                />
                                <span className="text-xs text-red-700">{tickets[index].title.error}</span>
                            </div>

                            <div className="col-span-6 sm:col-span-5 lg:col-span-6">
                                <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                                    Description
                                </label>
                                <div className="mt-2">
                                    <textarea
                                        id="description"
                                        name="description"
                                        rows={3}
                                        disabled={editMode && !isNewTicket}
                                        className={` px-2 mt-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:py-1.5 sm:text-sm sm:leading-6 ${tickets[index].description.error ? 'border border-red-700' : ''}`}
                                        placeholder="This ticket gurantees you entry into the event"
                                        onChange={onUpdateField}
                                        onBlur={(e) => handleBlur(e)}
                                        value={tickets[index].description.value}
                                    />
                                    <span className="text-xs text-red-700">{tickets[index].description.error}</span>
                                </div>
                                <p className="mt-1 text-sm text-gray-500">
                                    Brief description for this ticket and what it provides.
                                </p>
                            </div>

                            {/* quantity */}
                            <div className="col-span-6 sm:col-span-6 lg:col-span-3">
                                <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">
                                    Total Quantity
                                </label>
                                <input
                                    type="number"
                                    name="quantity"
                                    id="quantity"
                                    min={1}
                                    max={100000}
                                    className={`px-2 mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${tickets[index].quantity.error !== null ? 'border border-red-700' : ''}`}
                                    onChange={onUpdateField}
                                    onBlur={(e) => handleBlur(e)}
                                    value={tickets[index].quantity.value}
                                />
                                <span className="text-xs text-red-700">{tickets[index].quantity.error}</span>
                            </div>

                            <div className="col-span-3">
                                <label htmlFor="addressLine2" className="block text-sm font-medium leading-6 text-gray-900">
                                    Tickets per order
                                </label>
                                <div className='flex'>
                                    <div>
                                        <input
                                            type="number"
                                            name="min"
                                            id="min"
                                            className={`px-2 mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${tickets[index].min.error !== null ? 'border border-red-700' : ''}`}
                                            onChange={onUpdateField}
                                            onBlur={(e) => handleBlur(e)}
                                            value={tickets[index].min.value}
                                        />
                                        <span className="text-xs text-red-700">{tickets[index].min.error}</span>
                                    </div>

                                    <div className='mx-6 self-center'> - </div>

                                    <div>
                                        <input
                                            type="text"
                                            name="max"
                                            id="max"
                                            className={`px-2 mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${tickets[index].max.error !== null ? 'border border-red-700' : ''}`}
                                            onChange={onUpdateField}
                                            onBlur={(e) => handleBlur(e)}
                                            value={tickets[index].max.value}
                                        />
                                        <span className="text-xs text-red-700">{tickets[index].max.error}</span>
                                    </div>
                                </div>
                            </div>

                            {/* times */}
                            <div className="col-span-6 sm:col-span-5 lg:col-span-3">
                                <label htmlFor="facebook" className="block text-sm font-medium leading-6 text-gray-900">
                                    Sale Start Time
                                </label>
                                <div className='flex flex-row w-auto'>
                                    <TimePicker
                                        name="start"
                                        onChange={onUpdateDateTime}
                                        // onBlur={(e) => handleBlur(e)}
                                        form={tickets[index]}
                                        editMode={editMode && !isNewTicket}
                                    />
                                </div>
                            </div>

                            <div className="col-span-6 sm:col-span-5 lg:col-span-3">
                                <label htmlFor="facebook" className="block text-sm font-medium leading-6 text-gray-900">
                                    Sale End Time
                                </label>
                                <div className='flex flex-row w-auto'>
                                    <TimePicker
                                        name="end"
                                        onChange={onUpdateDateTime}
                                        // onBlur={(e) => handleBlur(e)}
                                        form={tickets[index]}
                                    />
                                </div>
                            </div>


                            {/* free event */}
                            <div class="col-span-6 mb-[0.125rem] block min-h-[1.5rem]">
                                <label className="inline-flex items-center">
                                    <input
                                        type="checkbox"
                                        name="hasPrice"
                                        className="form-checkbox h-5 w-5 text-blue-600"
                                        disabled={editMode && !isNewTicket}
                                        checked={tickets[index].hasPrice.value}
                                        onChange={handleCheckboxChange}
                                        value={tickets[index].hasPrice.value}
                                    />
                                    <span className="ml-2 text-gray-700">Set ticket price.</span>
                                </label>
                            </div>

                            {/* price */}
                            {
                                tickets[index].hasPrice.value &&
                                <>
                                    <div className="col-span-6 sm:col-span-6 lg:col-span-3">
                                        <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
                                            Unit Price
                                        </label>
                                        <input
                                            type="number"
                                            name="unitPrice"
                                            id="unitPrice"
                                            disabled={editMode && !isNewTicket}
                                            className={`px-2 mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${tickets[index].unitPrice.error !== null ? 'border border-red-700' : ''}`}
                                            onChange={handleUnitPriceChange}
                                            onBlur={(e) => handleBlur(e)}
                                            value={tickets[index].unitPrice.value}
                                        />
                                        <span className="text-xs text-red-700">{tickets[index].unitPrice.error}</span>
                                    </div>

                                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                        <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                                            Currency
                                        </label>
                                        <select
                                            id="currency"
                                            name="currency"
                                            disabled={editMode && !isNewTicket}
                                            className={`px-2 mt-2 block w-full rounded-md border-0 bg-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${tickets[index].currency.error !== null ? 'border border-red-700' : ''}`}
                                            onChange={onUpdateField}
                                            onBlur={(e) => handleBlur(e)}
                                            value={tickets[index].currency.value}
                                        >
                                            <option></option>
                                            <option>USD</option>
                                            <option>JMD</option>
                                        </select>
                                        <span className="text-xs text-red-700">{tickets[index].currency.error}</span>
                                    </div>
                                </>
                            }


                        </div>
                    </div>

                    <div className='flex flex-col items-center my-6 mx-auto'>
                        <button
                            onClick={(e) => handleSaveTicket(index)}
                            className='bg-[#529535] text-white uppercase py-2 px-4 rounded-xl font-semibold cursor-pointer border-[#529535]
                                    hover:bg-[#529535] hover:text-white hover:opacity-80 transition duration-200 ease-in-out h-auto w-fit'>
                            Save and Continue
                        </button>
                    </div>


                </div>
            </div>
        );
    }

    function addEditTicketModal(index) {
        return (
            <div className="fixed inset-0 z-50 backdrop-blur-sm bg-gray-500 bg-opacity-75">
                <div className="flex items-center justify-center min-h-screen px-4">

                    {/* Modal content */}
                    <div className="flex-col align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl w-full">
                        <div className='flex flex-auto overflow-hidden'>
                            <button
                                onClick={() => closeModal(index)}
                                type="button"
                                className="absolute border-none left-[94%] top-[2%] h-fit text-gray-400 bg-slate-100 hover:bg-gray-200 hover:text-gray-900 rounded-full text-sm p-1.5 z-30"
                                data-modal-hide="extralarge-modal">
                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                <span className="sr-only">Close modal</span>
                            </button>

                            {/* Left pane */}
                            <div className='flex-col w-full'>
                                {/* Main Content */}
                                <div className='inline-flex w-full'>
                                    <div className='w-full min-h-[512px] px-8 overflow-y-auto pt-1'>
                                        {ticketForm(index)}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        )
    }

    return (
        <div
            className={`flex  h-full mt-16 mx-auto justify-center w-full ${!editMode ? 'min-h-screen' : ''}`}
        >
            {
                !editMode &&
                <div className='pb-8 mt-28 mx-12'>
                    <div className='text-[#003863] font-bold text-3xl'>Create Tickets</div>
                    <span className='text-slate-500'>Add the different categories of tickets you would like to provide for this event.</span>
                </div>
            }


            {
                (ticketsInfo.length > 0) ?
                    <>
                        <div
                            // className="flex flex-col h-fit mx-auto mt-12 justify-center w-full"
                            className={`flex flex-col sm:mt-0 p-4 ${!editMode ? '' : 'w-[832px] max-w-[832px] '}`}
                        >
                            <div
                            // className='flex flex-col '
                            // className={`flex flex-col mt-10 sm:mt-0 p-8 ${!editMode ? '' : 'w-full justify-center'}`}

                            >
                                {ticketsInfo.map((ticket, index) => (

                                    <div
                                        // className="flex mt-5 ring-blue-500 ring-2 rounded-md w-full" 
                                        key={index}
                                        className={`flex flex-col mt-5 hover:ring-blue-400 hover:cursor-pointer hover:ring-2 rounded-md ${!editMode ? 'w-full' : 'w-full justify-center'}`}
                                    >
                                        {/* {ticket} */}
                                        <div
                                            className="bg-white shadow-md rounded-md p-6 w-full grid grid-cols-6"
                                            onClick={() => toggleEditTicketModal(index)}
                                        >

                                            <div className='flex flex-col col-span-5'>
                                                <h2 className="text-lg font-medium mb-2 text-slate-800">{ticket.title}</h2>
                                                <span className=''>{ticket.description}</span>
                                                <span className='mt-2 text-sm'>{convertDateToHuman(ticket.startDate)}  -  {convertDateToHuman(ticket.endDate)}</span>


                                            </div>

                                            <div className='col-span-1 flex items-center self-center justify-end'>
                                                {parseFloat(ticket.unitPrice) > parseFloat(0.00) ?
                                                    <p className="text-slate-600 mb-4">{getCurrencyFormat(ticket.unitPrice, ticket.currency)}</p> :
                                                    <p className="text-slate-600 mb-4">Free</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                {

                                    <div className='flex flex-col items-center mt-6 mx-auto'>
                                        <button className=" ring-gray-900/10 hover:ring-gray-900/20 hover:bg-gray-100 text-gray-500 ring-1 rounded-full h-16 w-16 ml-4 border-gray-900/10"
                                            onClick={handleAddTicket}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-6 h-6">
                                                <path fill-rule="evenodd" d="M11 9V5a1 1 0 0 0-2 0v4H5a1 1 0 0 0 0 2h4v4a1 1 0 0 0 2 0v-4h4a1 1 0 0 0 0-2h-4z" clip-rule="evenodd" />
                                            </svg>
                                        </button>
                                    </div>
                                }

                            </div>
                        </div>

                        {showTicketForm && addEditTicketModal(openIndex)}


                        {
                            !editMode &&
                            <StepperControl
                                handleClick={handleSubmit}
                                currentStep={currentStep}
                                steps={steps}
                            />
                        }
                    </>

                    :

                    <>

                        <div class="flex h-[40rem]">
                            <div class="m-auto">
                                <div className='flex flex-col items-center text-slate-500'>
                                    <img src={emptyState} alt="" width={250} height={250} />
                                    <span>You dont have any tickets, please create one in order to continue</span>
                                    <span>
                                        <div className='mt-4'>
                                            <button
                                                onClick={handleAddTicket}
                                                className='bg-[#529535] text-white uppercase py-2 px-4 rounded-xl font-semibold cursor-pointer border-[#529535]
                    hover:bg-[#529535] hover:text-white hover:opacity-80 transition duration-200 ease-in-out h-auto w-fit'>
                                                Add Ticket
                                            </button>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>


                        {showTicketForm && addEditTicketModal(openIndex)}
                    </>


            }
        </div>
    )

}

export default TicketInfo