// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import Table5 from '../components/Table5';
// import Loader from '../components/Loader';
// import { useAuth0 } from '@auth0/auth0-react';
// import { requestOrganizerOrders } from '../store/actions/index';
// import RefundModal from '../components/RefundModal';

// export const OrgOrders = () => {
//     const { getAccessTokenSilently } = useAuth0();
//     const [loading, setLoading] = useState(true);
//     const [showRefundModal, setShowRefundModal] = useState(false);
//     const [orderSelected, setOrderSelected] = useState({});
//     const dispatch = useDispatch();

//     const organizationDetails = useSelector((state) => state.organizerDetails.orgDetails);
//     const organizationOrders = useSelector((state) => state.organizerOrders.orgOrders);
//     const organizationOrdersLoading = useSelector((state) => state.organizerOrders.loading);

//     useEffect(() => {
//         const loadOrgOrders = async () => {
//             if (loading && organizationDetails && organizationDetails.org_id) {
//                 const accessToken = await getAccessTokenSilently();
//                 dispatch(requestOrganizerOrders(organizationDetails.org_id, accessToken));
//                 setLoading(false);
//             }
//         };

//         loadOrgOrders();
//     }, [loading, organizationDetails, dispatch, getAccessTokenSilently]);

//     useEffect(() => {
//         if (Object.keys(orderSelected).length !== 0) {
//             document.body.classList.add('body-no-scroll');
//             setShowRefundModal(true);
//         }
//     }, [orderSelected]);

//     const closeAndResetSelectedOrder = () => {
//         document.body.classList.remove('body-no-scroll');
//         setShowRefundModal(false);
//         setLoading(true);
//         setOrderSelected({});
//     };

//     if (!organizationDetails) {
//         return <Loader />;
//     }

//     return (
//         <div className="p-4 lg:ml-64 min-h-screen">
//             <div className="flex flex-col items-start justify-between pb-6 space-y-4 border-b lg:items-center lg:space-y-0 lg:flex-row m-4 mt-8">
//                 <h1 className="text-2xl font-semibold whitespace-nowrap">Orders</h1>
//                 {/* <div>
//                     in order details sectipn, you can review and manage all orders with their details, you can view and edit many information such as tickets for all orders,
//                     orderd productsm order date, price and status, Access to this area is limited. Only administrators can access this panel.
//                     All changes made happen in real time after actions are selected.
//                 </div> */}
//             </div>

//             {!loading && !organizationOrdersLoading ? (
//                 <>
//                     {organizationOrders.length > 0 ? (
//                         <div className="flex justify-center w-full rounded-lg bg-white transition-all p-4 sm:p-10 ring-1 ring-tremor-ring">
//                             <Table5
//                                 rows={organizationOrders ? organizationOrders : []}
//                                 orderSelected={setOrderSelected}
//                             />
//                         </div>
//                     ) : (
//                         <div className="flex h-[45rem]">
//                             <div className="m-auto">
//                                 <div className="flex flex-col items-center text-slate-500">
//                                     <span className="font-semibold text-2xl mb-4">No orders found</span>
//                                     <span>You don't have any orders, once they are placed, you will see them here</span>
//                                 </div>
//                             </div>
//                         </div>
//                     )}
//                 </>
//             ) : (
//                 <Loader />
//             )}

//             {showRefundModal && (
//                 <RefundModal
//                     orderDetails={orderSelected}
//                     onClose={() => closeAndResetSelectedOrder()}
//                 />
//             )}
//         </div>
//     );
// };






import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table5 from '../components/Table5';
import Loader from '../components/Loader';
import { useAuth0 } from '@auth0/auth0-react';
import { requestOrganizerOrders } from '../store/actions/index';
import RefundModal from '../components/RefundModal';

export const OrgOrders = () => {
    const { getAccessTokenSilently } = useAuth0();
    const [loading, setLoading] = useState(true);
    const [showRefundModal, setShowRefundModal] = useState(false);
    const [orderSelected, setOrderSelected] = useState({});
    // const [selectedStatus, setSelectedStatus] = useState(''); // For status filtering
    const dispatch = useDispatch();

    const organizationDetails = useSelector((state) => state.organizerDetails.orgDetails);
    const organizationOrders = useSelector((state) => state.organizerOrders.orgOrders);
    const organizationOrdersLoading = useSelector((state) => state.organizerOrders.loading);

    useEffect(() => {
        const loadOrgOrders = async () => {
            if (loading && organizationDetails && organizationDetails.org_id) {
                const accessToken = await getAccessTokenSilently();
                dispatch(requestOrganizerOrders(organizationDetails.org_id, accessToken));
                setLoading(false);
            }
        };

        loadOrgOrders();
    }, [loading, organizationDetails, dispatch, getAccessTokenSilently]);

    useEffect(() => {
        if (Object.keys(orderSelected).length !== 0) {
            document.body.classList.add('body-no-scroll');
            setShowRefundModal(true);
        }
    }, [orderSelected]);

    const closeAndResetSelectedOrder = () => {
        document.body.classList.remove('body-no-scroll');
        setShowRefundModal(false);
        setLoading(true);
        setOrderSelected({});
    };

    // Filter orders by status
    // const filteredOrders = organizationOrders.filter(order => {
    //     if (selectedStatus === '') return true; // No filter applied, show all
    //     return order.order_status.toLowerCase() === selectedStatus.toLowerCase();
    // });

    return (
        <div className="p-4 lg:ml-64 min-h-screen">
            <div className="flex flex-col items-start justify-between pb-6 space-y-4 border-b lg:items-center lg:space-y-0 lg:flex-row m-4 mt-8">
                <h1 className="text-2xl font-semibold whitespace-nowrap">Orders</h1>
            </div>

            {!loading && !organizationOrdersLoading ? (
                <>
                    {organizationOrders.length > 0 ? (
                        <div className="flex justify-center w-full rounded-lg bg-white transition-all p-4 sm:p-10 ring-1 ring-tremor-ring">
                            {/* <div className="flex items-center space-x-4">
                                <select
                                    value={selectedStatus}
                                    onChange={(e) => setSelectedStatus(e.target.value)}
                                    className="border border-gray-300 rounded-md px-2 py-1"
                                >
                                    <option value="">All Orders</option>
                                    <option value="refunded">Refunded</option>
                                    <option value="completed">Completed</option>
                                    <option value="open dispute">Open Dispute</option>
                                </select>
                            </div> */}
                            {/* Table5 with filtering */}
                            <Table5
                                rows={organizationOrders ? organizationOrders : []}
                                orderSelected={setOrderSelected}
                            />
                        </div>
                    ) : (
                        <div className="flex h-[45rem]">
                            <div className="m-auto">
                                <div className="flex flex-col items-center text-slate-500">
                                    <span className="font-semibold text-2xl mb-4">No orders found</span>
                                    <span>You don't have any orders, once they are placed, you will see them here</span>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <Loader />
            )}

            {showRefundModal && (
                <RefundModal
                    orderDetails={orderSelected}
                    onClose={() => closeAndResetSelectedOrder()}
                />
            )}
        </div>
    );
};
