import React, { useState } from 'react';

const FAQPage = () => {
    // State to manage accordion toggles
    const [activeIndex, setActiveIndex] = useState(null);

    // Function to handle accordion toggle
    const toggleAccordion = (index) => {
        if (activeIndex === index) {
            // If clicked item is already active, close it
            setActiveIndex(null);
        } else {
            // Otherwise, open clicked item
            setActiveIndex(index);
        }
    };

    // FAQ data
    const faqs = [
        {
            question: 'How can I contact the event organizer about the event or my tickets?',
            answer: 'You should find a contact link at the bottom of the ticket booking form, as well as in the footer of the ticket email you received. You may also find contact details for them in the same place you found the original link to buy tickets for their event (e.g. their website, Facebook page, or Twitter account).'
        },
        {
            question: 'Can I get a refund for my tickets?',
            answer: 'Lymetickets is a platform that allows event organizers to sell tickets directly to their audience and set their own ticket terms and conditions. Refunds are at the discretion of the event organizer, in accordance with their refund policy.'
        },
        {
            question: 'I didn\'t receive my tickets.',
            // answer: 'If you didnt receive an automatic email confirmation. \n 1. verify the email provided \n2. Check spam folder or other email organization rules \nIf tickets still cannot be located, contact us at support@lymetickets.com'
            answer: (
                <div>
                    <p>If you didn't receive an automatic email confirmation:</p>

                    <ol className='ml-4'>
                        <li>1. Verify the email provided</li>
                        <li>2. Check your spam folder or other email organization rules</li>
                        <li>3. If tickets still cannot be located, contact us at <a href="mailto:support@lymetickets.com">support@lymetickets.com</a></li>
                    </ol>
                </div>
            )
        },
        // {
        //     question: 'How do I get access to a theme I purchased?',
        //     answer: 'If you lose the link for a theme you purchased, don\'t panic! We\'ve got you covered. You can login to your account, tap your avatar in the upper right corner, and tap Purchases. If you didn\'t create a login or can\'t remember the information, you can use our handy Redownload page, just remember to use the same email you originally made your purchases with.'
        // },
        // {
        //     question: 'Upgrade License Type',
        //     answer: 'There may be times when you need to upgrade your license from the original type you purchased and we have a solution that ensures you can apply your original purchase cost to the new license purchase.'
        // },
        {
            question: 'What type of events can be hosted on Lymetickets?',
            answer: 'You can host both free and paid events on Lymetickets.com. Subsequently these can be in-person and/or online virtual events. We have no monthly charges or fees associated with free events, however we do charge a small processing fee for paid tickets.'
        },
        {
            question: 'What are the fee structures on Lymetickets?',
            answer: 'You can host both free and paid events on Lymetickets.com. Subsequently these can be in-person and/or online virtual events. We have no monthly charge or fees associated with free events, however we do charge a small processing fee for paid tickets.'
        },
        {
            question: 'Does Lymetickets offer door management?',
            answer: 'Yes, we do offer door management service for conferences and major corporate event in certain geographical locations. Schedule a consult with us as support@lymetickets.com. Seperate charges and fees applies.'
        },
    ];

    return (
        <div className="max-w-3xl mx-auto py-12 px-4">
            <h1 className="text-3xl font-bold mb-8">Frequently Asked Questions</h1>

            <div className="text-xl font-bold mb-8">Lymetickets is an online platform that facilites organizers reaching their target audience and selling tickets</div>

            <div className="text-md font-medium mb-24">If you have any questions about an event, a ticket purchase, refunds, data privacy or terms and conditions please contact the event organizer directly.</div>
            {/* Accordion */}
            <div className="divide-y divide-gray-200">
                {faqs.map((faq, index) => (
                    <div key={index}>
                        {/* Question */}
                        <div
                            className="flex items-center justify-between py-4 cursor-pointer"
                            onClick={() => toggleAccordion(index)}
                        >
                            <h2 className="text-lg font-medium">{faq.question}</h2>
                            {/* Toggle icon */}
                            <svg
                                className={`h-6 w-6 transition-transform transform ${activeIndex === index ? 'rotate-180' : ''
                                    }`}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <polyline points="6 9 12 15 18 9" />
                            </svg>
                        </div>
                        {/* Answer */}
                        <div
                            className={`py-2 ${activeIndex === index ? 'block' : 'hidden'
                                }`}
                        >
                            <p className="text-gray-600">{faq.answer}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FAQPage;
