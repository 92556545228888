import React from 'react'

import carlos_ateam from '../images/carlos_ateam.jpeg';
import { TbBuildingCommunity } from "react-icons/tb";
import { BiSupport } from "react-icons/bi";
import { VscExtensions } from "react-icons/vsc";




const AboutUsPage = () => {
    return (
        // < !--Main Content-- >
        <div class="max-w-5xl mx-auto bg-white p-6">
            {/* <div class="bg-white "> */}

            <div class="bg-white py-24 sm:py-32">
                <div class="mx-auto max-w-7xl px-6 lg:px-8">
                    <div class="mx-auto max-w-2xl lg:text-center">
                        <h1 class="text-5xl text-gray-800 font-bold mb-6">About LymeTickets</h1>
                        <h2 class="text-base font-semibold leading-7 text-[#0453a3]">Event management made easy</h2>
                        {/* <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Everything you need to sell tickets online</p> */}
                        <p class="mt-6 text-lg leading-8 text-gray-600 ">Lymetickets is a pioneering online ticketing platform committed to enhancing the local event experience for communities worldwide. Our mission is rooted in the belief that every event, no matter how small or large, deserves exceptional support and seamless management. We provide a cutting-edge technological framework that empowers millions of organizers globally, enabling them to orchestrate unforgettable events with ease and precision.</p>
                    </div>

                    <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
                        <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                            <div class="relative pl-16">
                                <dt class="text-base font-semibold leading-7 text-gray-900">
                                    <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-[#82C342]">
                                        {/* <svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z" />
                                        </svg> */}
                                        <TbBuildingCommunity size={24} color='white' />
                                    </div>
                                    Hyper-local Engagement
                                </dt>
                                <dd class="mt-2 text-base leading-7 text-gray-600">We're committed to hyper-local engagement, understanding the unique pulse of each community we serve, ensuring tailored support and services for every event.</dd>
                            </div>
                            <div class="relative pl-16">
                                <dt class="text-base font-semibold leading-7 text-gray-900">
                                    <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-[#82C342]">
                                        {/* <svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                                        </svg> */}
                                        <BiSupport size={24} color='white' />
                                    </div>
                                    Award-Winning Support
                                </dt>
                                <dd class="mt-2 text-base leading-7 text-gray-600">Experience unparalleled support with our award-winning customer service team, available around the clock to ensure every organizer feels confident and empowered.</dd>
                            </div>
                            <div class="relative pl-16">
                                <dt class="text-base font-semibold leading-7 text-gray-900">
                                    <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-[#82C342]">
                                        {/* <svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                        </svg> */}
                                        <VscExtensions size={24} color='white' />
                                    </div>
                                    Localized Content
                                </dt>
                                <dd class="mt-2 text-base leading-7 text-gray-600">Our platform seamlessly integrates local flavor into every interaction, enhancing engagement and fostering a sense of community pride.</dd>
                            </div>
                            <div class="relative pl-16">
                                <dt class="text-base font-semibold leading-7 text-gray-900">
                                    <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-[#82C342]">
                                        <svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M7.864 4.243A7.5 7.5 0 0119.5 10.5c0 2.92-.556 5.709-1.568 8.268M5.742 6.364A7.465 7.465 0 004.5 10.5a7.464 7.464 0 01-1.15 3.993m1.989 3.559A11.209 11.209 0 008.25 10.5a3.75 3.75 0 117.5 0c0 .527-.021 1.049-.064 1.565M12 10.5a14.94 14.94 0 01-3.6 9.75m6.633-4.596a18.666 18.666 0 01-2.485 5.33" />
                                        </svg>
                                    </div>
                                    Cutting-Edge Technology
                                </dt>
                                <dd class="mt-2 text-base leading-7 text-gray-600">Utilize our state-of-the-art technological framework to orchestrate unforgettable events with ease and precision.</dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>

            <section class="relative isolate overflow-hidden bg-white px-1 py-24 sm:py-24 sm:px-6 lg:px-8">
                <div class="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.blue.100),white)] opacity-20"></div>
                <div class="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-white shadow-2xl shadow-blue-600/10 ring-1 ring-blue-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center"></div>
                <div class="mx-auto max-w-2xl lg:max-w-4xl">
                    <img class="mx-auto h-24 bg-blend-overlay" src="https://gatekeepers-demo.s3.amazonaws.com/images/84dbe0b9a9764e5495554a5132d291ef.jpeg" alt="" />
                    <figure class="mt-10">
                        <blockquote class="text-center text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
                            <p>“Great to see new players in the game, one in which transparency and customer experience is at the heart of the platform.”</p>
                        </blockquote>
                        <figcaption class="mt-10">
                            <img class="mx-auto h-10 w-10 rounded-full" src={carlos_ateam} alt="" />
                            <div class="mt-4 flex items-center justify-center space-x-3 text-base">
                                <div class="font-semibold text-gray-900">Carl Simpson</div>
                                <svg viewBox="0 0 2 2" width="3" height="3" aria-hidden="true" class="fill-gray-900">
                                    <circle cx="1" cy="1" r="1" />
                                </svg>
                                <div class="text-gray-600">CEO of A-Team Media</div>
                            </div>
                        </figcaption>
                    </figure>
                </div>
            </section>
        </div>
    )
}

export default AboutUsPage