import React from 'react'
import { Link } from 'react-router-dom'

const OrderSummary = ({ orderId }) => {
    return (
        <>
            <div className='vh-100 mt-16 mx-8'>
                <div className='flex flex-col w-[480px] left-0 top-0 m-auto'>
                    <div className='mx-auto mt-24 mb-8 rounded-full transition duration-500 ease-in-out h-16 w-16 flex items-center justify-center py-3 bg-[#529535] text-white font-bold border border-[#529535]'>
                        <span className='bg-[#529535] text-white font-bold border border-[#529535] text-3xl'>
                            &#10003;
                        </span>
                    </div>
                    <div>
                        <p className='font-bold text-[#003f6b] text-center'>
                            You have successfully reserved your tickets.
                        </p>
                        <p className='font-semibold text-[#474b55] text-center'>
                            A confirmation email will be sent out shortly <br />
                            {/* If you dont receive the tickets you can always see them in the orders section */}
                            <Link to={`/orders/${orderId}`}><span className='text-[#0062AB] text-sm'>#{orderId}</span></Link>
                        </p>
                    </div>
                    <div className='h-[165px] self-end content-end'>
                        <p className='font-semibold text-xs text-[#757575] opacity-85 text-center'>
                            If you discover a mistake in your order, send us an email: support@lymetickets.com
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OrderSummary