import * as actionTypes from "../actions/actionTypes";

export const initialState = {
    orgRoles: [],
    loading: true,
    error: ""
}


export default function organizerAdminRoles(state = initialState, action) {
    switch (action.type) {
        case actionTypes.REQUEST_ORGANIZER_ADMIN_ROLES: {
            return {
                ...state,
                orgRoles: [],
                loading: true,
                error: ""
            };
        }
        case actionTypes.RECEIVE_ORGANIZER_ADMIN_ROLES: {
            switch (action.payload.status) {
                case 'ERROR': {
                    return {
                        ...state,
                        orgRoles: [],
                        loading: false,
                        error: action.payload
                    };
                }
                default: {
                    return {
                        ...state,
                        orgRoles: action.payload.data,
                        loading: false,
                        error: ""
                    }
                }
            }
        }
        default:
            return state;
    }
}
