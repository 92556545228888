import * as actionTypes from "../actions/actionTypes";

export const initialState = {
    selectedOrg: null,
    loading: true,
    error: ""
}


export default function selectedOrganization(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SET_SELECTED_ORGANIZER: {
            return {
                ...state,
                selectedOrg: null,
                loading: true,
                error: ""
            };
        }
        case actionTypes.RECEIVE_SELECTED_ORGANIZER: {
            switch (action.payload.status) {
                case 'ERROR': {
                    return {
                        ...state,
                        selectedOrg: null,
                        loading: false,
                        error: action.payload
                    };
                }
                default: {
                    return {
                        ...state,
                        selectedOrg: action.payload.data,
                        loading: false,
                        error: ""
                    }
                }
            }
        }
        default:
            return state;
    }
}
