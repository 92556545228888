import * as actionTypes from "../actions/actionTypes";

export const initialState = {
    orgOrders: [],
    loading: false,
    error: ""
}

export default function organizerOrders(state = initialState, action) {
    switch (action.type) {
        case actionTypes.REQUEST_ORGANIZER_ORDERS: {
            return {
                ...state,
                orgOrders: [],
                loading: true,
                error: ""
            };
        }
        case actionTypes.RECEIVE_ORGANIZER_ORDERS: {

            switch (action.payload.status) {
                case 'ERROR': {
                    return {
                        ...state,
                        orgOrders: [],
                        loading: false,
                        error: action.payload.errorData
                    };
                }
                default: {
                    return {
                        ...state,
                        orgOrders: action.payload.data.data,
                        loading: false,
                        error: ""
                    }
                }
            }
        }
        default:
            return state;
    }
}
