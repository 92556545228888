import React, { useState, useRef, useMemo } from 'react';
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import {
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
    getFilteredRowModel,
    getPaginationRowModel,
} from '@tanstack/react-table';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeaderCell,
    TableRow,
} from '@tremor/react';
import UserAvatar from './UserAvatar';
import { convertToHumanDate, convertUTCToHumanTime } from './common/dateUtils';
import { classNames } from './shared/Utils';
import { StatusPill } from './common/StatusPillUtils';

// Main Table component
export default function Table5({ rows, orderSelected }) {
    const [sorting, setSorting] = useState([]);
    const [filtering, setFiltering] = useState('');
    const [showActionMenus, setShowActionMenus] = useState(Array(rows.length).fill(false));
    const submenuRef = useRef(null);
    const [selectedStatus, setSelectedStatus] = useState(''); // For status filtering


    // Define your columns
    const columns = [
        {
            header: 'Order ID',
            accessorKey: 'order_id',
            enableSorting: true,
            meta: {
                align: 'text-left',
            },
            cell: ({ row }) => (`#${String(row.original.order_id).padStart(6, '0')}`),

        },
        {
            header: 'Event Name',
            accessorKey: 'event_name',
            enableSorting: true,
            meta: {
                align: 'text-left',
            },
        },
        {
            header: 'Customer',
            accessorKey: 'email',
            enableSorting: true,
            meta: {
                align: 'text-left',
            },
            cell: ({ row }) => (
                <span>{row.original.email}</span>
            )
        },
        {
            header: 'Status',
            accessorKey: 'order_status',
            enableSorting: true,
            meta: {
                align: 'text-left',
            },
            cell: ({ row }) => StatusPill(row.original.order_status),
        },
        {
            header: 'Price',
            accessorKey: 'price',
            enableSorting: true,
            meta: {
                align: 'text-left',
            },
            cell: ({ row }) => (row.original.price ? `$${row.original.price} ${row.original.currency}` : ''),
        },
        {
            header: 'Ordered Date',
            accessorKey: 'ordered_date',
            enableSorting: true,
            meta: {
                align: 'text-right',
            },
            cell: ({ row }) => convertUTCToHumanTime(row.original.ordered_date),
        },
        // {
        //     header: 'Action',
        //     enableSorting: false,
        //     meta: {
        //         align: 'text-right',
        //     },
        //     cell: ({ row }) => (
        //         <ActionButton
        //             row={row}
        //         />
        //     )
        // }
    ];


    const filteredOrders = useMemo(() => {
        return rows.filter(order => {
            const matchesStatus = selectedStatus === '' || order.order_status.toLowerCase() === selectedStatus.toLowerCase();
            const matchesSearch = filtering === '' || order.event_name.toLowerCase().includes(filtering.toLowerCase()) || order.email.toLowerCase().includes(filtering.toLowerCase());
            return matchesStatus && matchesSearch;
        });
    }, [rows, selectedStatus, filtering]); // D

    const table = useReactTable({
        data: filteredOrders,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        state: {
            sorting: sorting,
            globalFilter: filtering
        },
        onSortingChange: setSorting,
        onGlobalFilterChange: setFiltering,
        globalFilterFn: (row, columnId, filterValue) => {
            const value = row.getValue(columnId);
            return String(value).toLowerCase().includes(String(filterValue).toLowerCase());
        },
        initialState: {
            // sorting: [{ id: 'order_id', desc: true }], // Sort by 'order_id' in descending order initially
            pagination: {
                pageSize: 10,
            },
        },
    });

    const toggleActionMenu = (index) => {

        const finalState = Array(rows.length).fill(false)
        finalState[index] = !showActionMenus[index];
        setShowActionMenus(finalState);
    };

    const ActionButton = ({ row }) => {
        return (
            <>

                <button
                    className="inline-flex items-center py-1 px-2 text-sm font-medium text-center border-none text-gray-900 bg-white rounded-lg hover:bg-gray-100"
                    type="button"
                    onClick={() => toggleActionMenu(row.index)}
                >
                    <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 3">
                        <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z" />
                    </svg>
                </button>

                {
                    showActionMenus[row.index] ?

                        <>
                            < div
                                ref={submenuRef}
                                class="z-10 bg-white divide-y divide-gray-100 shadow w-44 absolute right-12 xl:right-44 mt-2 origin-top-right rounded-md">
                                <ul class="py-2 text-sm text-gray-700" >
                                    <li className='hover:bg-gray-50 hover:cursor-pointer disa'>
                                        <div
                                            onClick={() => orderSelected(row.original)}
                                            className="font-medium rounded-lg text-sm px-2 py-1 text-center mr-2"
                                        >
                                            Edit
                                        </div>
                                    </li>
                                    <li className='hover:bg-gray-50 hover:cursor-pointer disa'>
                                        {row.original.order_status.toLowerCase() === 'refunded' ? (
                                            <div
                                                className="font-medium rounded-lg text-sm px-2 py-1 text-center mr-2 opacity-50 cursor-not-allowed"
                                            >
                                                Refund
                                            </div>
                                        ) : (
                                            <div
                                                onClick={() => orderSelected(row.original)}
                                                className="font-medium rounded-lg text-sm px-2 py-1 text-center mr-2"
                                            >
                                                Refund
                                            </div>
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </> : <></>
                }
            </>
        );
    }

    return (
        <>

            <div className='w-full'>
                <div className='flex flex-row'>
                    <div className='flex justify-left'>
                        <input
                            placeholder='🔍 Search'
                            type='text'
                            value={filtering}
                            onChange={e => setFiltering(e.target.value)}
                            className="border border-gray-300 dark:border-white rounded-md px-2 py-1 m-2 w-60" />
                    </div>

                    <div className="flex ">
                        {/* Dropdown for status filtering */}
                        <select
                            value={selectedStatus}
                            onChange={(e) => setSelectedStatus(e.target.value)}
                            className="border border-gray-300 dark:border-white rounded-md px-2 py-1 m-2 w-32"
                        >
                            <option value="">All Orders</option>
                            <option value="refunded">Refunded</option>
                            <option value="completed">Completed</option>
                            <option value="open dispute">Open Dispute</option>
                        </select>
                    </div>
                </div>

                <Table className='border border-slate-200'>
                    <TableHead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <TableRow key={headerGroup.id} className="border-b border-tremor-border dark:border-dark-tremor-border">
                                {headerGroup.headers.map(header => (
                                    <TableHeaderCell
                                        key={header.id}
                                        onClick={header.column.getToggleSortingHandler()}
                                        onKeyDown={event => {
                                            if (event.key === 'Enter') {
                                                header.column.getToggleSortingHandler()(event);
                                            }
                                        }}
                                        className={classNames(
                                            header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                                            'px-0.5 py-1.5'
                                        )}
                                        tabIndex={header.column.getCanSort() ? 0 : -1}
                                        aria-sort={header.column.getIsSorted()}
                                    >
                                        <div
                                            className={classNames(
                                                header.column.columnDef.enableSorting === true
                                                    ? 'flex items-center justify-between gap-2 hover:bg-tremor-background-muted hover:dark:bg-dark-tremor-background-muted'
                                                    : header.column.columnDef.meta.align,
                                                'rounded-tremor-default px-3 py-1.5'
                                            )}
                                        >
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                            {header.column.getCanSort() && (
                                                <div className="-space-y-2">
                                                    <RiArrowUpSLine
                                                        className={classNames(
                                                            'h-4 w-4 text-tremor-content-strong dark:text-dark-tremor-content-strong',
                                                            header.column.getIsSorted() === 'desc' ? 'opacity-30' : ''
                                                        )}
                                                        aria-hidden={true}
                                                    />
                                                    <RiArrowDownSLine
                                                        className={classNames(
                                                            'h-4 w-4 text-tremor-content-strong dark:text-dark-tremor-content-strong',
                                                            header.column.getIsSorted() === 'asc' ? 'opacity-30' : ''
                                                        )}
                                                        aria-hidden={true}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </TableHeaderCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableHead>
                    <TableBody>
                        {table.getRowModel().rows.map(row => (
                            <TableRow
                                key={row.id}
                                className=' hover:cursor-pointer hover:bg-gray-100'
                                onClick={() => orderSelected(row.original)}
                            >
                                {row.getVisibleCells().map(cell => (
                                    <TableCell
                                        key={cell.id}
                                        className={`classNames(cell.column.columnDef.meta.align)`}
                                    >
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                <div className='flex justify-between my-2'>
                    <div>
                        <span className='text-gray-800'>Rows per page</span>
                        <select
                            value={table.getState().pagination.pageSize}
                            onChange={e => {
                                table.setPageSize(Number(e.target.value))
                            }}
                            className="border border-gray-300 dark:border-white rounded-md px-2 py-1 m-2 "
                        >
                            {[10, 25, 50, 100].map(size => (
                                <option key={size} value={size}>
                                    {size}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className='flex'>
                        <button
                            disabled={!table.getCanPreviousPage()}
                            onClick={() => table.setPageIndex(0)}
                            className="items-center border-inherit hover:bg-slate-100 justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground hidden h-8 w-8 p-0 lg:flex">
                            <span className="sr-only">Go to first page</span>
                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-4 w-4">
                                <path d="M6.85355 3.85355C7.04882 3.65829 7.04882 3.34171 6.85355 3.14645C6.65829 2.95118 6.34171 2.95118 6.14645 3.14645L2.14645 7.14645C1.95118 7.34171 1.95118 7.65829 2.14645 7.85355L6.14645 11.8536C6.34171 12.0488 6.65829 12.0488 6.85355 11.8536C7.04882 11.6583 7.04882 11.3417 6.85355 11.1464L3.20711 7.5L6.85355 3.85355ZM12.8536 3.85355C13.0488 3.65829 13.0488 3.34171 12.8536 3.14645C12.6583 2.95118 12.3417 2.95118 12.1464 3.14645L8.14645 7.14645C7.95118 7.34171 7.95118 7.65829 8.14645 7.85355L12.1464 11.8536C12.3417 12.0488 12.6583 12.0488 12.8536 11.8536C13.0488 11.6583 13.0488 11.3417 12.8536 11.1464L9.20711 7.5L12.8536 3.85355Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd">
                                </path>
                            </svg>
                        </button>
                        <button
                            disabled={!table.getCanPreviousPage()}
                            onClick={() => table.previousPage()}
                            className='inline-flex border-inherit hover:bg-slate-100 items-center justify-center whitespace-nowrap rounded-md text-3xl font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground h-8 w-8 p-0'>
                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-4 w-4">
                                <path d="M8.84182 3.13514C9.04327 3.32401 9.05348 3.64042 8.86462 3.84188L5.43521 7.49991L8.86462 11.1579C9.05348 11.3594 9.04327 11.6758 8.84182 11.8647C8.64036 12.0535 8.32394 12.0433 8.13508 11.8419L4.38508 7.84188C4.20477 7.64955 4.20477 7.35027 4.38508 7.15794L8.13508 3.15794C8.32394 2.95648 8.64036 2.94628 8.84182 3.13514Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd">
                                </path>
                            </svg>
                        </button>

                        <button
                            disabled={!table.getCanNextPage()}
                            onClick={() => table.nextPage()}
                            className="inline-flex border-inherit hover:bg-slate-100 items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground h-8 w-8 p-0">
                            <span className="sr-only">Go to next page</span>
                            <svg
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4">
                                <path d="M6.1584 3.13508C6.35985 2.94621 6.67627 2.95642 6.86514 3.15788L10.6151 7.15788C10.7954 7.3502 10.7954 7.64949 10.6151 7.84182L6.86514 11.8418C6.67627 12.0433 6.35985 12.0535 6.1584 11.8646C5.95694 11.6757 5.94673 11.3593 6.1356 11.1579L9.565 7.49985L6.1356 3.84182C5.94673 3.64036 5.95694 3.32394 6.1584 3.13508Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd">
                                </path>
                            </svg>
                        </button>
                        <button
                            disabled={!table.getCanNextPage()}
                            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                            className="items-center border-inherit hover:bg-slate-100 justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground hidden h-8 w-8 p-0 lg:flex">
                            <span className="sr-only">Go to last page</span>
                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-4 w-4">
                                <path d="M2.14645 11.1464C1.95118 11.3417 1.95118 11.6583 2.14645 11.8536C2.34171 12.0488 2.65829 12.0488 2.85355 11.8536L6.85355 7.85355C7.04882 7.65829 7.04882 7.34171 6.85355 7.14645L2.85355 3.14645C2.65829 2.95118 2.34171 2.95118 2.14645 3.14645C1.95118 3.34171 1.95118 3.65829 2.14645 3.85355L5.79289 7.5L2.14645 11.1464ZM8.14645 11.1464C7.95118 11.3417 7.95118 11.6583 8.14645 11.8536C8.34171 12.0488 8.65829 12.0488 8.85355 11.8536L12.8536 7.85355C13.0488 7.65829 13.0488 7.34171 12.8536 7.14645L8.85355 3.14645C8.65829 2.95118 8.34171 2.95118 8.14645 3.14645C7.95118 3.34171 7.95118 3.65829 8.14645 3.85355L11.7929 7.5L8.14645 11.1464Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd">
                                </path>
                            </svg>
                        </button>
                    </div>

                </div>
            </div>
        </>
    );
}