import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { requestOrderDetails } from '../store/actions/index';
import { useAuth0 } from '@auth0/auth0-react';
import Loader from '../components/Loader';
import QRCode from "react-qr-code";
import { BsArrowLeftShort } from 'react-icons/bs';
import { classNames } from '../components/shared/Utils'

import defaultOrg from '../images/default_org.png'



const OrderDetails = () => {

    const dispatch = useDispatch();
    const { orderId } = useParams();
    const navigate = useNavigate();


    const orderDetails = useSelector((state) => state.orderDetails.orderDetails);
    const orderDetailsLoading = useSelector((state) => state.orderDetails.loading);
    const { user, getAccessTokenSilently, getIdTokenClaims, isLoading, isAuthenticated } = useAuth0();


    // useEffect(() => {
    //     dispatch(requestOrderDetails(orderId, ));
    // }, []);

    useEffect(() => {
        const loadOrderDetail = async () => {
            const accessToken = await getAccessTokenSilently();
            console.log(accessToken);
            // dispatch(requestMyOrders(accessToken));
            dispatch(requestOrderDetails(orderId, accessToken));
        }

        loadOrderDetail();
    }, []);

    return (
        <div>
            {
                !orderDetailsLoading ? (
                    <div className='max-w-[1024px] w-full bg-white mx-auto items-start content-start my-12'>
                        <div className="flex-row inline-flex text-[#474B55] font-medium cursor-pointer hover:font-semibold my-4" onClick={() => navigate(-1)}>
                            <BsArrowLeftShort size={25} />
                            BACK
                        </div>
                        <div className='flex flex-col lg:flex-row w-full px-2 items-start justify-between'>
                            {/* left part */}
                            <div className='flex flex-col w-full lg:w-80 mb-10 flex-0 box-border shadow-sm justify-center lg:sticky lg:h-fit'>

                                <div className='w-full lg:w-80 bg-white p-1'>
                                    <div className='w-full '>
                                        <img src={orderDetails.event.img_url} alt='offer display' />
                                    </div>
                                </div>


                                <div className='hidden lg:block w-80 mt-10 bg-white p-1'>
                                    <div className='flex p-6 justify-center'>
                                        <img className={`p-1 h-24 w-24 max-w-fit border-[#e6e8ed] border-[1px] bg-white text-black rounded-full object-cover ${orderDetails.organization.img_url != null ? '' : 'grayscale'}`} src={orderDetails.organization.img_url != null ? orderDetails.organization.img_url : defaultOrg} alt="organization logo" />

                                    </div>
                                    <span className='flex px-6 mb-6 justify-center font-normal text-md'>{orderDetails.organization.name}
                                        {
                                            (
                                                (orderDetails.organization.is_verified) ?
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 fill-blue-500">
                                                        <path fillRule="evenodd" d="M16.403 12.652a3 3 0 000-5.304 3 3 0 00-3.75-3.751 3 3 0 00-5.305 0 3 3 0 00-3.751 3.75 3 3 0 000 5.305 3 3 0 003.75 3.751 3 3 0 005.305 0 3 3 0 003.751-3.75zm-2.546-4.46a.75.75 0 00-1.214-.883l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" />
                                                    </svg>
                                                    : ''
                                            )
                                        }
                                    </span>

                                    <p className='text-[#757575] text-center'>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos corporis pariatur amet, deleniti nam accusantium aspernatur numquam! <span className='text-sm text-blue-500'>See More</span>
                                    </p>
                                </div>

                            </div>

                            {/* Right Part */}
                            <div className='flex-col flex-1 lg:ml-4 border-l-gray-100 shadow-sm'>

                                {/* Tickets */}
                                <div className=' bg-white w-full lg:px-8 lg:pb-10'>
                                    <div className='text-3xl text-slate-800'>
                                        Tickets
                                    </div>


                                    {orderDetails.tickets.map((ticket, i) => (
                                        <div id="raffle-red" className="entry raffle mt-4">
                                            <div className="holes-top hidden lg:block"></div>

                                            <div className='flex flex-col md:flex-row px-8 py-10 lg:py-0'>
                                                <div className='flex w-full lg:w-72 md:w-40 justify-center'>
                                                    <QRCode
                                                        size={150}
                                                        style={{ height: "auto", maxWidth: "80%", width: "80%" }}
                                                        value={ticket.ticket_hash}
                                                        viewBox={`0 0 256 256`}
                                                    />
                                                </div>

                                                <div className='flex flex-col pl-4 border-dashed border-t-2 mt-6 md:border-t-0 md:mt-0 md:border-l-2 md:ml-6'>

                                                    <div className='text-3xl text-14xl grid grid-cols-5 mt-10 md:mt-0'>
                                                        <div className='col-span-4'>{ticket.title}</div>
                                                        {/* <span className='bg-[#5295351A] text-[#3E7128] bg-opacity-10 px-3 py-1 uppercase leading-normal font-bold text-xs rounded-full shadow-sm col-span-1 h-fit'>
                                                            Active
                                                        </span> */}
                                                        <span className={
                                                            classNames(
                                                                "bg-opacity-10 px-3 py-1 uppercase font-bold leading-normal text-xs rounded-full col-span-1 h-fit w-fit",
                                                                ticket.status.startsWith("Active") ? "bg-[#5295351A] text-[#3E7128] bg-opacity-10" : null,
                                                                ticket.status.startsWith("Claimed") ? "bg-[#54B4D3] text-[#236D86] bg-opacity-20" : null,
                                                            )
                                                        }>
                                                            {ticket.status}
                                                        </span>
                                                    </div>
                                                    <div className='text-[#474b55] font-normal text-sm block '>
                                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur deleniti officia architecto fugit quo. Nulla
                                                    </div>
                                                    <div className='text-[#474b55] font-normal text-sm block py-2'>
                                                        ${ticket.price}
                                                    </div>
                                                </div>
                                                {/* <div className='flex mx-5 justify-end'>
                                                        <button type="submit" className="flex w-full items-center justify-center self-center rounded-md border border-transparent bg-indigo-600 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mr-2">Transfer</button>

                                                        <button type="submit" className="flex w-full items-center justify-center self-center rounded-md border border-transparent bg-indigo-600 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">View</button>

                                                    </div> */}
                                            </div>

                                            <div className="holes-bottom hidden lg:block"></div>

                                        </div>
                                    ))}
                                </div>


                                {/* charge breakdown */}
                                {/* < div className='justify-center bg-white mx-auto self-center' >
                                    <div className='grid grid-cols-2 gap-14 py-6'>
                                        <div className='px-8'>
                                            <div className=''>Subtotal</div>
                                            <div className=''>Taxes</div>
                                            <div className=''>Processing Fees</div>
                                        </div>
                                        <div>
                                            <div className=''>$100.00</div>
                                            <div className=''>$20.00</div>
                                            <div className=''>$4.00</div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='grid grid-cols-2 gap-14 bg-white py-4'>
                                        <div className='px-8'>
                                            <div>Total</div>
                                        </div>
                                        <div>
                                            <div>$124.00</div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>

                            <div className='block lg:hidden w-full mt-10 bg-white shadow-md p-1'>
                                <div className='flex p-6 justify-center'>
                                    <img className='p-1 h-24 w-24 max-w-fit border-[#e6e8ed] border-[1px] bg-white text-black rounded-full object-cover' src={orderDetails.organization.img_url} />
                                </div>
                                <span className='flex px-6 mb-6 justify-center font-normal text-md'>{orderDetails.organization.name}
                                    {
                                        (
                                            (orderDetails.organization.is_verified) ?
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 fill-blue-500">
                                                    <path fillRule="evenodd" d="M16.403 12.652a3 3 0 000-5.304 3 3 0 00-3.75-3.751 3 3 0 00-5.305 0 3 3 0 00-3.751 3.75 3 3 0 000 5.305 3 3 0 003.75 3.751 3 3 0 005.305 0 3 3 0 003.751-3.75zm-2.546-4.46a.75.75 0 00-1.214-.883l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" />
                                                </svg>
                                                : ''
                                        )
                                    }
                                </span>

                                <p className='text-[#757575] text-center'>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos corporis pariatur amet, deleniti nam accusantium aspernatur numquam! <span className='text-sm text-blue-500'>See More</span>
                                </p>
                            </div>

                        </div>
                    </div>
                ) : <Loader />
            }
        </div>



    )
}

export default OrderDetails