import React from "react";
import { useLocation } from 'react-router-dom';
// import logo from '../images/lymetickets_logo_dark_green.png';
import logo from '../images/Lymeticket_2024_logo.svg';
import { Link } from 'react-router-dom';
import OrganizersCallToAction from '../components/OrganizersCallToAction';


const Footer = () => {

	const location = useLocation();

	// Check if the pathname contains "/organizer"
	const hideDiv = location.pathname.includes('/organizer') || location.pathname.includes('/scan') || location.pathname.includes('/events/') ||
		location.pathname.includes('/create-organization') || location.pathname.includes('/settings');

	return (
		<div>
			{hideDiv ? null :
				<>
					<OrganizersCallToAction />
					<footer class="bg-[#fafafb] lg:pt-28 pt-24">

						<div class="mx-auto w-full max-w-screen-xl">
							<div class="grid grid-cols-2 gap-8 px-4 py-6 lg:py-8 md:grid-cols-2 lg:grid-cols-6">
								<div className="col-span-3 max-w-md">
									<img src={logo} alt="lymetickets logo" className="w-72" />
									<div className="text-gray-500 font-medium mt-1 mb-6">
										Want to make your tickets easily accessible, download our assets and leverage them on your flyers or promotional mediums
										See brand guide for more details.
									</div>
									<button type="button" class=" bg-lime-500 text-white border border-none focus:outline-none hover:bg-lime-600 focus:ring-4 focus:bg-lime-600 font-semibold rounded-lg text-sm px-5 py-2.5 mr-2 mb-2">Download SVG</button>
								</div>
								<div>
									<h2 class="my-6 text-sm font-semibold text-gray-900 uppercase">Company</h2>
									<ul class="text-gray-500 font-medium">
										<li class="mb-4">
											<Link to="about-us" class="hover:underline">About</Link>
										</li>
										<li class="mb-4">
											<a href="#" class="hover:underline">Brand Center</a>
										</li>
										<li class="mb-4">
											<Link to="faq" class="hover:underline">FAQ</Link>
										</li>
										<li class="mb-4">
											<Link to="contactus" class="hover:underline">Contact Us</Link>
										</li>
									</ul>
								</div>
								{/* <div>
									<h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase">Help center</h2>
									<ul class="text-gray-500 font-medium">
										<li class="mb-4">
											<a href="https://www.instagram.com/lymetickets/" target="_blank"  class="hover:underline">Discord Server</a>
										</li>
										<li class="mb-4">
											<a href="https://twitter.com/lymetickets" target="_blank" class="hover:underline">Twitter</a>
										</li>
										<li class="mb-4">
											<a href="#" target="_blank" class="hover:underline">Facebook</a>

										</li>
										<li class="mb-4">
											<Link to="contactus" class="hover:underline">Contact Us</Link>
										</li>
									</ul>
								</div> */}
								<div>
									<h2 class="my-6 text-sm font-semibold text-gray-900 uppercase">Legal</h2>
									<ul class="text-gray-500 font-medium">
										<li class="mb-4">
											<Link to="privacy-policy" class="hover:underline">Privacy Policy</Link>
										</li>
										{/* <li class="mb-4">
											<a href="#" class="hover:underline">Licensing</a>
										</li> */}
										<li class="mb-4">
											<a href="#" class="hover:underline">Terms &amp; Conditions</a>
										</li>
									</ul>
								</div>
								{/* <div>
								<h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase">Download</h2>
								<ul class="text-gray-500 font-medium">
									<li class="mb-4">
										<a href="#" class="hover:underline">iOS</a>
									</li>
									<li class="mb-4">
										<a href="#" class="hover:underline">Android</a>
									</li>
									<li class="mb-4">
										<a href="#" class="hover:underline">Windows</a>
									</li>
									<li class="mb-4">
										<a href="#" class="hover:underline">MacOS</a>
									</li>
								</ul>
							</div> */}
							</div>
							<div class="px-4 py-6 bg-[#fafafb] md:flex md:items-center md:justify-between">
								<span class="text-sm text-gray-500 sm:text-center">Minted in 🇯🇲 © 2023 <a href="https://lymetickets.com/">Lymetickets™</a>. All Rights Reserved.
								</span>
								<div class="flex mt-4 space-x-6 sm:justify-center md:mt-0">
									<a href="#" class="text-gray-400 hover:text-gray-900 ">
										<svg class="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" /></svg>
										<span class="sr-only">Facebook page</span>
									</a>
									<a href="https://www.instagram.com/lymetickets/" target="_blank" className="text-gray-400 hover:text-gray-900 ">
										<svg class="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clip-rule="evenodd" /></svg>
										<span class="sr-only">Instagram page</span>
									</a>
									<a href="https://twitter.com/lymetickets" target="_blank" class="text-gray-400 hover:text-gray-900">
										<svg class="w-5 h-6" fill="none" viewBox="0 0 1200 1750" xmlns="http://www.w3.org/2000/svg">
											<path d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z" fill="currentColor" />
										</svg>
										<span class="sr-only">Twitter page</span>
									</a>

								</div>
							</div>
						</div>
					</footer>

				</>
			}
		</div>
	);

};

export default Footer;