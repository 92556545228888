import React, { useState, useEffect } from 'react';
import ScrollToTop from './utils/ScrollToTop';
import { Link, Outlet, useParams, useNavigate, useLocation } from 'react-router-dom';
import OrganizerSideNav from './OrganizerSideNav';
import CreateOrganizerModal from './CreateOrganizerModal';

import { requestUserOrganizaions, setSelectedOrganization } from '../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import Loader from './Loader';

const OrgWrapper = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
    const [loading, setLoading] = useState(true);
    const orgs = useSelector((state) => state.userOrgs.orgs.data);
    const orgs1 = useSelector((state) => state.userOrgs.orgs);
    const orgsLoading = useSelector((state) => state.userOrgs.loading);
    const selectedOrg = useSelector((state) => state.selectedOrganization.selectedOrg);
    const selectedOrgLoading = useSelector((state) => state.selectedOrganization.loading);

    useEffect(() => {
        const getOrganizations = async () => {
            if (loading) {
                const accessToken = await getAccessTokenSilently();
                dispatch(requestUserOrganizaions(accessToken));
                setLoading(false);
            }
        };

        getOrganizations();
    }, [loading, getAccessTokenSilently, dispatch]);

    useEffect(() => {
        const setOrg = async () => {
            if (!loading && !orgsLoading && orgs && orgs.length > 0) {
                if (location.pathname === '/organizer') {
                    const latestOrg = orgs.length - 1
                    dispatch(setSelectedOrganization(orgs[latestOrg].org_id));
                } else if (location.pathname.includes('/organizer/')) {
                    const parts = location.pathname.split('/');
                    const orgIdFromURL = parts[2];
                    dispatch(setSelectedOrganization(orgIdFromURL));
                }
            }
        };

        setOrg();
    }, [loading, orgsLoading, location.pathname, dispatch, orgs]);

    useEffect(() => {
        if (!loading && !selectedOrgLoading && selectedOrg != null) {
            if (location.pathname === '/organizer') {
                navigate("/organizer/" + selectedOrg + "/dashboard");
            }
        }
    }, [selectedOrgLoading, selectedOrg, location.pathname, navigate]);

    return (
        <>
            {!loading && !orgsLoading && orgs !== null && selectedOrg !== null ? (
                <div className="h-full bg-gray-50">
                    <ScrollToTop />
                    <OrganizerSideNav />
                    <div className="bg-gray-50">
                        <div className="xl:mx-24">
                            <Outlet />
                        </div>
                    </div>
                </div>
            ) : (!orgsLoading && orgs1 !== null && orgs1.data && orgs1.data.length === 0) ? (
                <div className='h-full'>
                    <CreateOrganizerModal />
                </div>
            ) : (
                <div className="flex h-screen">
                    <div className="m-auto">
                        <Loader />
                    </div>
                </div>
            )}
        </>
    );
}

export default OrgWrapper;
