import React, { useEffect, useState } from 'react'
import StepperControl from '../StepperControl'
import { getCurrencyFormat } from '../common/currencyUtils';


const TicketSelection = ({ tickets, ticketState, setTicketState, currentStep, steps, increaseStep, setIsNextDisabled }) => {

    const [quantity, setQuantity] = useState(0);

    const handleQuantityChange = (id, newLineItemQuantity, name, newLineItemBasePrice, newLineItemProcessingFee, newLineItemServiceCharge, newSubTotalPrice, newTotalProcessingFee, newTotalServiceCharge, newtotal, totalTicketCount) => {

        setTicketState({
            ...ticketState, tickets: {
                ...ticketState.tickets, [id]:
                {
                    ...ticketState.tickets[id],
                    ['quantity']: newLineItemQuantity,
                    ['title']: name,
                    ['price']: newLineItemBasePrice,
                    ['processing_fee']: newLineItemProcessingFee,
                    ['service_charge']: newLineItemServiceCharge
                }
            }, subTotal: newSubTotalPrice, totalProcessingFee: newTotalProcessingFee, totalServiceCharge: newTotalServiceCharge, total: newtotal, totalTicketCount
        })

        // if (totalTicketCount > 0) {
        //     setIsNextDisabled(false)
        // } else {
        //     setIsNextDisabled(true)
        // }
    };

    const handlePlusClick = (e) => {
        const name = e.target.name;
        const id = e.target.id;
        const quantity = ticketState.tickets[id].quantity
        const unitPrice = parseFloat(ticketState.tickets[id].unit_price).toFixed(2)
        const newQty = parseInt(quantity) + 1

        let unitProcessingFee = (0.00).toFixed(2)
        let unitServiceCharge = (0.00).toFixed(2)

        if (unitPrice > 0) {
            unitProcessingFee = (parseFloat(unitPrice) * 0.029 + 0.30).toFixed(2)
            unitServiceCharge = (parseFloat(unitPrice) * 0.071 - 0.30).toFixed(2)
        }

        const newBasePrice = parseFloat(parseFloat(unitPrice) * newQty).toFixed(2)
        const newProcessingFee = parseFloat(unitProcessingFee * newQty).toFixed(2)
        const newServiceCharge = parseFloat(unitServiceCharge * newQty).toFixed(2)


        const newSubTotalPrice = parseFloat(parseFloat(ticketState.subTotal) + parseFloat(unitPrice)).toFixed(2)
        const newTotalProcessingFee = parseFloat(parseFloat(ticketState.totalProcessingFee) + parseFloat(unitProcessingFee)).toFixed(2)
        const newTotalServiceCharge = parseFloat(parseFloat(ticketState.totalServiceCharge) + parseFloat(unitServiceCharge)).toFixed(2)
        const newTotalPrice = parseFloat(parseFloat(ticketState.total) + parseFloat(unitPrice) + parseFloat(unitProcessingFee) + parseFloat(unitServiceCharge)).toFixed(2)
        // console.log(newSubTotalPrice, newTotalProcessingFee, newTotalServiceCharge, newTotalPrice);
        // console.log(ticketState.total, unitPrice, unitProcessingFee, unitServiceCharge);
        const totalTicketCount = ticketState.totalTicketCount + 1

        handleQuantityChange(id, newQty, name, newBasePrice, newProcessingFee, newServiceCharge, newSubTotalPrice, newTotalProcessingFee, newTotalServiceCharge, newTotalPrice, totalTicketCount);

    };

    const handleMinusClick = (e) => {
        const name = e.target.name;
        const id = e.target.id;
        const quantity = ticketState.tickets[id].quantity
        const unitPrice = parseFloat(ticketState.tickets[id].unit_price).toFixed(2);
        let unitProcessingFee = (0.00).toFixed(2)
        let unitServiceCharge = (0.00).toFixed(2)

        if (quantity > 0) {

            const newQty = parseInt(quantity) - 1

            if (unitPrice > 0) {
                unitProcessingFee = (parseFloat(unitPrice) * 0.029 + 0.30).toFixed(2);
                unitServiceCharge = (parseFloat(unitPrice) * 0.071 - 0.30).toFixed(2);
            }

            const newBasePrice = parseFloat(parseFloat(unitPrice) * newQty).toFixed(2)
            const newProcessingFee = parseFloat(unitProcessingFee * newQty).toFixed(2)
            const newServiceCharge = parseFloat(unitServiceCharge * newQty).toFixed(2)


            const newSubTotalPrice = parseFloat(parseFloat(ticketState.subTotal) - parseFloat(unitPrice)).toFixed(2)
            const newTotalProcessingFee = parseFloat(parseFloat(ticketState.totalProcessingFee) - parseFloat(unitProcessingFee)).toFixed(2)
            const newTotalServiceCharge = parseFloat(parseFloat(ticketState.totalServiceCharge) - parseFloat(unitServiceCharge)).toFixed(2)
            const newTotalPrice = parseFloat(parseFloat(ticketState.total) - parseFloat(unitPrice) - parseFloat(unitProcessingFee) - parseFloat(unitServiceCharge)).toFixed(2)
            // console.log(newSubTotalPrice, newTotalProcessingFee, newTotalServiceCharge, newTotalPrice);
            // console.log(ticketState.total, unitPrice, unitProcessingFee, unitServiceCharge);
            const totalTicketCount = ticketState.totalTicketCount - 1

            handleQuantityChange(id, newQty, name, newBasePrice, newProcessingFee, newServiceCharge, newSubTotalPrice, newTotalProcessingFee, newTotalServiceCharge, newTotalPrice, totalTicketCount);
        }
    };

    const handleSubmit = (e) => {
        increaseStep("next");
    };

    useEffect(() => {
        if (ticketState.totalTicketCount > 0) {
            setIsNextDisabled(false)
        } else {
            setIsNextDisabled(true)
        }
    }, [ticketState]);

    return (
        <div className="mt-1">
            <h2 className="text-2xl font-semibold mb-2 ml-6">Ticket Selection</h2>
            {/* <div className='text-xs'>Log in for faster checkout experience</div> */}

            {/* */}
            <div className="-mt-8">
                <div className="bg-white px-4 sm:p-6">

                    {
                        tickets.map((ticket) => (

                            <div className={`flex mt-5 ${ticketState.tickets[ticket.ticket_id] > 0 ? 'ring-blue-500 ring-2 rounded-md' : ''} `} key={ticket.ticket_id}>

                                <div className="bg-white shadow-md rounded-md p-6 w-full grid grid-cols-6">

                                    <div className='flex flex-col col-span-4'>
                                        <h2 className="text-lg font-medium mb-2 text-slate-800">{ticket.title}</h2>
                                        {/* <span></span> */}
                                        {parseFloat(ticket.price) > parseFloat(0.00) ?
                                            <p className="text-slate-600 mb-4">{getCurrencyFormat(ticket.price, ticket.currency)} </p> :
                                            <p className="text-slate-600 mb-4">Free</p>
                                        }

                                    </div>


                                    {/* counter */}
                                    <div className="flex items-center col-span-2 justify-center">
                                        <button
                                            className="text-slate-600 rounded-full hover:bg-gray-300 px-3 py-1 mr-2 w-9 border-slate-500 disabled:opacity-25"
                                            onClick={handleMinusClick}
                                            name={ticket.title}
                                            id={ticket.ticket_id}
                                            disabled={ticketState.tickets[ticket.ticket_id]?.quantity === 0}
                                        >
                                            -
                                        </button>
                                        <input
                                            type="number"
                                            min="0"
                                            defaultValue="0"
                                            disabled
                                            value={ticketState.tickets[ticket.ticket_id]?.quantity ?? 0}
                                            // title={ticket.title}
                                            onChange={(event) => handleQuantityChange(parseInt(event.target.value))}
                                            className="border border-gray-300 text-center rounded-md px-3 py-2 w-12 appearance-none"
                                        />


                                        <button
                                            className="text-slate-600 rounded-full hover:bg-gray-100 px-3 py-1 ml-2 w-9 border-slate-500 "
                                            onClick={handlePlusClick}
                                            name={ticket.title}
                                            id={ticket.ticket_id}
                                        >
                                            +
                                        </button>
                                    </div>

                                </div>

                            </div>
                        ))}
                </div>

            </div>

            {/* <StepperControl
                handleClick={handleSubmit}
                currentStep={currentStep}
                steps={steps}
                nextText='Next'
            /> */}

        </div >
    )
}

export default TicketSelection