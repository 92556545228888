import * as actionTypes from "../actions/actionTypes";

export const initialState = {
    validationStatus: {},
    loading: false,
    error: ""
}

export default function validateTicketReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.REQUEST_TICKET_VALIDATION: {
            return {
                ...state,
                validationStatus: {},
                loading: true,
                error: ""
            };
        }
        case actionTypes.RECEIVE_TICKET_VALIDATION: {
            console.log(action.payload);
            switch (action.payload.status) {
                case 'ERROR': {
                    console.log(action.payload);
                    return {
                        ...state,
                        validationStatus: {},
                        loading: false,
                        error: action.payload.errorData
                    };
                }
                default: {
                    return {
                        ...state,
                        validationStatus: action.payload.data,
                        loading: false,
                        error: ""
                    }
                }
            }
        }
        default:
            return state;
    }
}
