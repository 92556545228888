import React from 'react'

const StepperControl = ({ handleClick, currentStep, steps, nextText = 'Next', prevText = 'Back', isNextDisabled = undefined, show = true }) => {
    return ((show ?
        <div className='container grid grid-col-6 gap-4 mt-4 mb-8 px-20'>
            {/* back */}
            <button
                onClick={() => handleClick("prev")}
                className={`bg-white text-slate-400 uppercase py-2 px-4 rounded-xl font-semibold cursor-pointer border-1 
            border-slate-300 hover:bg-slate-700 hover:text-white transition duration-200 ease-in-out col-start-1 col-end-2 ${currentStep <= 1 ? 'opacity-50 cursor-not-allowed hidden' : ''} `}>
                {/* Back */}
                {prevText}
            </button>

            {/* next button */}
            <button
                disabled={isNextDisabled}
                onClick={() => handleClick("next")}
                className={`bg-lime-600 text-white uppercase py-2 px-4 rounded-xl font-semibold cursor-pointer border-lime-600
            hover:bg-lime-500 hover:border-lime-500 hover:text-white hover:opacity-80 transition duration-200 ease-in-out col-start-5 col-end-6 disabled:opacity-30 disabled:bg-slate-700 disabled:border-slate-300`}>
                {/* Next */}
                {/* {currentStep == steps.length - 1 ? "Confirm" : "Next"} */}
                {nextText}
            </button>
        </div>
        : <></>
    )

    )
}

export default StepperControl