import React, { useState } from 'react'
import { BsEyeFill, BsCalendar4 } from 'react-icons/bs';
import { MdOutlineLocationOn } from 'react-icons/md'
import { convertDateToHuman } from './common/dateUtils';
import { Link } from 'react-router-dom';

import defaultOrg from '../images/default_org.png'


const Card = (cardInfo) => {

    const [showFullDescription, setFullDescription] = useState(false);

    const showFullDescriptionHandler = () => {
        setFullDescription(!showFullDescription);
    };

    const description = showFullDescription ? cardInfo.cardInfo.short_description : cardInfo.cardInfo.short_description.slice(0, 65);


    return (

        < Link to={`/events/` + cardInfo.cardInfo.id} className='pointer rounded-xl relative bg-white flex flex-col border-[#e6e8ed] border-[1px] overflow-hidden hover:shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]' >
            <img className="w-full object-cover rounded-t-lg h-28 md:max-w-md  transition duration-300 ease-in-out hover:scale-110 hover:cursor-pointer" src={cardInfo.cardInfo.img_url} alt="" />
            {/* overlay */}
            <div className='relative shadow-lg h-12 w-12 sm:h-16 sm:w-16 bg-white -mt-6 ml-4 sm:-mt-8 sm:ml-8 rounded-md overflow-hidden'>
                <img className={`absolute p-1 max-h-16 max-w-16 border-black bg-white text-black rounded-md object-cover overflow-hidden ${cardInfo.cardInfo.organizer.img_url != null ? '' : 'grayscale'}`} src={cardInfo.cardInfo.organizer.img_url != null ? cardInfo.cardInfo.organizer.img_url : defaultOrg} />
            </div>

            <div className="h-10 px-4 my-4 inline-block md:h-10">
                <p className="font-semibold leading-4 h-12 tracking-tight text-xs text-gray-700 overflow-hidden md:text-base md:leading-5 md:h-10">{cardInfo.cardInfo.title}</p>
            </div>

            {/* <div className="max-h-12 px-4 inline-block md:h-16">
                <p className="text-gray-700 text-base">{description}</p>
                <div onClick={showFullDescriptionHandler} className='hover:cursor-pointer hover:text-[#000952] text-[#0062AB] underline uppercase -leading-3 tracking-wider font-semibold text-xs'>
                    Read {showFullDescription ? "Less" : "More"}
                </div>
            </div> */}

            <div className='h-6 flex items-center ml-4'>
                <BsCalendar4 size={15} className="text-[#757575] opacity-30" />
                <span className='pl-1 text-xs text-[#757575] opacity-70 leading-5'>{convertDateToHuman(cardInfo.cardInfo.start_time)}</span>
            </div>

            <div className='h-6 flex items-center ml-4'>
                <MdOutlineLocationOn size={20} className="text-[#757575] opacity-30 -ml-1" />
                <span className='pl-1 text-xs text-[#757575] opacity-70'>{cardInfo.cardInfo.location.name}</span>
            </div>

            <div className='h-6 flex items-center mb-2 ml-4'>
                <BsEyeFill size={15} className="text-[#757575] opacity-30" />
                <span className='pl-1 text-xs text-[#757575] opacity-70'>55k</span>
            </div>

        </Link >
    )
}

export default Card