// 'use client';
import React, { useEffect, useState, useRef } from 'react'
import UserAvatar from './UserAvatar';
import { convertUnixToHumanDate, convertToHumanDate, convertToRelativeDate } from './common/dateUtils';
import { classNames } from './shared/Utils'
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeaderCell,
    TableRow,
} from '@tremor/react';



const Table3 = ({ tableType, headers, rows, orderSelected }) => {

    const [showActionMenus, setShowActionMenus] = useState(Array(rows.length).fill(false));
    const submenuRef = useRef(null);

    const closeAllSubmenus = () => {
        setShowActionMenus(Array(rows.length).fill(false));
    };

    const tableColumnHeader = (name) => {
        return (
            <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
                {name}
            </TableHeaderCell>
        )
    }

    const orgUsersRow = (row) => {
        return (
            <tr class="transition-all hover:bg-gray-100 hover:shadow-lg">
                {/* <td class="px-6 py-4 whitespace-nowrap">
                    <td class="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{row.order_id}</td>
                </td> */}
                {/* <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {row.first_name}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {row.last_name}
                </td> */}
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {row.email}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {row.roles[0].name}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {row.status}
                </td>
                <td class="px-6 py-2 whitespace-nowrap">

                    <button
                        class="inline-flex items-center p-2 text-sm font-medium text-center border-none  text-gray-900 bg-white rounded-lg hover:bg-gray-100 "
                        type="button"
                    // onClick={() => toggleActionMenu(index)}
                    >
                        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 3">
                            <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z" />
                        </svg>
                    </button>
                </td>
            </tr>
        )
    }

    const orgRolesRow = (row) => {
        return (
            <TableRow class="transition-all hover:bg-gray-100 hover:shadow-lg">
                <TableCell class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {row.name}
                </TableCell>
                <TableCell class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {row.description}
                </TableCell>
                <TableCell class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {row.user_count}
                </TableCell>
            </TableRow>
        )
    }

    const latestTransactionRow = (row) => {
        return (
            <TableRow class=" even:bg-white even:dark:bg-dark-tremor-background-muted">
                <TableCell class="px-6 py-0 content-center whitespace-nowrap">
                    <TableCell >
                        {/* {row.order_id} */}
                        #{String(row.order_id).padStart(6, '0')}
                    </TableCell>
                </TableCell>
                <TableCell class="px-6 py-0 content-center whitespace-nowrap text-sm text-gray-500">
                    {row.first_name}. {row.last_name}
                </TableCell>
                <TableCell class="px-6 py-0 content-center whitespace-nowrap text-sm text-gray-500">
                    {getTotal(row.tickets)}
                </TableCell>
                <TableCell class="px-6 py-0 content-center text-sm text-gray-500 whitespace-nowrap text-left">{convertToRelativeDate(row.order_date)}</TableCell>
            </TableRow>
        )
    }

    const consolidateRow = () => {

    }

    const orderDetailsRow = (row) => {
        return (
            <TableRow class=" even:bg-white even:dark:bg-dark-tremor-background-muted">
                <TableCell class="px-0 py-0 content-center whitespace-nowrap overflow-hidden overflow-ellipsis w-6 max-w-6">
                    <TableCell >
                        {row.ticket_name}
                    </TableCell>
                </TableCell>
                <TableCell class="px-6 py-0 content-center whitespace-nowrap text-sm text-gray-500">
                    {/* {row.ticket_id} */}
                    2
                </TableCell>
                <TableCell class="px-6 py-0 content-center whitespace-nowrap text-sm text-gray-500">
                    {/* {row.ticket_name} */}
                    $50.00
                </TableCell>
                <TableCell class="px-6 py-0 content-center text-sm text-gray-500 whitespace-nowrap text-left">
                    {/* {row.ticket_name} */}
                    $100.00
                </TableCell>
            </TableRow>
        )
    }

    const StatusPill = (value) => {
        const status = value ? value.toLowerCase() : "unknown";

        // const finalStatus = status.startsWith("completed") ? 'completed' : status

        return (
            <span
                className={
                    classNames(
                        "px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm",
                        status.startsWith("completed") ? "bg-[#d9fbd0] text-[#3E7128] border border-[#bee8b4]" : null,
                        // status.startsWith("refunded") ? "bg-[#ffefca] text-[#bc3803] bg-opacity-50" : null,
                        status.startsWith("refunded") ? "bg-[#e3e6ed] text-[#141824] border border-[#cbd0dd]  bg-opacity-50" : null,
                        // status.startsWith("") ? "bg-[#c7ebff] text-[#005585] border border-[#96d9ff]" : null,
                    )
                }
            >
                {status}
            </span>
        );
    };

    const toggleActionMenu = (index) => {

        closeAllSubmenus();

        const newShowActionMenus = [...showActionMenus];
        newShowActionMenus[index] = !newShowActionMenus[index];
        setShowActionMenus(newShowActionMenus);
    };

    const orgOrdersRow = (item, index) => {
        return (
            <TableRow
                key={item.workspace}
                className="even:bg-tremor-background-muted even:dark:bg-dark-tremor-background-muted"
            >
                <TableCell className="font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
                    #{String(item.order_id).padStart(6, '0')}
                </TableCell>
                <TableCell>
                    {item.event_name}
                </TableCell>
                <TableCell>
                    <td class="px-6 py-0 whitespace-nowrap text-sm text-gray-500 flex items-center">
                        <UserAvatar firstName={item.first_name} lastName={item.last_name} />
                        <span className='pl-3'>
                            {item.email}
                        </span>
                    </td>
                </TableCell>
                <TableCell>
                    {StatusPill(item.order_status)}
                </TableCell>
                <TableCell>
                    {
                        item.price ?
                            <>
                                ${item.price} {item.currency}
                            </>
                            :
                            <>
                                Free
                            </>
                    }
                </TableCell>
                <TableCell className="text-right">
                    {convertToHumanDate(item.ordered_date)}
                </TableCell>
                <TableCell className="text-right">
                    <button
                        class="inline-flex items-center p-2 text-sm font-medium text-center border-none  text-gray-900 bg-white rounded-lg hover:bg-gray-100 "
                        type="button"
                        onClick={() => toggleActionMenu(index)}>
                        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 3">
                            <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z" />
                        </svg>
                    </button>

                    {
                        showActionMenus[index] ?

                            <>
                                < div
                                    ref={submenuRef}
                                    class="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 absolute -ml-[6%]">
                                    <ul class="py-2 text-sm text-gray-700" >
                                        <li className='hover:bg-gray-50 hover:cursor-pointer disa'>
                                            {item.order_status.toLowerCase() === 'refunded' ? (
                                                <div
                                                    className="font-medium rounded-lg text-sm px-2 py-1 text-center mr-2 opacity-50 cursor-not-allowed"
                                                >
                                                    Refund
                                                </div>
                                            ) : (
                                                <div
                                                    onClick={() => orderSelected(item)}
                                                    className="font-medium rounded-lg text-sm px-2 py-1 text-center mr-2"
                                                >
                                                    Refund
                                                </div>
                                            )}
                                        </li>

                                    </ul>
                                </div>
                            </> : <></>
                    }
                </TableCell>
            </TableRow>
        )
    }

    const getTotal = (row) => {
        let total = 0;

        for (const ticket of row) {
            total += ticket.count;
        }

        return total;
    }


    return (
        <>
            <Table className="mt-8 w-full">
                <TableHead>
                    <TableRow className="border-b border-tremor-border dark:border-dark-tremor-border">
                        {headers.map((item, index) => (
                            tableColumnHeader(item)
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        rows.length > 0 &&

                        rows.map((item, index) => {
                            switch (tableType) {
                                case "latestPurchases":
                                    return latestTransactionRow(item);
                                case "orgOrders":
                                    return orgOrdersRow(item, index);
                                case "orgUsers":
                                    return orgUsersRow(item);
                                case "orgRoles":
                                    return orgRolesRow(item);
                                case "orderDetails":
                                    return orderDetailsRow(item);
                                default:
                                    return null; //paymentTransactionRow(item);
                            }
                        })

                    }
                </TableBody>
            </Table>

            {
                rows.length == 0 &&

                <div className='p-6 bg-white'>
                    <div class="tremor-Flex-root flex flex-row justify-center items-center w-full h-full border border-dashed rounded-tremor-default border-tremor-border bg-white dark:border-tdark-remor-border min-h-[22rem]">
                        <p class="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                            No data
                        </p>
                    </div>
                </div>
            }

        </>
    );
}

export default Table3;