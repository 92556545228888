import React, { useEffect, useState } from 'react'
import EventPreviewRectangleCard from '../components/EventPreviewRectangleCard'
import Filters from '../components/Filters'
import Loader from '../components/Loader';
import { requestAllOffers } from '../store/actions/index';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";



const Search = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    // const OffersList = useSelector((state) => state.allOffers.offers);
    // const OfferLoading = useSelector((state) => state.allOffers.loading);
    const allOffers = useSelector((state) => state.allOffers);
    const { offers, loading } = allOffers;

    // const [offers, setOffers] = useState([]);
    const [filteredOffers, setFilteredOffers] = useState(offers);
    const [selectedFilters, setSelectedFilters] = useState([]);

    useEffect(() => {
        dispatch(requestAllOffers());
    }, []);

    useEffect(() => {
        setFilteredOffers(offers)
    }, [offers])

    const handleClick = (offer) => {
        navigate("/events/" + offer.id);
    }

    // const handleShareClick = async (e) => {
    //     e.preventDefault();
    //     try {
    //         console.log("boop boop boop");
    //         const domain = window.location.origin;
    //         await navigator.share({
    //             text: 'Check out this event on Lymetickets.com',
    //             url: `${domain}/o/${id}`,
    //         });
    //     } catch (error) {
    //         console.error('Error sharing:', error);
    //     }
    // };

    const filterHandler = (event) => {
        console.log("This was what was clicked " + event.target.value);

        const filterValue = event.target.value;
        const isChecked = event.target.checked;

        // if (event.target.checked) {
        //     console.log(offers);
        //     const newOffers = offers.filter((offer) => offer.organizer.name === event.target.value)
        //     console.log("brrrrrrrrrrrrrrrrrrp");
        //     console.log(newOffers);
        //     setOffers(newOffers)
        // } else {
        //     setOffers(offers)
        // }

        if (isChecked) {
            setSelectedFilters([...selectedFilters, filterValue]);
        } else {
            setSelectedFilters(selectedFilters.filter((filter) => filter !== filterValue));
        }
    }

    useEffect(() => {
        let filteredOffers = offers;

        if (selectedFilters.length > 0) {
            filteredOffers = offers.filter((offer) => selectedFilters.includes(offer.category_name) || selectedFilters.includes(offer.organizer.name));
        }

        setFilteredOffers(filteredOffers);
    }, [offers, selectedFilters]);

    return (
        (loading ? <Loader /> :
            <div className='max-w-[1024px] mt-10 w-full mb-auto mx-auto  flex bg-white justify-center'>
                <div className='flex  w-full md:max-w-[944px] lg:max-w-[1166px] mr-6 ml-6'>
                    <div className='mb-6 mt-8 hidden lg:block lg:max-w-[25%] md:w-full border-solid max-h-fit pr-6'>
                        <Filters
                            OffersList={offers}
                            onChange={filterHandler}
                        />
                    </div>

                    <div className='mt-8' id='events-list'>
                        {Array.isArray(filteredOffers) && filteredOffers.map((offer, key) => (
                            <div key={key} onClick={() => handleClick(offer)}>
                                <EventPreviewRectangleCard {...offer} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )
    )
}

export default Search