import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from "@stripe/react-stripe-js";

import AddressForm from './AddressForm';
import CheckoutForm from "./CheckoutForm";

const StripeCheckoutComponent = ({ clientSecret, increaseStep, handleErrorToastMessage }) => {
    const [stripePromise, setStripePromise] = useState(null);

    // const stripePromise = loadStripe("pk_test_51NDxbkJatF2W2kOGcNYU5DVMbvYLCL1j4CmpwYTcYwVRhVxyg43nKb24U2NANWesvDIQQWS1tEqSEL8ZbUmTK5fT006Qc3qRpk");
    useEffect(() => {
        setStripePromise(loadStripe("pk_test_51NDxbkJatF2W2kOGcNYU5DVMbvYLCL1j4CmpwYTcYwVRhVxyg43nKb24U2NANWesvDIQQWS1tEqSEL8ZbUmTK5fT006Qc3qRpk"));
    }, []);

    // px-4 h-[47px] mt-2 block w-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 border border-red-700
    const appearance = {
        theme: 'stripe',
        variables: {
            colorPrimaryText: '#529535',
            // colorBackground: '#ffffff',
            // colorText: '#30313d',
            // colorDanger: '#df1b41',
            // fontFamily: 'Ideal Sans, system-ui, sans-serif',
            // spacingGridColumn: '20px',
            spacingUnit: '20px',
            spacingGridRow: '20px',
            spacingUnit: '5px',
            borderRadius: '0px',
        }
    };
    const options = {
        clientSecret,
        appearance,
    };

    const completePayment = () => {
        increaseStep("next")
    }

    const displayError = (message) => {
        handleErrorToastMessage(message)
    }

    return (
        <div id="stripe_widget">
            {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                    {/* <AddressForm /> */}
                    <CheckoutForm completePayment={completePayment} displayError={displayError} />
                </Elements>
            )}
        </div>
    );
};

export default StripeCheckoutComponent;