import React, { useEffect, useState } from 'react'
import Stepper from '../components/Stepper'
import StepperControl from '../components/StepperControl'
import OrganizationInfo from '../components/steps/OrganizationInfo'
import EventInfo from '../components/steps/EventInfo'
import VenueInfo from '../components/steps/VenueInfo'
import Summary from '../components/steps/Summary'
import { useAuth0 } from '@auth0/auth0-react';
import { BsArrowLeftShort } from 'react-icons/bs';
import { useNavigate } from "react-router-dom";
import dayjs from 'dayjs';


import { requestSubmissionOfEvent } from '../store/actions/index';
import { useDispatch, useSelector } from 'react-redux';
import TicketInfo from '../components/steps/TicketInfo';
import Loader from '../components/Loader'




const CreateEvent = () => {
    const submitEvent = useSelector((state) => state.submitEvent.event);
    const submitEventError = useSelector((state) => state.submitEvent.error);
    const submitEventLoading = useSelector((state) => state.submitEvent.loading);

    const selectedOrg = useSelector((state) => state.selectedOrganization.selectedOrg);


    const { user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();

    const dispatch = useDispatch();
    const navigate = useNavigate();


    const [currentStep, setCurrentStep] = useState(1);
    const [eventId, setEventId] = useState();
    const [formState, setFormState] = useState({
        orgInfo: {
            orgId: {
                value: selectedOrg,
                error: null
            },
        },
        eventInfo: {
            eventImgFile: {
                value: "",
                error: null
            },
            eventName: {
                value: "",
                error: null
            },
            shortDescription: {
                value: "",
                error: null
            },
            category: {
                value: "",
                error: null
            },
            // startDate: {
            //     value: "",
            //     error: null
            // },
            // startTime: {
            //     value: "",
            //     error: null
            // },
            // endDate: {
            //     value: "",
            //     error: null
            // },
            // endTime: {
            //     value: "",
            //     error: null
            // },
            startDate: {
                // value: new Date(start_time),
                value: dayjs(new Date()),
                error: null,
            },
            startTime: {
                // value: new Date(start_time),
                value: dayjs(new Date()),
                error: null,
            },
            endDate: {
                // value: new Date(end_time),
                value: dayjs(new Date()),
                error: null,
            },
            endTime: {
                // value: new Date(end_time),
                value: dayjs(new Date()),
                error: null,
            },
            timezone: {
                value: Intl.DateTimeFormat().resolvedOptions().timeZone,
                error: null,
            },
            longDescription: {
                value: "",
                error: null
            }
        },
        venueInfo: {
            venueId: {
                value: null,
                error: null
            },
            venue: {
                value: "",
                error: null
            },
            addressLine1: {
                value: "",
                error: null
            },
            addressLine2: {
                value: "",
                error: null
            },
            city: {
                value: "",
                error: null
            },
            state: {
                value: "",
                error: null
            },
            postalCode: {
                value: "",
                error: null
            },
            country: {
                value: "",
                error: null
            }
        },
        ticketInfo: []
    })

    const steps = [
        // "Organization Infomation",
        "Event Information",
        "Venue Information",
        "Ticket Information",
        "Complete"
    ];

    const displayStep = (step) => {
        switch (step) {
            // case 1:
            //     return <OrganizationInfo formState={formState} setFormState={setFormState} currentStep={currentStep} steps={steps} increaseStep={increaseStep} />
            case 1:
                return <EventInfo formState={formState} setFormState={setFormState} currentStep={currentStep} steps={steps} increaseStep={increaseStep} />
            case 2:
                return <VenueInfo formState={formState} setFormState={setFormState} currentStep={currentStep} steps={steps} increaseStep={increaseStep} />
            case 3:
                return <TicketInfo formState={formState} setFormState={setFormState} currentStep={currentStep} steps={steps} increaseStep={increaseStep} />
            case 4:
                return <Summary eventId={eventId} orgId={selectedOrg} />
            default:
        }


    }

    const submitForm = async () => {
        const accessToken = await getAccessTokenSilently();
        // console.log("the trimmed value");
        let ts = trimForm(formState);
        // console.log(ts);
        dispatch(requestSubmissionOfEvent(trimForm(formState), accessToken));
    }

    function trimForm(originalObject) {
        const convertedObject = {};

        for (const key in originalObject) {
            if (Array.isArray(originalObject[key])) {
                convertedObject[key] = originalObject[key];
            } else if (typeof originalObject[key] === "object") {
                convertedObject[key] = {};

                for (const subKey in originalObject[key]) {
                    convertedObject[key][subKey] = originalObject[key][subKey].value;
                }
            } else {
                convertedObject[key] = originalObject[key];
            }
        }

        return convertedObject;
    }


    useEffect(() => {

        if (!submitEventLoading && Object.keys(submitEventError).length > 1) {
            console.log("there is an error cant move on");
        } else if (!submitEventLoading && Object.keys(submitEvent).length > 1) {
            if ('eventId' in submitEvent) {
                let newStep = currentStep + 1;
                setCurrentStep(newStep);
                setEventId(submitEvent.eventId)
            }
        }
    }, [submitEvent]);


    const increaseStep = (direction) => {
        let newStep = currentStep;

        (direction == "next") ? newStep++ : newStep--;

        // newStep >= 1 && newStep <= steps.length && setCurrentStep(newStep);
        // console.log(newStep, steps.length);
        if (newStep == steps.length) {
            console.log(formState);
            submitForm()
        } else if (newStep >= 1 && newStep <= steps.length) {
            setCurrentStep(newStep);
        }
    }


    return (
        <div className='p-4 sm:ml-64 min-h-screen'>
            <div className="flex flex-col items-start justify-between pb-6 space-y-4 border-b lg:items-center lg:space-y-0 lg:flex-row m-4 mt-8">
                <h1 className="text-2xl font-semibold whitespace-nowrap">Create Event</h1>
            </div>

            <div className=' md:max-w-[1166px] lg:max-w-[1166px] mx-auto my-2'>
                <div className="flex flex-row text-[#474B55] font-medium cursor-pointer hover:font-semibold " onClick={() => navigate(-1)}>
                    <BsArrowLeftShort size={25} />
                    BACK
                </div>
            </div>

            <div className='w-full md:max-w-[1166px] lg:max-w-[1166px] mx-auto pb-2 bg-white'>

                {/* stepper */}
                <div className='container horizontal pt-5'>
                    <Stepper
                        steps={steps}
                        currentStep={currentStep} />
                </div>

                {
                    !submitEventLoading ?

                        displayStep(currentStep) :
                        <Loader />
                    // <div className='vh-100 mt-16 mx-8'>
                    //     <div className="mt-10 sm:mt-10 xs:mt-10">
                    //         {/* <div className="md:grid md:grid-cols-3 md:gap-6"> */}
                    //         <div role="status" className='mx-auto w-[50%] align-middle justify-center flex flex-col items-center vh-100 h-96 min-h-screen'>
                    //             <svg aria-hidden="true" className="w-12 h-12 mx-auto text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    //                 <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                    //                 <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                    //             </svg>
                    //             {/* <span class="sr-only">Loading...</span> */}
                    //         </div>
                    //         {/* </div> */}
                    //     </div>
                    // </div>

                }


                {/* navigation controls */}
                {
                    currentStep !== steps.length &&

                    <div className="md:grid md:grid-cols-3 md:gap-6">
                        <div className='md:col-span-1'>

                        </div>
                        <div className='mt-5 md:col-span-2 md:mt-0'>
                            {/* <StepperControl
                                handleClick={handleClick}
                                currentStep={currentStep}
                                steps={steps}
                            /> */}
                        </div>
                    </div>
                }
            </div>
        </div >
    )
}

export default CreateEvent