import React from 'react'
import { Card, Text, Metric, Flex, ProgressBar } from "@tremor/react";

const CardMetric = ({ title, value, currency }) => {
    return (
        <Card className="mx-auto">
            <Text>{title}</Text>
            <Metric>{value} <span className='text-lg'>{currency}</span></Metric>
        </Card>
    )
}

export default CardMetric