import React, { useState, useEffect } from 'react';
import TimezoneSelect from 'react-timezone-select';

export default function TimezonePicker({ form, onChange }) {

    const [timezoneValue, setTimezoneValue] = useState(form['timezone'].value)


    const handleTimezoneChange = (timezone) => {
        setTimezoneValue(timezone)
        onChange(timezone.value);
    };

    return (
        <TimezoneSelect
            value={timezoneValue}
            onChange={handleTimezoneChange}
        />
    );
}
