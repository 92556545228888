import * as actionTypes from "../actions/actionTypes";

export const initialState = {
    orgOrderRefund: {},
    loading: false,
    error: ""
}

export default function organizerRefund(state = initialState, action) {
    switch (action.type) {
        case actionTypes.REQUEST_REFUND_BY_ORGANIZER: {
            return {
                ...state,
                orgOrderRefund: {},
                loading: true,
                error: ""
            };
        }
        case actionTypes.RESET_REFUND_BY_ORGANIZER: {
            return {
                ...state,
                orgOrderRefund: {},
                loading: false,
                error: ""
            };
        }
        case actionTypes.RECEIVE_REFUND_BY_ORGANIZER: {

            switch (action.payload.status) {
                case 'ERROR': {
                    return {
                        ...state,
                        orgOrderRefund: {},
                        loading: false,
                        error: action.payload.errorData
                    };
                }
                default: {
                    return {
                        ...state,
                        orgOrderRefund: action.payload.data.data,
                        loading: false,
                        error: ""
                    }
                }
            }
        }
        default:
            return state;
    }
}
