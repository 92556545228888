import * as actionTypes from "../actions/actionTypes";

export const initialState = {
    orgInvitation: {},
    loading: false,
    error: ""
}


export default function organizerAdminInvitation(state = initialState, action) {
    switch (action.type) {
        case actionTypes.REQUEST_ORGANIZER_ADMIN_INVITATION: {
            return {
                ...state,
                orgInvitation: {},
                loading: true,
                error: ""
            };
        }
        case actionTypes.RECEIVE_ORGANIZER_ADMIN_INVITATION: {
            switch (action.payload.status) {
                case 'ERROR': {
                    return {
                        ...state,
                        orgInvitation: {},
                        loading: false,
                        error: action.payload
                    };
                }
                default: {
                    return {
                        ...state,
                        orgInvitation: action.payload.data,
                        loading: false,
                        error: ""
                    }
                }
            }
        }
        default:
            return state;
    }
}
