import React, { useState, useEffect } from 'react'
import AnonymousReservation from './AnonymousReservation'
import TicketSelection from './TicketSelection'
import StripeCheckoutComponent from './StripeCheckoutComponent'
import StepperControl from '../StepperControl'
import { useAuth0 } from '@auth0/auth0-react';
import { validateRequiredText, validateRequiredFile, validateRequiredEmail, validateNone } from '../utils/validators';

import { requestPurchaseTickets, resetPurchaseTickets } from '../../store/actions/index';
import { useDispatch, useSelector } from 'react-redux';
import OrderSummary from './OrderSummary';
import Loader from '../Loader';
import Login from '../authentication/login';
import LoginForm from './LoginForm'

import axios from "axios";


const CheckoutModal = ({ offer, onClose }) => {
    const orderDetails = useSelector((state) => state.purchaseTickets.orderDetails);
    const clientSecretLoading = useSelector((state) => state.purchaseTickets.loading);
    const reserveTicketsError = useSelector((state) => state.purchaseTickets.error);

    const dispatch = useDispatch();

    const { isAuthenticated, user, loginWithRedirect, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [orderId, setOrderId] = useState(null);
    const [currentStep, setCurrentStep] = useState(1);
    const [expiresAt, setExpiresAt] = useState(null);
    const [showStepper, setShowStepper] = useState(true);
    const [timeRemaining, setTimeRemaining] = useState(null);
    const [isNextDisabled, setIsNextDisabled] = useState(true);
    const [ticketState, setTicketState] = useState({
        tickets: {
        },
        ticketHolderInfo: {
            firstName: {
                value: null,
                error: null
            },
            lastName: {
                value: null,
                error: null
            },
            email: {
                value: null,
                error: null
            },
            confirmEmail: {
                value: null,
                error: null
            }
        }
    })

    useEffect(() => {
        const convertedTickets = {};
        offer.tickets.forEach(ticket => {
            convertedTickets[ticket.ticket_id] = { ticket_id: ticket.ticket_id, title: ticket.title, quantity: 0, unit_price: ticket.price, max_per_person: ticket.max_per_person, min_per_person: ticket.min_per_Person, price: 0.00 };
        });

        setTicketState({ ...ticketState, tickets: convertedTickets, event_id: offer.id, subTotal: 0, totalProcessingFee: 0, totalServiceCharge: 0, total: 0, totalTicketCount: 0 });
    }, []);


    useEffect(() => {
        const interval = setInterval(() => {
            const currentEpochTime = Math.floor(Date.now() / 1000);
            const timeDifferenceInSeconds = Math.floor(expiresAt - currentEpochTime);

            if (timeDifferenceInSeconds > 0) {
                setTimeRemaining(timeDifferenceInSeconds)
            } else {
                // todo show time expired screens
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [expiresAt]);


    const getExpiresIn = () => {
        const minutes = Math.floor(timeRemaining / 60);
        const remainingSeconds = timeRemaining % 60;

        return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    };


    const displayStep = (step) => {
        switch (step) {
            case 1:
                return <TicketSelection tickets={offer.tickets} ticketState={ticketState} setTicketState={setTicketState} currentStep={currentStep} steps={steps} increaseStep={increaseStep} setIsNextDisabled={setIsNextDisabled} />
            // case 2:
            //     return <LoginForm />
            case 2:
                return <AnonymousReservation ticketState={ticketState} setTicketState={setTicketState} currentStep={currentStep} steps={steps} increaseStep={increaseStep} setIsNextDisabled={setIsNextDisabled} />
            case 3:
                // case 'payment':
                return <StripeCheckoutComponent clientSecret={orderDetails.clientSecret} increaseStep={increaseStep} handleErrorToastMessage={handleErrorToastMessage} />
            case 4:
            case 'summary':
                return <OrderSummary orderId={orderId} />
            default:
        }
    }


    const steps = [
        "Ticket Selection",
        "Ticker Holder Information",
        "Payment",
        "OrderSummary"
    ];


    const parseTickets = (tickets) => {
        const flatForm = []
        for (const [fieldName, fieldValue] of Object.entries(tickets)) {
            if (typeof fieldValue === "object") {
                const ff = {}
                for (const [subFieldName, subFieldValue] of Object.entries(fieldValue)) {
                    if (subFieldName == 'quantity' && subFieldValue > 0) {
                        ff['ticket_id'] = fieldValue.ticket_id
                        ff[subFieldName] = subFieldValue
                        flatForm.push(ff);
                    }
                }
            }
        }
        return flatForm
    }


    const trimForm = (form) => {
        const ticketArr = parseTickets(ticketState['tickets'])

        const res = {
            order: {
                event_id: ticketState['event_id'],
                firstName: ticketState.ticketHolderInfo['firstName'].value,
                lastName: ticketState.ticketHolderInfo['lastName'].value,
                email: ticketState.ticketHolderInfo['email'].value,
                tickets: ticketArr
            }
        }

        return res
    };


    // const submitForm = async () => {
    //     const accessToken = await getAccessTokenSilently();
    //     dispatch(requestPurchaseTickets(trimForm(ticketState), accessToken));
    // }

    // testing something out
    const submitOrderReservationForm = async () => {
        let accessToken = ""
        let tokenType = ""
        if (isAuthenticated) {
            accessToken = await getAccessTokenSilently();
            tokenType = "authenticated"
        } else {
            accessToken = await fetchGuestToken();
            tokenType = "unauthenticated"
        }
        console.log(accessToken);
        dispatch(requestPurchaseTickets(trimForm(ticketState), accessToken, tokenType));
    }

    const fetchGuestToken = async () => {

        try {

            const res = await axios.get(`https://staging-api.lymetickets.com/guest-token`, {
                headers: {
                    "Content-Type": "application/json",
                }
            });
            return res.data.data.token;
        } catch (err) {
            console.log("Error caught: ", err.message);
        }
    };

    const fetchUserLoggedInState = async () => {
        const accessToken = await getAccessTokenSilently();
        console.log(accessToken);
        return accessToken
    }


    // This entire function is actually pointless :s 
    const increaseStep = (direction) => {
        // let newStep = currentStep;

        // (direction == "next") ? newStep++ : newStep--;

        // console.log(newStep);
        // if (newStep == steps.length) {
        //     // submitForm()
        //     setCurrentStep(newStep);
        // } else if (newStep >= 1 && newStep <= steps.length) {
        //     if (newStep == 2) {
        //         console.log("is user authenticated ", isAuthenticated);
        //         // if (!isAuthenticated) {
        //         //     newStep--
        //         //     loginWithRedirect()
        //         // }
        //     }
        //     if (newStep == 3) {
        //         //validate form
        //         submitOrderReservationForm()
        //     } else {
        //         setCurrentStep(newStep);
        //         setIsNextDisabled(true)
        //     }
        // }

        let newStep = currentStep;

        if (direction === "next") {
            newStep++;
        } else {
            newStep--;
        }

        // Handle special cases based on step
        switch (newStep) {
            case 1:
                console.log("step 1 ");
                // if (!isAuthenticated) {
                //     // Redirect to login and don't increment step
                //     loginWithRedirect();
                //     return;
                // }
                break;
            case 2:
                console.log("step 2 ");
                // Validate form or perform any necessary actions
                // if (!validateForm()) {
                //     // Don't increment step if validation fails
                //     return;
                // }
                break;
            case 3:
                // Additional logic for step 3
                console.log("step 3 ");
                submitOrderReservationForm()
                break;
            case 4:
                setExpiresAt(null)
                setOrderId(orderDetails.order_id)
                setShowStepper(false)
            default:
                break;
        }

        setCurrentStep(newStep);
        setIsNextDisabled(newStep === steps.length - 1); // Disable next button on last step
    }

    const handleErrorToastMessage = (message) => {
        setToastMessage(message)
        handlDisplayError()
    }


    //this handles the point from the order is placed to the completion
    //there is essentially a fork between this and the increase step function
    useEffect(() => {

        if (!clientSecretLoading && orderDetails != undefined && Object.keys(orderDetails).length != 0) {

            if (orderDetails.current_step == 'payment') {
                setExpiresAt(orderDetails.exp)
                setCurrentStep(3)
                setOrderId(orderDetails.order_id)
                setShowStepper(false)
            }

            //this only captures if the user orders a free ticket
            //doesnt account for purchased tickets
            if (orderDetails.current_step == 'summary') {
                setExpiresAt(null)
                setCurrentStep(4)
                setOrderId(orderDetails.order_id)
                setShowStepper(false)
            }
        }


        if (reserveTicketsError != "") {
            setToastMessage(reserveTicketsError)
            handlDisplayError()
        }

    }, [orderDetails]);


    const closeModal = () => {
        dispatch(resetPurchaseTickets());
        onClose()
    }


    const handlDisplayError = () => {
        setShowToast(true);

        // Hide the toast after 3 seconds
        setTimeout(() => {
            setShowToast(false);
        }, 5000);
    };



    return (
        <div className="fixed inset-0 z-50 backdrop-blur-sm bg-gray-500 bg-opacity-75">
            <div className="flex items-center justify-center min-h-screen px-4">

                {/* <div className="fixed inset-0 transition-opacity">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div> */}



                {/* Modal content */}
                <div className="flex-col align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl w-full">
                    <div className='flex flex-auto overflow-hidden'>
                        <button onClick={closeModal} type="button" className="absolute border-none left-[96%] top-[1%] h-fit text-gray-400 bg-slate-100 hover:bg-gray-200 hover:text-gray-900 rounded-full text-sm p-1.5 z-30" data-modal-hide="extralarge-modal">
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            <span className="sr-only">Close modal</span>
                        </button>

                        {/* Left pane */}
                        <div className='flex-col sm:w-[70%] w-full'>
                            {/* Modal header */}
                            <div class="flex items-center justify-center p-5 border-b rounded-t">
                                <div className='flex flex-col items-center'>
                                    <h3 class="text-xl font-medium text-gray-900">
                                        {offer.title}
                                    </h3>
                                    {expiresAt != null ?
                                        <div className='flex items-center'>{getExpiresIn()}</div> :
                                        <></>
                                    }
                                </div>

                            </div>

                            {/* error taset message */}
                            {/* {showToast && <ToastMessage message="Toast message example" />} */}
                            {showToast &&
                                <div className='flex items-center justify-center bg-[#f9b260] text-slate-800 min-h-12 h-12 font-medium'>
                                    {toastMessage}
                                </div>
                            }

                            {/* Main Content */}
                            <div className='inline-flex w-full'>
                                {/* Left pane */}
                                <div className='w-full max-h-[512px] h-[512px] px-8 overflow-y-auto pt-1'>
                                    {
                                        !clientSecretLoading ?
                                            displayStep(currentStep) :
                                            < Loader />
                                    }
                                </div>
                            </div>

                            {/* Stepper footer */}
                            <StepperControl
                                handleClick={increaseStep}
                                currentStep={currentStep}
                                steps={steps}
                                nextText='Next'
                                isNextDisabled={isNextDisabled}
                                show={showStepper}
                            />
                        </div>


                        {/* Right pane */}
                        <div className="w-full bg-gray-100 md:w-[30%] flex-col hidden md:block ">
                            <img src={offer.img_url} alt='offer display' className='w-full object-cover h-36 md:max-w-md' />
                            <div className="space-y-2 p-4">

                                {
                                    ticketState.totalTicketCount > 0 ?
                                        <>
                                            <h2 className="text-lg font-semibold mb-4">Order Summary</h2>
                                            {Object.keys(ticketState.tickets).map((ticketId) => (
                                                ticketState.tickets[ticketId].quantity > 0 ?
                                                    <div className="flex justify-between text-sm" key={ticketId}>
                                                        <span>{ticketState.tickets[ticketId].quantity} x {ticketState.tickets[ticketId].title}</span>
                                                        <span>${ticketState.tickets[ticketId].price}</span>
                                                    </div>
                                                    : <></>
                                            ))}
                                            <hr className="my-2" />
                                            {
                                                ticketState.subTotal > 0 ?
                                                    <div className="flex justify-between text-sm my-auto">
                                                        <span>Subtotal</span>
                                                        <span>${ticketState.subTotal}</span>
                                                    </div> : <></>
                                            }
                                            {
                                                ticketState.totalProcessingFee > 0 ?
                                                    <div className="flex justify-between text-sm my-auto">
                                                        <span>Processing Fee</span>
                                                        <span>${ticketState.totalProcessingFee}</span>
                                                    </div> : <></>
                                            }
                                            {
                                                ticketState.totalServiceCharge > 0 ?
                                                    <div>
                                                        <div className="flex justify-between text-sm my-auto">
                                                            <span>Service Charge</span>
                                                            <span>${ticketState.totalServiceCharge}</span>
                                                        </div>
                                                        <hr className="my-2" />
                                                    </div>
                                                    : <></>
                                            }

                                            <div className="flex justify-between font-semibold">
                                                <span>Total</span>
                                                <span>${ticketState.total}</span>
                                            </div>
                                        </>

                                        : <> </>
                                }

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>


    )
}

export default CheckoutModal