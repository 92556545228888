import React, { useEffect, useState } from 'react'
import OrganizerSideNav from '../components/OrganizerSideNav'
import CardMetric from '../components/dashboard/CardMetric'
import { Select, SelectItem } from "@tremor/react";
import TicketSalesProgressBar from '../components/dashboard/TicketSalesProgressBar'
import Table2 from '../components/Table2'
import Table3 from '../components/Table3';
import RevenueAreaChart from '../components/dashboard/RevenueAreaChart'
import DailyTicketSales from '../components/dashboard/DailyTicketSales'
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import Loader from '../components/Loader'
import { Title } from "@tremor/react";
import ShareBanner from '../components/dashboard/ShareBanner';
import { getCurrencyFormat } from '../components/common/currencyUtils';


import { requestDashboardMetrics } from '../store/actions/index';


const Dashboard = () => {

    const latestPurchasesTableHeaders = ["Order Id", "Customer", "Qty", "Order Date"]
    const tableType = "latestPurchases"

    const organizationDetails = useSelector((state) => state.organizerDetails.orgDetails);
    const organizationDetailsLoading = useSelector((state) => state.organizerDetails.loading);

    const organizationEvents = useSelector((state) => state.organizerEvents.orgEvents);
    const organizationEventsLoading = useSelector((state) => state.organizerEvents.loading);

    const dashboardMetrics = useSelector((state) => state.dashboardMetrics.dashboardMetrics);
    const dashboardMetricsLoading = useSelector((state) => state.dashboardMetrics.loading);
    const selectedOrg = useSelector((state) => state.selectedOrganization.selectedOrg);


    const [eventId, setEventId] = useState("");


    const { user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
    const dispatch = useDispatch();


    useEffect(() => {
        if (organizationEvents.length !== 0) {
            // console.log("This is the org", organizationEvents[organizationEvents.length - 1].id);
            setEventId(organizationEvents[organizationEvents.length - 1].id);
        }
    }, [organizationEvents])


    useEffect(() => {
        const loadDashboard = async () => {

            if (eventId != "") {
                const accessToken = await getAccessTokenSilently();
                dispatch(requestDashboardMetrics(selectedOrg, eventId, accessToken));
            }
        }

        loadDashboard();
    }, [eventId])


    const convertDataToDailyTicketSales = (dataSet) => {
        return dataSet.map(item => {
            const day = new Date(item.day).toLocaleDateString("en-US", { weekday: "short" });
            const metricsObj = {
                "Day": day
            };
            item.metrics.forEach(metric => {
                metricsObj[metric.title] = metric.tickets_sold;
            });
            return metricsObj;
        });
    };

    const getUniqueTitlesForDailyTicketSales = (dataSet) => {
        const uniqueTitles = new Set();

        dataSet.forEach(item => {
            item.metrics.forEach(metric => {
                uniqueTitles.add(metric.title);
            });
        });

        return Array.from(uniqueTitles);
    };


    return (
        (dashboardMetricsLoading || organizationEventsLoading || organizationDetailsLoading || organizationDetails.length < 1 ? <Loader /> :

            <>
                <div class="sm:p-4 lg:ml-64 min-h-screen">

                    <div className="flex justify-between pb-6 space-y-4 border-b lg:space-y-0 lg:flex-row m-4 pt-8">
                        <div>
                            <h1 className="text-2xl font-semibold whitespace-nowrap">Dashboard</h1>
                        </div>
                        {
                            (organizationEvents.length !== 0) ?

                                <div className="!-mt-1 max-w-36 sm:max-w-72">
                                    <Select value={eventId} onValueChange={setEventId} >

                                        {organizationEvents.map((item, index) => (
                                            <SelectItem value={item.id} key={index}>
                                                {item.title}
                                            </SelectItem>
                                        ))}

                                    </Select>
                                </div> : <></>
                        }

                    </div>

                    <>
                        {
                            (organizationEvents.length !== 0) ?
                                <div className='m-4'>
                                    <ShareBanner event_id={eventId} />
                                </div> : <></>
                        }
                    </>

                    <div class="grid grid-cols-1 gap-5 mt-6 m-4 sm:grid-cols-2 lg:grid-cols-4">
                        <CardMetric
                            title="Gross Sales"
                            value={`${dashboardMetrics['total_ticket_revenue'] ? getCurrencyFormat(dashboardMetrics['total_ticket_revenue'], organizationDetails.default_currency.toUpperCase()) : '-'}`}
                            currency={`${dashboardMetrics['currency'] ? dashboardMetrics['currency'] : organizationDetails.default_currency.toUpperCase()}`}
                        />
                        <CardMetric
                            title="Net Sales"
                            value={`${dashboardMetrics['total_ticket_revenue'] ? getCurrencyFormat(dashboardMetrics['total_ticket_revenue'], organizationDetails.default_currency.toUpperCase()) : '-'}`}
                            currency={`${dashboardMetrics['currency'] ? dashboardMetrics['currency'] : organizationDetails.default_currency.toUpperCase()}`}
                        />
                        <CardMetric
                            title="Total Orders"
                            value={dashboardMetrics['num_of_orders'] ? dashboardMetrics['num_of_orders'] : '-'}
                        />
                        <CardMetric
                            title="Tickets Sold"
                            value={dashboardMetrics['num_of_tickets_sold'] ? dashboardMetrics['num_of_tickets_sold'] : '-'}
                        />
                    </div>


                    <div className='grid grid-cols-1 gap-5 lg:grid-cols-5 lg:gap-5 mt-6 m-4'>
                        <div className="col-span-3 overflow-hidden rounded-lg ring-1 ring-tremor-ring">
                            <RevenueAreaChart
                                dataSet={dashboardMetrics['daily_total_tracker'] ? dashboardMetrics['daily_total_tracker'] : []}
                                currency={organizationDetails.default_currency.toUpperCase()}
                            />
                        </div>
                        <div className="col-span-3 lg:col-span-2">
                            <DailyTicketSales
                                dataSet={convertDataToDailyTicketSales(dashboardMetrics['daily_breakdown'] ? dashboardMetrics['daily_breakdown'] : [])}
                                categories={getUniqueTitlesForDailyTicketSales(dashboardMetrics['daily_breakdown'] ? dashboardMetrics['daily_breakdown'] : [])}
                            />
                        </div>
                    </div>

                    <div className='grid grid-cols-1 lg:grid-cols-5 gap-5 mt-6 m-4'>
                        <div className="col-span-3 rounded-lg bg-white transition-all p-4 sm:p-10 ring-1 ring-tremor-ring ">
                            <Title>Latest Transactions</Title>
                            <Table3
                                tableType={tableType}
                                headers={latestPurchasesTableHeaders}
                                rows={dashboardMetrics['latest_orders'] ? dashboardMetrics['latest_orders'] : []} />
                        </div>
                        <div className="col-span-3 lg:col-span-2 ">
                            <TicketSalesProgressBar dataSet={dashboardMetrics['ticket_sales_breakdown'] ? dashboardMetrics['ticket_sales_breakdown'] : []}
                                eventName={dashboardMetrics['event_name'] ? dashboardMetrics['event_name'] : ""} />
                        </div>
                    </div>

                </div>
            </>
        )

    )
}

export default Dashboard