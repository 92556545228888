import React from 'react'
import { Link } from 'react-router-dom'

const Summary = ({ eventId, orgId }) => {
    return (
        <>
            <div className='vh-100 mt-16 mx-8'>
                <div className='flex flex-col w-[480px] left-0 top-0 m-auto mb-16'>
                    <div className='mx-auto mt-24 mb-8 rounded-full transition duration-500 ease-in-out h-16 w-16 flex items-center justify-center py-3 bg-[#529535] text-white font-bold border border-[#529535]'>
                        <span className='bg-[#529535] text-white font-bold border border-[#529535] text-3xl'>
                            &#10003;
                        </span>
                    </div>
                    <div>
                        <p className='font-bold text-[#003f6b] text-center'>
                            You have successfully submitted your event.
                        </p>
                        {/* <p className='text-center font-semibold'>{eventId}</p> */}
                        <p className='font-semibold text-[#474b55] text-center'>
                            Our team will get back to you within 24-48 hrs.
                            <br />
                            In that time we encourage you to preview your event
                        </p>
                        <div className="mt-10 mx-auto flex items-center gap-x-6 justify-center">
                            <Link to={`/organizer/${orgId}/events`} className="bg-[#529535] rounded-md bg-brand-600 px-6 py-1.5 text-base font-semibold leading-7 text-white shadow-sm hover:bg-brand-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600">My Events</Link>
                            <Link to={`/events/${eventId}/preview`} target='_blank' className=" text-base font-semibold leading-7 text-gray-300">Preview Event</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Summary