import React, { useState, useEffect } from 'react'
import SecurityForm from '../components/personalSettings/SecurityForm';
import PersonalForm from '../components/personalSettings/PersonalForm';
import banner from '../images/profile banner discord.jpg'
import mock_user_profile from '../images/mock_profile_user.avif'

const MyInformation = () => {

    const [selectedTab, setSelectedTab] = useState('profileInformation'); // Default tab is 'Users'

    const handleTabClick = (tabName) => {
        setSelectedTab(tabName);
    };

    return (
        <div class="max-w-5xl mx-auto bg-white p-6">

            <div className='w-full'>
                <img src={banner} alt="banner background" className='w-full object-cover h-52 rounded-xl' />
            </div>

            <div className='flex flex-row'>
                <div className='-mt-12 w-full grid grid-cols-10 gap-x-5'>
                    <img src={mock_user_profile} alt="" className='h-32 ml-12 border-[8px] border-white rounded-xl col-span-2' />

                    <div className='col-span-7 mt-14'>
                        <div className='flex flex-col'>
                            <span className='text-slate-800 font-semibold text-2xl'>Trevaughn Daley</span>
                            <span className='text-slate-800 text-sm'>trevaughn13@gmail.com</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="m-4 text-sm font-medium text-center text-gray-500 border-b border-gray-200">
                <ul class="flex flex-wrap -mb-px">
                    <li class="mr-2">
                        <a
                            href="#"
                            className={`inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 ${selectedTab === 'profileInformation' ? 'text-lime-600 hover:text-lime-600 !border-lime-600' : ''}`}
                            onClick={() => handleTabClick('profileInformation')}
                        >
                            Profile Information
                        </a>
                    </li>
                    <li class="mr-2">
                        <a
                            href="#"
                            className={`inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 ${selectedTab === 'profileSecurity' ? 'text-lime-600 hover:text-lime-600 !border-lime-600' : ''}`}
                            onClick={() => handleTabClick('profileSecurity')}
                        >
                            Profile Security
                        </a>
                    </li>
                </ul>
            </div>


            {
                selectedTab == 'profileInformation' ?
                    <PersonalForm /> :
                    <SecurityForm />
            }
        </div >
    )
}

export default MyInformation