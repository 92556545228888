import React, { useEffect, useState } from 'react'
import Table2 from '../components/Table2';
import Table3 from '../components/Table3';
import Loader from '../components/Loader';
import { BsPersonFillAdd } from 'react-icons/bs';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { requestOrganizationAdminUsers } from '../store/actions/index';
import { requestOrganizationAdminRoles } from '../store/actions/index';

import InviteUserModal from '../components/InviteUserModal';



const OrgSettings = () => {


    const { user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
    const [loading, setLoading] = useState(true);
    const [showInviteUserModal, setShowInviteUserModal] = useState(false);
    const dispatch = useDispatch();

    const organizationAdminUsers = useSelector((state) => state.organizerAdminUsers.orgUsers);
    const organizationAdminUsersLoading = useSelector((state) => state.organizerAdminUsers.loading);

    const organizationAdminRoles = useSelector((state) => state.organizerAdminRoles.orgRoles);
    const organizationAdminRolesLoading = useSelector((state) => state.organizerAdminRoles.loading);

    const organizationDetails = useSelector((state) => state.organizerDetails.orgDetails);



    const orgUsersTableHeaders = ["Email", "role", "Status", "Actions"]
    const orgUsersTableType = "orgUsers"
    const orgRolesTableHeaders = ["Name", "Description", "Users"]
    const orgRolesTableType = "orgRoles"

    const [selectedTab, setSelectedTab] = useState('Users'); // Default tab is 'Users'

    const handleTabClick = (tabName) => {
        setSelectedTab(tabName);
    };

    useEffect(() => {
        const loadOrgUsers = async () => {
            if (loading && organizationDetails && organizationDetails.org_id) {
                const accessToken = await getAccessTokenSilently();
                dispatch(requestOrganizationAdminUsers(organizationDetails.org_id, accessToken))
                dispatch(requestOrganizationAdminRoles(organizationDetails.org_id, accessToken))
                setLoading(false)
            }
        }

        loadOrgUsers();
    }, [loading, getAccessTokenSilently, dispatch, organizationDetails])


    const closeAndResetInvitationModal = () => {
        setShowInviteUserModal(false)
        setLoading(true)
    }


    return (
        <div class="p-4 lg:ml-64 min-h-screen">
            <div className="flex flex-col items-start justify-between pb-6 space-y-4 lg:items-center lg:space-y-0 lg:flex-row m-4 mt-8">
                <h1 className="text-2xl font-semibold whitespace-nowrap">Settings</h1>
            </div>

            <div class="m-4 text-sm font-medium text-center text-gray-500 border-b border-gray-200 ">
                <ul class="flex flex-wrap -mb-px">
                    <li class="mr-2">
                        <a
                            href="#"
                            className={`inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 ${selectedTab === 'Users' ? 'text-lime-600 hover:text-lime-600 !border-lime-600' : ''}`}
                            onClick={() => handleTabClick('Users')}
                        >
                            Users
                        </a>
                    </li>
                    <li class="mr-2">
                        <a
                            href="#"
                            className={`inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 ${selectedTab === 'Roles' ? 'text-lime-600 hover:text-lime-600 !border-lime-600' : ''}`}
                            onClick={() => handleTabClick('Roles')}
                        >
                            Roles
                        </a>
                    </li>
                </ul>
            </div>

            {selectedTab === 'Users' ? (
                <>
                    {!loading && !organizationAdminUsersLoading ? (
                        <>
                            <div>
                                <div className="flex justify-between pb-6 items-center space-y-0 flex-row m-4 mt-10">
                                    <h1 className="text-xl font-semibold whitespace-nowrap">Users</h1>
                                    <div className="w-44">
                                        <button
                                            className='flex bg-lime-500 justify-center w-full py-2 rounded-sm text-white hover:bg-lime-600 hover:cursor-pointer border-none text-sm font-semibold'
                                            onClick={() => setShowInviteUserModal(true)}
                                        >
                                            <BsPersonFillAdd size={18} className='mr-1' />
                                            Invite new user
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {organizationAdminUsers.length > 0 && (
                                <div className="flex w-full rounded-lg bg-white transition-all p-4 sm:p-10 ring-1 ring-tremor-ring">
                                    {/* <Table2
                                        tableType={orgUsersTableType}
                                        headers={orgUsersTableHeaders}
                                        rows={organizationAdminUsers ? organizationAdminUsers : []}
                                    /> */}
                                    <Table3
                                        tableType={orgUsersTableType}
                                        headers={orgUsersTableHeaders}
                                        rows={organizationAdminUsers ? organizationAdminUsers : []}
                                    />
                                </div>
                            )}


                            {
                                showInviteUserModal &&
                                <InviteUserModal
                                    onClose={() => closeAndResetInvitationModal()}
                                />
                            }
                        </>



                    ) : null}


                </>
            ) : (
                <>
                    {!loading && !organizationAdminRolesLoading ? (
                        <div>
                            <div className="flex flex-col items-start justify-between pb-6 space-y-4 lg:items-center lg:space-y-0 lg:flex-row m-4 mt-10">
                                <h1 className="text-xl font-semibold whitespace-nowrap">Roles</h1>
                            </div>
                        </div>
                    ) : null}

                    {organizationAdminRoles.length > 0 && (
                        <div className="flex w-full rounded-lg bg-white transition-all p-4 sm:p-10 ring-1 ring-tremor-ring">
                            {/* <Table2
                                tableType={orgRolesTableType}
                                headers={orgRolesTableHeaders}
                                rows={organizationAdminRoles ? organizationAdminRoles : []}
                            /> */}
                            <Table3
                                tableType={orgRolesTableType}
                                headers={orgRolesTableHeaders}
                                rows={organizationAdminRoles ? organizationAdminRoles : []}
                            />
                        </div>
                    )}
                </>
            )}

        </div>

    )
}

export default OrgSettings