import React from 'react'
import { Link } from 'react-router-dom';
import { BsEyeFill, BsShare, BsInfoCircle } from 'react-icons/bs';

const EventPreviewRectangleCard = (offer) => {
    // const title = offer.attributes.title;
    // const shortDescription = offer.attributes.shortDescription;
    // const brandName = offer.attributes.brand.data.attributes.brandName
    // const brandAvatar = 'http://localhost:1337' + offer.attributes.cover.data.attributes.formats.thumbnail.url;

    const id = offer.id;
    const title = offer.title;
    const shortDescription = offer.short_description;
    const brandName = offer.organizer.name;
    const brandAvatar = offer.img_url;
    const isVerified = offer.organizer.is_verified;

    const handleShareClick = async (e) => {
        // e.preventDefault();
        try {
            // console.log("boop boop boop");
            const domain = window.location.origin;
            await navigator.share({
                text: 'Check out this event on Lymetickets.com',
                url: `${domain}/events/${id}`,
            });
        } catch (error) {
            console.error('Error sharing:', error);
        }
    };

    return (
        (
            <div className='event-preview'>
                {/* <Link to="/offers"> */}
                {/* <div className='bg-[#eaeaea] h-[1px]'></div> */}
                <div className='flex w-full p-8 relative hover:drop-shadow-xl hover:cursor-pointer hover:z-10 hover:bg-white border-t-[1px] hover:border-t-0'>
                    {/* <hr class="h-px -mt-8 bg-gray-200 border-0 " /> */}

                    <div className='flex flex-row'>
                        <div className='flex h-40 min-h-[10rem] w-40 min-w-[13rem] order-1 items-center'>
                            <img className='p-1 h-40 w-64 border-[#e6e8ed] border-[1px] bg-white text-black rounded-md object-cover' src={brandAvatar} />
                        </div>

                        <div className='flex flex-col w-full order-2 ml-4'>

                            {/* View Section */}
                            <div className='h-6 flex items-start mb-2'>
                                <BsEyeFill size={16} className="text-[#757575] opacity-30" />
                                <span className='pl-1 text-[13px] text-[#757575] opacity-70 px-2 w-10 items-start'>24k</span>
                                <span className='w-[1px] bg-[#e6e8ed] mx-3 h-5'></span>
                                {/* <span className='py-[3px] px-[8px] text-center text-[10px] font-bold rounded-full bg-white shadow-md tracking-[.8px]'>EXCLUSIVE</span> */}
                            </div>

                            {/* Title Section */}
                            <div className='flex flex-col'>
                                <div className='text-[15px] tracking-normal leading-4 md:text-[20px] md:leading-5 font-bold text-slate-800'>{title}</div>
                                <div className='font-normal mt-[3px] text-[14px] tracking-normal text-[#757575] flex-wrap items-center] flex'>
                                    {brandName}
                                    {
                                        (
                                            (isVerified) ?
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 fill-blue-500">
                                                    <path fillRule="evenodd" d="M16.403 12.652a3 3 0 000-5.304 3 3 0 00-3.75-3.751 3 3 0 00-5.305 0 3 3 0 00-3.751 3.75 3 3 0 000 5.305 3 3 0 003.75 3.751 3 3 0 005.305 0 3 3 0 003.751-3.75zm-2.546-4.46a.75.75 0 00-1.214-.883l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" />
                                                </svg>
                                                : ''
                                        )
                                    }

                                </div>
                            </div>

                            <div className='hidden md:block text-[14px] py-[10px] text-[#212529] overflow-y-hidden max-h-20'>
                                {shortDescription}
                            </div>

                            <div className='flex space-x-4 mt-4'>
                                <BsInfoCircle size={17} className="text-[#0062AB]" />
                                <BsShare size={17} className="text-[#0062AB] z-10" onClick={handleShareClick} />
                            </div>

                        </div>
                    </div>
                </div>
                {/* </Link> */}
            </div>
        )
    )
}

export default EventPreviewRectangleCard