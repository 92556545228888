import * as actionTypes from "./actionTypes";


export const requestOfferDetails = (data) => ({
    type: actionTypes.REQUEST_OFFER_DETAILS,
    payload: data
});

export const receiveOfferDetails = (data) => ({
    type: actionTypes.RECEIVE_OFFER_DETAILS,
    payload: {
        status: "SUCCESS",
        data
    },
});

export const errorOfferDetails = (error) => ({
    type: actionTypes.RECEIVE_OFFER_DETAILS,
    payload: {
        status: "ERROR",
        errorData: error
    },
});





export const requestAllOffers = (data) => ({
    type: actionTypes.REQUEST_ALL_OFFERS,
    payload: data
});

export const receiveAllOffers = (data) => ({
    type: actionTypes.RECEIVE_ALL_OFFERS,
    payload: {
        status: "SUCCESS",
        data
    },
});

export const errorAllOffers = (error) => ({
    type: actionTypes.RECEIVE_ALL_OFFERS,
    payload: {
        status: "ERROR",
        errorData: error
    },
});





export const requestOffersByCategory = (data) => ({
    type: actionTypes.REQUEST_OFFERS_BY_CATEGORY,
    payload: data
});

export const receiveOffersByCategory = (data) => ({
    type: actionTypes.RECEIVE_OFFERS_BY_CATEGORY,
    payload: {
        status: "SUCCESS",
        data
    },
});

export const errorOffersByCategory = (error) => ({
    type: actionTypes.RECEIVE_OFFERS_BY_CATEGORY,
    payload: {
        status: "ERROR",
        errorData: error
    },
});



export const requestSubmissionOfEvent = (data, jwt) => ({
    type: actionTypes.REQUEST_SUBMISSION_OF_EVENT,
    payload: data,
    jwt: jwt,
});

export const receiveSubmissionOfEvent = (data) => ({
    type: actionTypes.RECEIVE_SUBMISSION_OF_EVENT,
    payload: {
        status: "SUCCESS",
        data
    },
});

export const errorSubmissionOfEvent = (error) => ({
    type: actionTypes.RECEIVE_SUBMISSION_OF_EVENT,
    payload: {
        status: "ERROR",
        errorData: error
    },
});


export const requestAlterationOfEvent = (data, orgId, eventId, jwt) => ({
    type: actionTypes.REQUEST_ALTERATION_OF_EVENT,
    payload: data,
    orgId: orgId,
    eventId: eventId,
    jwt: jwt,
});

export const receiveAlterationOfEvent = (data) => ({
    type: actionTypes.RECEIVE_ALTERATION_OF_EVENT,
    payload: {
        status: "SUCCESS",
        data
    },
});

export const errorAlterationOfEvent = (error) => ({
    type: actionTypes.RECEIVE_ALTERATION_OF_EVENT,
    payload: {
        status: "ERROR",
        errorData: error
    },
});

export const resetAlterationOfEvent = () => ({
    type: actionTypes.RESET_ALTERATION_OF_EVENT,
});


export const requestUserOrganizaions = (jwt) => ({
    type: actionTypes.REQUEST_USER_ORG,
    payload: jwt,
    jwt: jwt,

});

export const receiveUserOrganizaions = (data) => ({
    type: actionTypes.RECEIVE_USER_ORG,
    payload: {
        status: "SUCCESS",
        data
    },
});

export const errorUserOrganizaions = (error) => ({
    type: actionTypes.RECEIVE_USER_ORG,
    payload: {
        status: "ERROR",
        errorData: error
    },
});


export const requestPurchaseTickets = (data, jwt, tokenType) => ({
    type: actionTypes.REQUEST_PURCHASE_OF_TICKETS,
    payload: data,
    jwt: jwt,
    tokenType: tokenType,
});

export const receivePurchaseTickets = (data) => ({
    type: actionTypes.RECEIVE_PURCHASE_OF_TICKETS,
    payload: {
        status: "SUCCESS",
        data
    },
});

export const errorPurchaseTickets = (error) => ({
    type: actionTypes.RECEIVE_PURCHASE_OF_TICKETS,
    payload: {
        status: "ERROR",
        errorData: error
    },
});

export const resetPurchaseTickets = () => ({
    type: actionTypes.RESET_PURCHASE_OF_TICKETS,
});




export const requestMyOrders = (jwt) => ({
    type: actionTypes.REQUEST_MY_ORDERS,
    jwt: jwt,
});

export const receiveMyOrders = (data) => ({
    type: actionTypes.RECEIVE_MY_ORDERS,
    payload: {
        status: "SUCCESS",
        data
    },
});

export const errorMyOrders = (error) => ({
    type: actionTypes.RECEIVE_MY_ORDERS,
    payload: {
        status: "ERROR",
        errorData: error
    },
});




export const requestOrderDetails = (data, jwt) => ({
    type: actionTypes.REQUEST_ORDER_DETAILS,
    payload: data,
    jwt: jwt,
});

export const receiveOrderDetails = (data) => ({
    type: actionTypes.RECEIVE_ORDER_DETAILS,
    payload: {
        status: "SUCCESS",
        data
    },
});

export const errorOrderDetails = (error) => ({
    type: actionTypes.RECEIVE_ORDER_DETAILS,
    payload: {
        status: "ERROR",
        errorData: error
    },
});


export const requestOrganizerDetails = (data, jwt) => ({
    type: actionTypes.REQUEST_ORGANIZER_DETAILS,
    payload: data,
    jwt: jwt,
});

export const receiveOrganizerDetails = (data) => ({
    type: actionTypes.RECEIVE_ORGANIZER_DETAILS,
    payload: {
        status: "SUCCESS",
        data
    },
});

export const errorOrganizerDetails = (error) => ({
    type: actionTypes.RECEIVE_ORGANIZER_DETAILS,
    payload: {
        status: "ERROR",
        errorData: error
    },
});




export const requestOrganizerPaymentSetup = (data, jwt) => ({
    type: actionTypes.REQUEST_ORGANIZER_PAYMENT_SETUP,
    payload: data,
    jwt: jwt,
});

export const receiveOrganizerPaymentSetup = (data) => ({
    type: actionTypes.RECEIVE_ORGANIZER_PAYMENT_SETUP,
    payload: {
        status: "SUCCESS",
        data
    },
});

export const errorOrganizerPaymentSetup = (error) => ({
    type: actionTypes.RECEIVE_ORGANIZER_PAYMENT_SETUP,
    payload: {
        status: "ERROR",
        errorData: error
    },
});



// SET_SELECTED_ORGANIZER
export const setSelectedOrganization = (data) => ({
    type: actionTypes.SET_SELECTED_ORGANIZER,
    payload: data
});

export const receiveSelectedOrganization = (data) => ({
    type: actionTypes.RECEIVE_SELECTED_ORGANIZER,
    payload: {
        status: "SUCCESS",
        data
    },
});



export const requestOrganizerPaymentDetails = (data, jwt) => ({
    type: actionTypes.REQUEST_ORGANIZER_PAYMENT_DETAILS,
    payload: data,
    jwt: jwt,
});

export const receiveOrganizerPaymentDetails = (data) => ({
    type: actionTypes.RECEIVE_ORGANIZER_PAYMENT_DETAILS,
    payload: {
        status: "SUCCESS",
        data
    },
});

export const errorOrganizerPaymentDetails = (error) => ({
    type: actionTypes.RECEIVE_ORGANIZER_PAYMENT_DETAILS,
    payload: {
        status: "ERROR",
        errorData: error
    },
});




export const requestTicketValidation = (orgId, data, jwt) => ({
    type: actionTypes.REQUEST_TICKET_VALIDATION,
    orgId: orgId,
    payload: data,
    jwt: jwt,
});

export const receiveTicketValidation = (data) => ({
    type: actionTypes.RECEIVE_TICKET_VALIDATION,
    payload: {
        status: "SUCCESS",
        data
    },
});

export const errorTicketValidation = (error) => ({
    type: actionTypes.RECEIVE_TICKET_VALIDATION,
    payload: {
        status: "ERROR",
        errorData: error
    },
});



export const requestDashboardMetrics = (orgId, eventId, jwt) => ({
    type: actionTypes.REQUEST_ORGANIZER_DASHBOARD_METRICS,
    orgId: orgId,
    eventId: eventId,
    jwt: jwt,
});

export const receiveDashboardMetrics = (data) => ({
    type: actionTypes.RECEIVE_ORGANIZER_DASHBOARD_METRICS,
    payload: {
        status: "SUCCESS",
        data
    },
});

export const errorDashboardMetrics = (error) => ({
    type: actionTypes.RECEIVE_ORGANIZER_DASHBOARD_METRICS,
    payload: {
        status: "ERROR",
        errorData: error
    },
});

export const resetDashboardMetrics = () => ({
    type: actionTypes.RESET_ORGANIZER_DASHBOARD_METRICS,
});



export const requestOrganizerEvents = (orgId, jwt) => ({
    type: actionTypes.REQUEST_ORGANIZER_EVENTS,
    orgId: orgId,
    jwt: jwt,
});

export const receiveOrganizerEvents = (data) => ({
    type: actionTypes.RECEIVE_ORGANIZER_EVENTS,
    payload: {
        status: "SUCCESS",
        data
    },
});

export const errorOrganizerEvents = (error) => ({
    type: actionTypes.RECEIVE_ORGANIZER_EVENTS,
    payload: {
        status: "ERROR",
        errorData: error
    },
});



export const requestOrganizerOrders = (orgId, jwt) => ({
    type: actionTypes.REQUEST_ORGANIZER_ORDERS,
    orgId: orgId,
    jwt: jwt,
});

export const receiveOrganizerOrders = (data) => ({
    type: actionTypes.RECEIVE_ORGANIZER_ORDERS,
    payload: {
        status: "SUCCESS",
        data
    },
});

export const errorOrganizerOrders = (error) => ({
    type: actionTypes.RECEIVE_ORGANIZER_ORDERS,
    payload: {
        status: "ERROR",
        errorData: error
    },
});



export const requestOrganizerRefundOrder = (data, jwt) => ({
    type: actionTypes.REQUEST_REFUND_BY_ORGANIZER,
    // order_id: order_id,
    payload: data,
    jwt: jwt,
});

export const receiveOrganizerRefundOrder = (data) => ({
    type: actionTypes.RECEIVE_REFUND_BY_ORGANIZER,
    payload: {
        status: "SUCCESS",
        data
    },
});

export const errorOrganizerRefundOrder = (error) => ({
    type: actionTypes.RECEIVE_REFUND_BY_ORGANIZER,
    payload: {
        status: "ERROR",
        errorData: error
    },
});

export const resetOrganizerRefundOrder = () => ({
    type: actionTypes.RESET_REFUND_BY_ORGANIZER,
});


export const requestCreateOrganization = (data, jwt) => ({
    type: actionTypes.REQUEST_CREATE_ORGANIZATION,
    payload: data,
    jwt: jwt,
});

export const receiveCreateOrganization = (data) => ({
    type: actionTypes.RECEIVE_CREATE_ORGANIZATION,
    payload: {
        status: "SUCCESS",
        data
    },
});

export const errorCreateOrganization = (error) => ({
    type: actionTypes.RECEIVE_CREATE_ORGANIZATION,
    payload: {
        status: "ERROR",
        errorData: error
    },
});


export const resetCreateOrganization = () => ({
    type: actionTypes.RESET_CREATE_ORGANIZATION,
});







export const requestOrganizationAdminUsers = (orgId, jwt) => ({
    type: actionTypes.REQUEST_ORGANIZER_ADMIN_USERS,
    orgId: orgId,
    jwt: jwt,
});

export const receiveOrganizationAdminUsers = (data) => ({
    type: actionTypes.RECEIVE_ORGANIZER_ADMIN_USERS,
    payload: {
        status: "SUCCESS",
        data
    },
});

export const errorOrganizationAdminUsers = (error) => ({
    type: actionTypes.RECEIVE_ORGANIZER_ADMIN_USERS,
    payload: {
        status: "ERROR",
        errorData: error
    },
});




export const requestOrganizationAdminRoles = (orgId, jwt) => ({
    type: actionTypes.REQUEST_ORGANIZER_ADMIN_ROLES,
    orgId: orgId,
    jwt: jwt,
});

export const receiveOrganizationAdminRoles = (data) => ({
    type: actionTypes.RECEIVE_ORGANIZER_ADMIN_ROLES,
    payload: {
        status: "SUCCESS",
        data
    },
});

export const errorOrganizationAdminRoles = (error) => ({
    type: actionTypes.RECEIVE_ORGANIZER_ADMIN_ROLES,
    payload: {
        status: "ERROR",
        errorData: error
    },
});



export const requestOrganizationAdminInvitation = (orgId, jwt, data) => ({
    type: actionTypes.REQUEST_ORGANIZER_ADMIN_INVITATION,
    orgId: orgId,
    payload: data,
    jwt: jwt,
});

export const receiveOrganizationAdminInvitation = (data) => ({
    type: actionTypes.RECEIVE_ORGANIZER_ADMIN_INVITATION,
    payload: {
        status: "SUCCESS",
        data
    },
});

export const errorOrganizationAdminInvitation = (error) => ({
    type: actionTypes.RECEIVE_ORGANIZER_ADMIN_INVITATION,
    payload: {
        status: "ERROR",
        errorData: error
    },
});



export const requestOrganizerResendTickets = (data, jwt) => ({
    type: actionTypes.REQUEST_RESEND_TICKETS_BY_ORGANIZER,
    payload: data,
    jwt: jwt,
});

export const receiveOrganizerResendTickets = (data) => ({
    type: actionTypes.RECEIVE_RESEND_TICKETS_BY_ORGANIZER,
    payload: {
        status: "SUCCESS",
        data
    },
});

export const errorOrganizerResendTickets = (error) => ({
    type: actionTypes.RECEIVE_RESEND_TICKETS_BY_ORGANIZER,
    payload: {
        status: "ERROR",
        errorData: error
    },
});

export const resetOrganizerResendTickets = () => ({
    type: actionTypes.RESET_RESEND_TICKETS_BY_ORGANIZER,
});


export const requestOrganizerDuplicateEvent = (data, jwt) => ({
    type: actionTypes.REQUEST_ORGANIZER_DUPLICATE_EVENT,
    payload: data,
    jwt: jwt,
});

export const receiveOrganizerDuplicateEvent = (data) => ({
    type: actionTypes.RECEIVE_ORGANIZER_DUPLICATE_EVENT,
    payload: {
        status: "SUCCESS",
        data
    },
});

export const errorOrganizerDuplicateEvent = (error) => ({
    type: actionTypes.RECEIVE_ORGANIZER_DUPLICATE_EVENT,
    payload: {
        status: "ERROR",
        errorData: error
    },
});

export const resetOrganizerDuplicateEvent = () => ({
    type: actionTypes.RESET_ORGANIZER_DUPLICATE_EVENT,
});