import React from 'react'

const securityForm = () => {
    return (
        <div>
            <div className="max-w-5xl mx-auto bg-white p-6 ">
                <div className="px-4 sm:px-0">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">Security Information</h3>
                    <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Account login credentials and activity</p>
                </div>
                <div className="mt-6 border-t border-gray-100">
                    <dl className="divide-y divide-gray-100">
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Current password</dt>
                            <div className="mt-2">
                                <input type="password" name="current-password" id="current-password" placeholder='current password' className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6" />
                            </div>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">New password</dt>
                            <div className="mt-2">
                                <input type="password" name="new-password" id="new-password" placeholder='new password' className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6" />
                            </div>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Confirm new password</dt>
                            <div className="mt-2">
                                <input type="password" name="confirm-new-password" id="confirm-new-password" placeholder='confirm new password' className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6" />
                            </div>
                        </div>
                    </dl>

                    <div className="flex space-x-1 justify-end">
                        <button
                            className="bg-white border border-gray-300 py-1 px-4 hover:bg-gray-100 font-normal text-sm rounded-md">
                            cancel
                        </button>
                        <button
                            className="bg-lime-500 text-white py-1 px-4 border-none hover:bg-lime-600 font-normal text-sm rounded-md">
                            update password
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default securityForm