import React, { useState } from 'react';
import StepperControl from '../StepperControl';
import { validateRequiredText, validateOptionalText, validateNone } from '../utils/validators';



const VenueInfo = ({ formState, setFormState, currentStep, steps, increaseStep, editMode }) => {

    const onUpdateField = e => {
        const name = e.target.name;
        const value = e.target.value;

        setFormState({ ...formState, venueInfo: { ...formState.venueInfo, [name]: { ...formState.venueInfo[name], value: value, error: null } } })
    };

    const handleBlur = e => {
        const name = e.target.name;
        const value = e.target.value;
        if (!formState.venueInfo[name].value) return setFormState({ ...formState, venueInfo: { ...formState.venueInfo, [name]: { ...formState.venueInfo[name], value: value, error: "Required!" } } })
    };

    const validateField = (field, value) => {
        switch (field) {
            case "venue":
                return validateRequiredText(value);
            case "addressLine1":
                return validateRequiredText(value);
            case "addressLine2":
                return validateRequiredText(value);
            case "city":
                return validateRequiredText(value);
            case "state":
                return validateRequiredText(value);
            case "postalCode":
                return validateRequiredText(value);
            case "country":
                return validateRequiredText(value);
            default:
                return validateNone();
        }
    }

    const handleSubmit = (e) => {
        if (e == "prev") {
            increaseStep("prev");
        } else {
            let hasError = false;
            const updatedForm = { ...formState.venueInfo };
            for (const [name, field] of Object.entries(updatedForm)) {
                const error = validateField(name, field.value);
                if (error.msg !== null) {
                    hasError = true;
                    updatedForm[name] = { ...field, error: error.msg };
                }
            }
            if (hasError) {
                setFormState({ ...formState, venueInfo: updatedForm })
            } else {
                // setFormState({ ...formState, venueInfo: updatedForm })
                increaseStep("next");
            }
        }
    };

    return (
        <div
            // className='vh-100 mt-16 flex mx-auto justify-center'
            className='flex min-h-full h-full mt-16 mx-auto justify-center w-full'
        >
            <div
                // className="mt-10 sm:mt-0 w-[832px] max-w-[832px]"
                className={` mt-10 sm:mt-0  ${!editMode ? '' : 'w-[832px] max-w-[832px]'}`}
            >
                <div className="md:grid md:grid-cols-3 md:gap-6">

                    {
                        !editMode &&
                        <div className="md:col-span-1">
                            <div className="px-4 sm:px-0">
                                <h3 className="text-base font-semibold leading-6 text-gray-900">Venue Location</h3>
                                <p className="mt-1 text-sm text-gray-600">Use a permanent address where you can receive mail.</p>
                            </div>
                        </div>
                    }


                    <div
                        // className="mt-5 md:col-span-2 md:mt-0"
                        className={` ${!editMode ? 'mt-5 md:col-span-2 md:mt-0' : 'col-span-3 w-full'}`}
                    >
                        {/* <form action="#" method="POST"> */}
                        <div className="overflow-hidden ">
                            <div className="bg-white px-4 py-5 sm:p-6">
                                <div className="grid grid-cols-6 gap-6">


                                    <div className="col-span-6 sm:col-span-4">
                                        <label htmlFor="venue" className="block text-sm font-medium leading-6 text-gray-900">
                                            Venue Name
                                        </label>
                                        <input
                                            type="text"
                                            name="venue"
                                            id="venue"
                                            autoComplete="venue"
                                            className={`px-4 h-[47px] mt-2 block w-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${formState.venueInfo.venue.error !== null ? 'border border-red-700' : ''}`}
                                            // className={`px-4 h-[47px] mt-2 block w-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${formState.eventInfo.eventName.error !== null ? 'border border-red-700' : ''}`}
                                            onChange={onUpdateField}
                                            onBlur={(e) => handleBlur(e)}
                                            value={formState.venueInfo.venue.value}
                                        />
                                        <span className="text-xs text-red-700">{formState.venueInfo.venue.error}</span>
                                    </div>

                                    <div className="col-span-6">
                                        <label htmlFor="addressLine1" className="block text-sm font-medium leading-6 text-gray-900">
                                            Address Line 1
                                        </label>
                                        <input
                                            type="text"
                                            name="addressLine1"
                                            id="addressLine1"
                                            autoComplete="address-Line1"
                                            className={`px-4 h-[47px] mt-2 block w-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${formState.venueInfo.addressLine1.error !== null ? 'border border-red-700' : ''}`}
                                            onChange={onUpdateField}
                                            onBlur={(e) => handleBlur(e)}
                                            value={formState.venueInfo.addressLine1.value}
                                        />
                                        <span className="text-xs text-red-700">{formState.venueInfo.addressLine1.error}</span>
                                    </div>

                                    <div className="col-span-6">
                                        <label htmlFor="addressLine2" className="block text-sm font-medium leading-6 text-gray-900">
                                            Address Line 2
                                        </label>
                                        <input
                                            type="text"
                                            name="addressLine2"
                                            id="addressLine1"
                                            autoComplete="address-line2"
                                            className={`px-4 h-[47px] mt-2 block w-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${formState.venueInfo.addressLine2.error !== null ? 'border border-red-700' : ''}`}
                                            onChange={onUpdateField}
                                            onBlur={(e) => handleBlur(e)}
                                            value={formState.venueInfo.addressLine2.value}
                                        />
                                        <span className="text-xs text-red-700">{formState.venueInfo.addressLine2.error}</span>
                                    </div>

                                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                                        <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">
                                            City
                                        </label>
                                        <input
                                            type="text"
                                            name="city"
                                            id="city"
                                            autoComplete="address-level2"
                                            className={`px-4 h-[47px] mt-2 block w-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${formState.venueInfo.city.error !== null ? 'border border-red-700' : ''}`}
                                            onChange={onUpdateField}
                                            onBlur={(e) => handleBlur(e)}
                                            value={formState.venueInfo.city.value}
                                        />
                                        <span className="text-xs text-red-700">{formState.venueInfo.city.error}</span>
                                    </div>

                                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                        <label htmlFor="state" className="block text-sm font-medium leading-6 text-gray-900">
                                            State / Province
                                        </label>
                                        <input
                                            type="text"
                                            name="state"
                                            id="state"
                                            autoComplete="region"
                                            className={`px-4 h-[47px] mt-2 block w-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${formState.venueInfo.state.error !== null ? 'border border-red-700' : ''}`}
                                            onChange={onUpdateField}
                                            onBlur={(e) => handleBlur(e)}
                                            value={formState.venueInfo.state.value}
                                        />
                                        <span className="text-xs text-red-700">{formState.venueInfo.state.error}</span>
                                    </div>

                                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                        <label htmlFor="postalCode" className="block text-sm font-medium leading-6 text-gray-900">
                                            ZIP / Postal code
                                        </label>
                                        <input
                                            type="text"
                                            name="postalCode"
                                            id="postalCode"
                                            autoComplete="postal-code"
                                            className={`px-4 h-[47px] mt-2 block w-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${formState.venueInfo.postalCode.error !== null ? 'border border-red-700' : ''}`}
                                            onChange={onUpdateField}
                                            onBlur={(e) => handleBlur(e)}
                                            value={formState.venueInfo.postalCode.value}
                                        />
                                        <span className="text-xs text-red-700">{formState.venueInfo.postalCode.error}</span>
                                    </div>

                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                                            Country
                                        </label>
                                        <select
                                            id="country"
                                            name="country"
                                            autoComplete="country-name"
                                            className={`px-4 h-[47px] mt-2 block w-full border-0 bg-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${formState.venueInfo.country.error !== null ? 'border border-red-700' : ''}`}
                                            onChange={onUpdateField}
                                            onBlur={(e) => handleBlur(e)}
                                            value={formState.venueInfo.country.value}
                                        >
                                            <option></option>
                                            <option>Jamaica</option>
                                        </select>
                                        <span className="text-xs text-red-700">{formState.venueInfo.country.error}</span>
                                    </div>
                                </div>
                            </div>

                        </div>

                        {!editMode &&

                            <StepperControl
                                handleClick={handleSubmit}
                                currentStep={currentStep}
                                steps={steps}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VenueInfo