import React from 'react'
import ScrollToTop from './utils/ScrollToTop'

const Wrapper = ({ children }) => {
    return (
        <div className='h-full'>
            <ScrollToTop />
            {children}
        </div>
    )
}

export default Wrapper