import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const TextEditor = ({ name, onBlur, onChange, form }) => {

    const nameVar = form[name].value
    const errVar = form[name].error;

    const toolbar = [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ font: [] }],
        ["bold", "italic", "underline"],
        [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
        ],
        ["image", "video"]
    ];

    const formats = [
        "background",
        "bold",
        "color",
        "font",
        "code",
        "italic",
        "link",
        "size",
        "strike",
        "script",
        "underline",
        "blockquote",
        "header",
        "indent",
        "list",
        "align",
        "direction",
        "code-block",
        "formula",
        "image",
        "video",
    ];

    const updateText = (e) => {
        onChange(name, e);
    };

    return (
        <div>
            <ReactQuill theme="snow"
                value={nameVar}
                onChange={updateText}
                id={name}
                modules={{
                    toolbar: {
                        container: toolbar,
                    },
                }}
                onBlur={(e) => onBlur(name, e)}
                className={`ql-editor ${errVar !== null ? 'border border-red-700' : ''}`}
                placeholder="Write something awesome..."
                formats={formats} />
            <span className="text-xs text-red-700">{errVar}</span>
        </div>
    )
}

export default TextEditor