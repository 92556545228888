import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();

    return (
        <div className="flex space-x-1">
            <button
                onClick={() => loginWithRedirect()}
                className="bg-white border border-gray-300 py-2 px-4 hover:bg-gray-100 font-semibold">
                Login
            </button>
            <button
                onClick={() => loginWithRedirect({
                    authorizationParams: {
                        screen_hint: 'signup'
                    }
                })}
                className="bg-lime-500 text-white py-2 px-4 border-none hover:bg-lime-600 font-semibold">
                Sign Up
            </button>
        </div>
    );
};

export default LoginButton;