import React from 'react'
import Card from './Card'

import Slider from "react-slick";
import { Link } from 'react-router-dom';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import rightArrow from '../images/icn-arrow-right.svg';
import leftArrow from '../images/icn-arrow-left.svg';

import { TbDeviceSpeaker } from "react-icons/tb";
import { MdWorkOutline, MdLocalDining, MdOutlineSportsBasketball } from 'react-icons/md';
import { FaTheaterMasks } from "react-icons/fa";





// import SampleCards from '../util/SampleCards'

function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div onClick={onClick} className={`"-right-12 absolute z-10 top-[40%] hover:bg-slate-400 ${className}`} id="next-arrow-slider">
            <img src={rightArrow} />
        </div>
    );
}

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div onClick={onClick} className={`"-left-12 absolute z-10 top-[40%] hover:bg-slate-400 ${className}`} id="prev-arrow-slider">
            <img src={leftArrow} />
        </div>
    );
}

const settings = {
    mobileFirst: true,
    dots: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    draggable: true,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
        {
            breakpoint: 769,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            }
        },
        {
            breakpoint: 480,
            settings: {
                // centerMode: true,
                slidesToShow: 1.1,
                slidesToScroll: 1,
            }
        }
    ]

};

const getIconTag = (category) => {
    switch (category) {
        case "Party":
            return <TbDeviceSpeaker size={20} className='mr-1 mt-1 text-slate-800' />
        case "Business":
            return <MdWorkOutline size={20} className='mr-1 mt-1 text-slate-800' />
        case "Food & Drinks":
            return <MdLocalDining size={20} className='mr-1 mt-1 text-slate-800' />
        case "Performing & Visual Arts":
            return <FaTheaterMasks size={20} className='mr-1 mt-1 text-slate-800' />
        case "Sports":
            return <MdOutlineSportsBasketball size={20} className='mr-1 mt-1 text-slate-800' />
        // case "TbTicket":
        //     return <TbTicket size={20} className='mr-1' />
        default:
            return;
    }
}

const Section = ({ categoryList }) => {


    return (

        < div className='flex flex-col max-w-7xl justify-center overflow-hidden w-full' >
            {/* {console.log(categoryList)} */}
            {
                Object.keys(categoryList[0]).map((meta, index) => (
                    < div key={index} className="relative" >

                        <div className='flex py-5 px-4 justify-between'>
                            <div className="flex ">
                                {getIconTag(meta)}
                                <p className="pl-2 text-slate-800">{meta} </p>
                            </div>

                            <div className='hover:cursor-pointer hover:text-[#000952] text-[#0062AB] underline uppercase -leading-3 tracking-wider font-semibold text-xs'>
                                <Link to="/search">View All Offers</Link>
                            </div>
                        </div>

                        {/* <div className='w-96 h-80 block absolute transition ease-in-out card-blur top-16 -left-60 rotate-180'>
                        </div>

                        <div className='w-96 h-80 block absolute transition ease-in-out card-blur top-16 -right-60'>
                        </div> */}

                        < div >
                            <Slider {...settings} className="preview-slider pl-1 mx-9">
                                {/* {console.log("this is " + meta)}
                                {console.log(categoryList[0][meta])} */}
                                {categoryList[0][meta].map((offer, i) => (
                                    <div key={i} >
                                        <Card cardInfo={offer} />
                                    </div>
                                ))}
                            </Slider>
                        </div>

                    </div >
                ))
            }
        </div >
    )
}

export default Section