import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import QRScanner from '../components/QRScanner';
import { TbCircleCheck, TbAlertCircle } from "react-icons/tb";
import { requestTicketValidation } from '../store/actions/index';
import { convertUTCToHumanTime } from '../components/common/dateUtils';
import { RxReset } from "react-icons/rx";



const OrgEventScan = () => {
    const submitEventLoading = useSelector((state) => state.organizerEditEvent.loading);
    const organizationEvents = useSelector((state) => state.organizerEvents.orgEvents);

    const dispatch = useDispatch();
    const { orgId, offerId } = useParams();
    const navigate = useNavigate();
    const { user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
    const [data, setData] = useState('');
    const validationStatus = useSelector((state) => state.validateTicketReducer.validationStatus);
    const validationStatusLoading = useSelector((state) => state.validateTicketReducer.loading);
    const validationStatusError = useSelector((state) => state.validateTicketReducer.error);
    const selectedOrg = useSelector((state) => state.selectedOrganization.selectedOrg);
    const [loading, setLoading] = useState(true);
    const [lastScanTime, setLastScanTime] = useState(0);
    const [totalCheckedIn, setTotalCheckedIn] = useState(0);
    const [totalTicketsSold, setTotalTicketsSold] = useState(0);
    const [LastUpdated, setLastUpdated] = useState(Date.now());



    useEffect(() => {
        const selectedEvent = organizationEvents.find((event) => event.id == offerId);
        if (selectedEvent) {
            setLoading(false);
        }
    }, [orgId, offerId]);

    const handleQRCodeDetected = (data) => {
        const currentTime = Date.now();
        if (currentTime - lastScanTime >= 3000) { // Check if 2 seconds have passed
            console.log('QR code detected:', data);
            setData(data);
            setLastScanTime(currentTime); // Update last scan time
        }
    };

    useEffect(() => {
        const validateTicket = async () => {
            const accessToken = await getAccessTokenSilently();
            const payload = {
                "ticket_id": data,
                "event_id": offerId // This is a WIP might not have been passed yet
            };
            dispatch(requestTicketValidation(selectedOrg, payload, accessToken));
        };

        if (data !== '') {
            validateTicket();
            // setTimeout(() => {
            //     setData('');
            // }, 2000); // Reset data after 2 seconds
        }
    }, [data]);


    useEffect(() => {
        if (!validationStatusLoading && Object.keys(validationStatus).length !== 0) {
            setTotalCheckedIn(validationStatus.data.data.event_scanning_metrics.total_checked_in)
            setTotalTicketsSold(validationStatus.data.data.event_scanning_metrics.total_tickets_sold)
            setLastUpdated(validationStatus.data.data.event_scanning_metrics.last_updated);
        }

    }, [validationStatus, validationStatusLoading]);

    return (
        <div>
            {!loading ? (
                <div>
                    <div className='flex flex-col h-[81vh]'>
                        <div className='flex flex-col bg-black w-full z-10 text-center text-white relative pt-2 h-[20%]'>
                            {/* <p className='text-lg'>Rise and Toast</p> */}
                            <p className='text-lg'>{totalCheckedIn}/{totalTicketsSold} patrons checked in</p>
                            <p className='text-sm'>Last Updated: {convertUTCToHumanTime(LastUpdated)}</p>
                        </div>

                        <QRScanner
                            onQRCodeDetected={handleQRCodeDetected}
                            data={setData}
                        />

                        {data === "" ? (
                            <div className='flex flex-col bg-[#0062AB] self-center font-semibold text-lg w-full z-10 bottom-0 text-white p-2 h-[20%]'>
                                <div className='flex items-center self-center'>Ready To Scan</div>
                            </div>
                        ) : (
                            !validationStatusLoading ?
                                <>
                                    {Object.keys(validationStatus).length !== 0 ? (
                                        <div className='flex flex-row bg-[#3e7128] w-full z-10 bottom-0 text-white p-2 fixed h-[17%] justify-between'>
                                            <div>
                                                <div className='flex flex-row uppercase items-center ml-2 my-2'>
                                                    <TbCircleCheck size={40} color='white' />
                                                    <p className='ml-2'>Valid Ticket</p>
                                                </div>
                                                <div className='flex flex-col ml-4'>
                                                    <span className='font-medium text-2xl'>{validationStatus.data.data.ticket_holder}</span>
                                                    <span className='uppercase tracking-normal'>{validationStatus.data.data.ticket_type}</span>
                                                </div>
                                            </div>
                                            <div
                                                onClick={() => { setData('') }}
                                                className='mt-2 mr-2'
                                            >
                                                <RxReset size={25} />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='flex flex-row bg-red-500 w-full z-10 bottom-0 text-white p-2 fixed h-[17%] justify-between'>
                                            <div className='flex flex-row uppercase items-center ml-2 my-2'>
                                                <TbAlertCircle size={40} color='white' />
                                                {validationStatusError.errorMessage}
                                            </div>
                                            <div
                                                onClick={() => { setData('') }}
                                                className='mt-2 mr-2'
                                            >
                                                <RxReset size={20} />
                                            </div>
                                        </div>
                                    )}
                                </> :
                                (
                                    <div className='flex flex-col bg-[#0062AB] self-center font-semibold text-lg w-full z-10 bottom-0 text-white p-2 h-[20%]'>
                                        <div className='flex items-center self-center'>Loading...</div>
                                    </div>
                                )

                        )}
                    </div>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default OrgEventScan;
