import React from 'react'

const PersonalForm = () => {
    return (
        <div>
            <div className="max-w-5xl mx-auto bg-white p-6 ">
                <div className="px-4 sm:px-0">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">Profile Information</h3>
                    <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Personal details about yourself.</p>
                </div>
                <div className="mt-6 border-t border-gray-100">
                    <dl className="divide-y divide-gray-100">
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">First name</dt>
                            <div className="mt-2">
                                <input type="text" name="first-name" id="first-name" placeholder='Margret' className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6" />
                            </div>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Last name</dt>
                            <div className="mt-2">
                                <input type="text" name="last-name" id="last-name" placeholder='Fosrer' className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6" />
                            </div>
                        </div>
                        <div className="spx-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Sex</dt>
                            <div className="mt-2">
                                <select id="sex" name="sex" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                    <option>Male</option>
                                    <option>Female</option>
                                    <option>Prefer not to say</option>
                                </select>
                            </div>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Email address</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">margotfoster@example.com</dd>
                        </div>


                    </dl>
                </div>

                <div className="flex space-x-1 justify-end">
                    <button
                        className="bg-white border border-gray-300 py-1 px-4 hover:bg-gray-100 font-normal text-sm rounded-md">
                        Cancel
                    </button>
                    <button
                        className="bg-lime-500 text-white py-1 px-4 border-none hover:bg-lime-600 font-normal text-sm rounded-md">
                        Update Info
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PersonalForm