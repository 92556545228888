import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from "react-router-dom";
import { requestCreateOrganization, resetCreateOrganization } from '../store/actions';
import { Link } from 'react-router-dom';




const CreateOrganizerModal = () => {
    const createOrganization = useSelector((state) => state.createOrganization.orgCreation);
    const createOrganizationError = useSelector((state) => state.createOrganization.error);
    const createOrganizationLoading = useSelector((state) => state.createOrganization.loading);

    const { user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
    // const [isChecked, setIsChecked] = useState(false);
    const [formState, setFormState] = useState({
        orgInfo: {
            logo: {
                value: null,
                error: null,
                required: false
            },
            organizerName: {
                value: "",
                error: null,
                required: true
            },
            about: {
                value: "",
                error: null,
                required: false
            },
            country: {
                value: "",
                error: null,
                required: true
            },
            emailAddress: {
                value: "",
                error: null,
                required: true
            },
            contactNumber: {
                value: "",
                error: null,
                required: true
            },
            termsOfService: {
                value: null,
                error: null,
                required: true
            },
            facebook: {
                value: "",
                error: null,
                required: false
            },
            instagram: {
                value: "",
                error: null,
                required: false
            },
            twitter: {
                value: "",
                error: null,
                required: false
            },
            whatsapp: {
                value: "",
                error: null,
                required: false
            },
            website: {
                value: "",
                error: null,
                required: false
            },
        }
    })


    const areAllFieldsFilled = () => {
        const fields = formState.orgInfo;

        for (const fieldName of Object.keys(fields)) {
            if (fields[fieldName].required === true && (fields[fieldName].value === '' || fields[fieldName].value === null)) {
                return false; // At least one field is empty
            }
        }

        return true; // All fields have values
    };
    const canCreate = areAllFieldsFilled();

    const submitForm = async () => {
        const accessToken = await getAccessTokenSilently();
        dispatch(requestCreateOrganization(trimForm(formState), accessToken));
    }

    function trimForm(originalObject) {
        const convertedObject = {};

        for (const key in originalObject) {
            if (Array.isArray(originalObject[key])) {
                convertedObject[key] = originalObject[key];
            } else if (typeof originalObject[key] === "object") {
                convertedObject[key] = {};

                for (const subKey in originalObject[key]) {
                    convertedObject[key][subKey] = originalObject[key][subKey].value;
                }
            } else {
                convertedObject[key] = originalObject[key];
            }
        }

        return convertedObject;
    }

    const handleAdvancedSettingsToggle = () => {
        setShowAdvancedSettings(!showAdvancedSettings);
    };

    function handleCheckboxChange(event) {
        setFormState({ ...formState, orgInfo: { ...formState.orgInfo, ['termsOfService']: { ...formState.orgInfo['termsOfService'], value: event.target.checked ? event.target.checked : null, error: null } } })
        // setIsChecked(event.target.checked);
    }

    const getBase64 = file => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            onFileLoad(reader.result)
        }
    }

    const onFileChange = (event) => {
        let file = event.target.files[0];
        getBase64(file)
    }

    const onFileLoad = fileString => {
        setFormState({ ...formState, orgInfo: { ...formState.orgInfo, ['logo']: { ...formState.orgInfo['logo'], value: fileString, error: null } } })
    }

    const onUpdateField = e => {
        const name = e.target.name;
        const value = e.target.value;

        setFormState({ ...formState, orgInfo: { ...formState.orgInfo, [name]: { ...formState.orgInfo[name], value: value, error: null } } })

        if (name == 'organizerName' && createOrganizationError) {
            dispatch(resetCreateOrganization())
        }
    };

    const handleBlur = e => {
        const name = e.target.name;
        const value = e.target.value;

        if (formState.orgInfo[name].required && !formState.orgInfo[name].value) return setFormState({ ...formState, orgInfo: { ...formState.orgInfo, [name]: { ...formState.orgInfo[name], error: "Required!" } } })
    };

    useEffect(() => {
        if (!createOrganizationLoading && createOrganization != null && Object.keys(createOrganization).length != 0) {
            dispatch(resetCreateOrganization())
            navigate("/organizer");
            // window.location.reload();
        }
    }, [createOrganization]);


    return (

        <div className="inset-0 flex items-center justify-center my-8">
            <div className="fixed inset-0 bg-gray-200 opacity-75"></div>
            <div className="z-10 bg-white p-4 md:p-5 rounded-md shadow-md max-w-xl overflow-y-auto">
                <h3 className="mb-1 text-xl font-bold text-gray-900">Create a new organization</h3>
                <p className="text-gray-500 mb-6 text-sm">
                    Organizations represents the top level in your heirarchy.
                    This serve as the overarching entities that can contain different users, events, and other components.
                </p>



                <div className="grid grid-cols-6 gap-6">

                    <div className="col-span-6 sm:col-span-2">
                        <div className="mt-2 flex items-center relative">
                            <div className="flex flex-col">
                                <span className="relative inline-block h-32 w-32 overflow-hidden rounded-full bg-gray-100">
                                    {formState.orgInfo.logo.value !== null ? (
                                        <img src={formState.orgInfo.logo.value} alt="Logo" />
                                    ) : (
                                        <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                        </svg>
                                    )}
                                    <div className="absolute bottom-0 left-0 right-0 flex justify-center">
                                        <label htmlFor="logo" className="cursor-pointer bg-gray-200 opacity-70 px-2 rounded-b-full w-full text-center">
                                            Edit
                                            <input
                                                type="file"
                                                id="logo"
                                                className="hidden"
                                                accept="image/png, image/jpg, image/gif, image/jpeg"
                                                onChange={onFileChange}
                                            />
                                        </label>
                                    </div>
                                </span>
                                <span className="text-xs text-red-700">{formState.orgInfo.logo.error}</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-span-6 sm:col-span-4 mt-6">
                        <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                            Organization Name<span className="text-red-700"> *</span>
                        </label>
                        <input
                            type="text"
                            name="organizerName"
                            id="organizerName"
                            autoComplete="organizerName"
                            className={`mt-2 block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${formState.orgInfo.organizerName.error !== null ? 'border border-red-700' : ''}`}
                            onChange={onUpdateField}
                            onBlur={(e) => handleBlur(e)}
                            placeholder="e.g. My Company"
                            value={formState.orgInfo.organizerName.value}
                            required
                        />
                        <span className="text-xs text-red-700">{formState.orgInfo.organizerName.error}</span>
                        {createOrganizationError && (
                            <span className="text-xs text-red-700">{createOrganizationError.errorMessage}</span>
                        )}
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                            About
                        </label>
                        <div className="mt-2">
                            <textarea
                                id="about"
                                name="about"
                                rows={3}
                                className={`mt-1 block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:py-1.5 sm:text-sm sm:leading-6 ${formState.orgInfo.about.error ? 'border border-red-700' : ''}`}
                                placeholder="you@example.com"
                                onChange={onUpdateField}
                                onBlur={(e) => handleBlur(e)}
                                value={formState.orgInfo.about.value}
                            />
                            <span className="text-xs text-red-700">{formState.orgInfo.about.error}</span>
                        </div>
                        <p className="mt-1 text-sm text-gray-500">
                            Brief description for your Organization.
                        </p>
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                            Country<span className="text-red-700"> *</span>
                        </label>
                        <select
                            id="country"
                            name="country"
                            // disabled={editMode && !isNewTicket}
                            className={`px-2 mt-2 block w-full rounded-md border-0 bg-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${formState.orgInfo.country.error ? 'border border-red-700' : ''}`}
                            onChange={onUpdateField}
                            onBlur={(e) => handleBlur(e)}
                            value={formState.orgInfo.country.value}
                        >
                            <option value=""></option>
                            <option value="US">United States of America</option>
                            <option value="JM">Jamaica</option>
                        </select>
                        <span className="text-xs text-red-700">{formState.orgInfo.country.error}</span>
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="emailAddress" className="block text-sm font-medium leading-6 text-gray-900">
                            Email Address<span className="text-red-700"> *</span>
                        </label>
                        <input
                            type="email"
                            name="emailAddress"
                            id="emailAddress"
                            autoComplete="email"
                            className={`mt-2 block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${formState.orgInfo.emailAddress.error ? 'border border-red-700' : ''}`}
                            onChange={onUpdateField}
                            onBlur={(e) => handleBlur(e)}
                            value={formState.orgInfo.emailAddress.value}
                        />
                        <span className="text-xs text-red-700">{formState.orgInfo.emailAddress.error}</span>
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                        <label htmlFor="contactNumber" className="block text-sm font-medium leading-6 text-gray-900">
                            Contact Number<span className="text-red-700"> *</span>
                        </label>
                        <input
                            type="text"
                            name="contactNumber"
                            id="contactNumber"
                            autoComplete="phone"
                            maxLength={10}
                            className={`mt-2 block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${formState.orgInfo.contactNumber.error ? 'border border-red-700' : ''}`}
                            onChange={onUpdateField}
                            onBlur={(e) => handleBlur(e)}
                            value={formState.orgInfo.contactNumber.value}
                        />
                        <span className="text-xs text-red-700">{formState.orgInfo.contactNumber.error}</span>
                    </div>

                </div>

                <div className="mt-4 flex justify-end">

                    <div
                        className='hover:cursor-pointer hover:text-[#000952] text-[#0062AB] underline uppercase -leading-3 tracking-wider font-semibold text-xs'
                        type="button"
                        onClick={handleAdvancedSettingsToggle}
                    >
                        Advanced Settings
                    </div>
                </div>

                {showAdvancedSettings && (

                    <>
                        <div className="grid grid-cols-6 gap-6">
                            {/* social media */}
                            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                <label htmlFor="facebook" className="block text-sm font-medium leading-6 text-gray-900">
                                    Facebook
                                </label>
                                <input
                                    type="text"
                                    name="facebook"
                                    id="facebook"
                                    autoComplete="facebook"
                                    className={`mt-2 block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${formState.orgInfo.facebook.error ? 'border border-red-700' : ''}`}
                                    onChange={onUpdateField}
                                    // onBlur={(e) => handleBlur(e)}
                                    value={formState.orgInfo.facebook.value} />
                                <span className="text-xs text-red-700">{formState.orgInfo.facebook.error}</span>
                            </div>

                            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                <label htmlFor="instagram" className="block text-sm font-medium leading-6 text-gray-900">
                                    Instagram
                                </label>
                                <input
                                    type="text"
                                    name="instagram"
                                    id="instagram"
                                    autoComplete="instagram"
                                    className={`mt-2 block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${formState.orgInfo.instagram.error ? 'border border-red-700' : ''}`}
                                    onChange={onUpdateField}
                                    // onBlur={(e) => handleBlur(e)}
                                    value={formState.orgInfo.instagram.value} />
                                <span className="text-xs text-red-700">{formState.orgInfo.instagram.error}</span>
                            </div>

                            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                <label htmlFor="twitter" className="block text-sm font-medium leading-6 text-gray-900">
                                    Twitter
                                </label>
                                <input
                                    type="text"
                                    name="twitter"
                                    id="twitter"
                                    autoComplete="twitter"
                                    className={`mt-2 block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${formState.orgInfo.twitter.error ? 'border border-red-700' : ''}`}
                                    onChange={onUpdateField}
                                    // onBlur={(e) => handleBlur(e)}
                                    value={formState.orgInfo.twitter.value} />
                                <span className="text-xs text-red-700">{formState.orgInfo.twitter.error}</span>
                            </div>

                            <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                                <label htmlFor="instagram" className="block text-sm font-medium leading-6 text-gray-900">
                                    Whatsapp
                                </label>
                                <input
                                    type="tel"
                                    name="whatsapp"
                                    id="whatsapp"
                                    autoComplete="tel"
                                    maxLength={10}
                                    className={`mt-2 block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${formState.orgInfo.whatsapp.error ? 'border border-red-700' : ''}`}
                                    onChange={onUpdateField}
                                    // onBlur={(e) => handleBlur(e)}
                                    value={formState.orgInfo.whatsapp.value} />
                                <span className="text-xs text-red-700">{formState.orgInfo.whatsapp.error}</span>
                            </div>

                            <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                                <label htmlFor="twitter" className="block text-sm font-medium leading-6 text-gray-900">
                                    Website
                                </label>
                                <input
                                    type="text"
                                    name="website"
                                    id="website"
                                    autoComplete="Website"
                                    className={`mt-2 block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${formState.orgInfo.website.error ? 'border border-red-700' : ''}`}
                                    onChange={onUpdateField}
                                    // onBlur={(e) => handleBlur(e)}
                                    value={formState.orgInfo.website.value} />
                                <span className="text-xs text-red-700">{formState.orgInfo.website.error}</span>
                            </div>
                        </div>
                    </>
                )}

                <div class="col-span-6 mb-[0.125rem] block min-h-[1.5rem]">
                    <label className="inline-flex items-center">
                        <input
                            type="checkbox"
                            className="form-checkbox h-5 w-5 text-blue-600"
                            // checked={isChecked}
                            checked={formState.orgInfo.termsOfService.value}
                            onChange={handleCheckboxChange}
                        />
                        <span className="ml-2 text-gray-700">I accept the <Link to="/tos" target="_blank" className="text-[#008bbf] hover:opacity-80"> Terms of Service</Link><span className="text-red-700"> *</span></span>
                    </label>
                </div>

                <div className="mt-4 flex justify-end">
                    <button
                        disabled={!canCreate}
                        onClick={submitForm}
                        className={`bg-[#529535] text-white uppercase py-2 px-4 w-28 rounded-xl font-semibold cursor-pointer border-[#529535]
                        hover:bg-[#529535] hover:text-white hover:opacity-80 transition duration-200 ease-in-out col-start-5 col-end-6 inline-flex items-center disabled:opacity-30 disabled:bg-slate-700 disabled:border-slate-300`}>
                        {!createOrganizationLoading ?
                            "create" :
                            <>
                                {/* <svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                </svg> */}
                                <svg aria-hidden="true" class="inline w-4 h-4 text-gray-200 animate-spin fill-green-500" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                </svg>
                                loading...
                            </>
                        }
                    </button>
                </div>
            </div>
        </div >

    )
}

export default CreateOrganizerModal